import React, {Component} from 'react';
import HistoryMonthList from "./HistoryMonthList";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class HistoryYearList extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        monthHide : false,
        isMobile : deviceDetect()
    }

    render() {
        const { white } = this.props;
        const { year, startWithLeft, monthData } = this.props.year;
        const monthList = monthData.map((monData, index) => <HistoryMonthList key={index} monthData={monData} isLeft={this.chooseLeft(startWithLeft, index)} isHide={this.state.monthHide} />)

        if (this.state.isMobile) {
            return (
                <div className="history_mobile" onClick={() => this.setState({monthHide : !this.state.monthHide})}>
                    <div className="year">
                        <div className="yearSign_mobile"></div>
                        <div className="history-year_mobile">{year}</div>
                    </div>
                    <div className={"month-" + (this.state.monthHide ? "hide":"show")}>
                        {monthList}
                    </div>
                </div>
            )
        }

        return (
            <div className={'history' + (white ? '-background-white':'')} onClick={() => this.setState({monthHide : !this.state.monthHide})}>
                <div className={"yearFlex-" + (startWithLeft ? 'left' : 'right')}>
                    <div className={'year-' + (startWithLeft ? 'left' : 'right')}>
                        <div className="history-year">{year}</div>
                        <div className="yearSign"></div>
                    </div>
                    <div className="yearOpposite"></div>
                </div>
                <div className={"month-" + (this.state.monthHide ? "hide":"show")}>
                    {monthList}
                </div>
            </div>
        );
    }

    chooseLeft = (startWithLeft, index) => {
        return startWithLeft ? index%2 === 0 ? true : false : index%2 === 0 ? false : true;
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default HistoryYearList;