import React, {Component} from 'react';

class HistoryMonth extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;

        return (
            <div>
                {data}
            </div>
        );
    }
}

export default HistoryMonth;