import React, {Component} from 'react';
import {NavLink, useParams} from "react-router-dom";
import ArticleList from "../../components/Resources/Article/ArticleList";
import '../../static/css/pages/resource.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Resource extends Component{
    state = {
        limit : 15,
        page : 0,
        categoryId : 1,
        index : [0, 1, 3, 6, 4],
        menus : [],
        isMobile : deviceDetect()
    }

    render() {
        const result = this.state.menus.map((menu) => <div className="menu" key={menu.id}><NavLink className={(menu.selected ? 'selected':'')} to={'/resources' + (menu.url)}>{menu.menu}</NavLink></div>)

        if (this.state.isMobile) {
            return (
                <div className="resources_mobile">
                    <div className="resourcesHeader_mobile">Resources</div>
                    <div className="resourcesMenus_mobile">
                        {result}
                    </div>
                    <ArticleList categoryId={this.state.index[this.state.categoryId]} page={this.state.page} limit={6} />
                </div>
            );
        }

        return (
            <div className="resources">
                <div className="resourcesHeader">Resources</div>
                <div className="resourcesMenus">
                    {result}
                </div>
                <ArticleList categoryId={this.state.index[this.state.categoryId]} page={this.state.page} limit={this.state.limit} />
            </div>
        );
    }

    findCategoryId = () => {
        // const categoryList = ['', 'sw', 'pr', 'media', 'cs', 'bv'];
        const categoryList = ['','pr','bv','media'];
        let num = 0;

        categoryList.forEach((name, i) => {
            if (name.includes(this.props.params.category)) {
                num = i;
            }
        });
        this.setState({
            categoryId : num + 1
        }, () => {
            this.getMenus();
        });
    }

    // seminar/webinar 삭제 요청, case study 숨김 요청 (처리는 똑같이 함 2023-02-14)
    getMenus = () => {
        const selected = this.state.categoryId;

        let menus = [
            {
                id : 1,
                menu : 'All',
                url : '',
                selected : false
            },
            // {
            //     id : 2,
            //     menu : 'Seminar/Webinar',
            //     url : '/sw',
            //     selected : false
            // },
            {
                id : 3,
                menu : 'Press Release',
                url : '/pr',
                selected : false
            },
            {
                id : 6,
                menu : 'BV Contents',
                url : '/bv',
                selected : false
            },
            {
                id : 4,
                menu : 'Media',
                url : '/media',
                selected : false
            }
            // {
            //     id : 5,
            //     menu : 'Case Studies',
            //     url : '/cs',
            //     selected : false
            // },
        ]

        menus[selected - 1].selected = true;

        this.setState({
            menus : menus
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        this.findCategoryId()
        window.addEventListener(`resize`, this.windowResize);
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.params.category !== this.props.params.category) {
            this.findCategoryId()
            this.setState({
                limit : 15,
                page : 0,
                menus : []
            });
        }

        if (prevState.categoryId !== this.state.categoryId) {
            this.getMenus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default withParams(Resource);