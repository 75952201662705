//import React from "react";

import styles from './index.module.css';

import ailobigProcess from './assets/ailobigProcess.png';
import bkgndLogo from './assets/bg_logo.png';
import avatar1 from './assets/avatar1.png';
import avatar2 from './assets/avatar2.png';
import avatar3 from './assets/avatar3.png';
import noImg from "../../../../static/assets/utils/noimg.png";

function Ailobig(props) {

    return (<div className={styles.bkgnd}>

        <div className={styles.content}>

            <div className={styles.top}>
                <div className={styles.title}>Comments</div>
                <div className={styles.head}>AI LOBIG 고객 경험</div>
                <div className={styles.description}>AI LOBIG을 직접 경험한 고객사의 후기를 확인해보세요!</div>
                <img className={styles.ailobig_process_image} src={ailobigProcess} onError={(e) => e.target.src = `${noImg}`}/>
            </div>

            <div className={styles.avatar}>
                <img className={styles.avatar_image} src={avatar1} onError={(e) => e.target.src = `${noImg}`}/>
                <div className={styles.avatar_text}>
                    최근 아파트 인테리어 내역을 AI LOBIG이 학습<br/>
                    하는데 1주일 밖에 걸리지 않았지만 그 결과는<br/>
                    <span className={styles.avatar_text_highlight}>7년이 넘은 전문가들이 내린 결론과 유사</span>했으며,<br/>
                    데이터를 통해 그 이유를 정확히 확인할 수 있어<br/>
                    서 정말 놀라운 경험이었습니다.

                    <div className={styles.avatar_by}>국내 1위 인테리어 기업 임원</div>
                </div>
            </div>

            <div className={styles.avatar1}>
                <img className={styles.avatar_image} src={avatar2} onError={(e) => e.target.src = `${noImg}`}/>
                <div className={styles.avatar_text}>
                    전국 모든 매장에 대해서 시장의 변화를<br/>
                    모니터링하고 코칭할 수 있습니다. 점포 개발 <br/>
                    과정에서의 불확실성도 사라졌습니다.<br/>
                    AI LOBIG은 30초면 전국 어디든 <br/>
                    <span className={styles.avatar_text_highlight}>신규 점포의 예상 매출액을 정확히 예측합니다.</span>

                    <div className={styles.avatar_by}>국내 300개 이상의 프랜차이즈 기업 마케팅 팀장</div>
                </div>
            </div>

            <div className={styles.avatar2}>
                <img className={styles.avatar_image} src={avatar3} onError={(e) => e.target.src = `${noImg}`}/>
                <div className={styles.avatar_text}>
                    AI LOBIG은 저희 데이터와 공간 정보를 결합하여 <br/>
                    학습하면서 <span className={styles.avatar_text_highlight}>폭발적인 인사이트를 제공</span>합니다. <br/>
                    소비자들이 어떻게 매장에 접근하고 이용하는지 <br/>
                    처음 알게 되었습니다.

                    <div className={styles.avatar_by}>국내 대형 할인마트 데이터 담당 팀장</div>
                </div>
            </div>
            <img className={styles.bkgnd_logo_image} src={bkgndLogo} onError={(e) => e.target.src = `${noImg}`}/>

        </div>


    </div>);
}

export default Ailobig;