import React, {useState, useEffect, useContext} from 'react';
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';

import styled from 'styled-components';

// import "@toast-ui/editor/dist/toastui-editor.css";

// import Editor from "@toast-ui/editor";
import {useAdminInfoActionState} from "./AdminInfoStore";

const DivArea = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    padding: 20px;
    width: 100%;
`;

const Table = styled.table`
        border-collapse: collapse;
        margin: 0 auto;
        width: 100%;
        font-size: 16px;
`

const Tr = styled.tr`
    border-top: 1px solid #d2d2d2 !important;
    border-bottom: 1px solid #d2d2d2 !important;
`

const Td = styled.td`
    padding: 10px;
`

const TdHeader = styled.td`
    background-color: #F5F5F5 !important;
    color: #000000 !important;
    font-weight: bold !important;
    padding: 12px 20px !important;
    text-align: left !important;
`

const ButtonGroup = styled.div`
    padding: 10px 0;
    display: flex;
    gap: 3px;
`;

const Button = styled.button`
    margin-right: 10px;
    border: 1px solid #448fdd;
    color: #448fdd;
    background-color: #ffffff;
    font-size: 14px !important;
    height: 30px;
    cursor: pointer;
    padding: 10px 20px;
    display: inline-block;
    box-sizing: border-box;
    width: 180px;

    &:hover {
        text-decoration: underline;
    }
`
const InputText = styled.input`
    width: 100%;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
`

const InputTextArea = styled.input`
    width: 100%;
    height: 50px;
    border: 1px solid #d2d2d2;
`

const DivHeader = styled.div`
    font-weight: bold !important;
    padding: 12px 20px !important;
    font-size: 16px;
`;

const refEditor = React.createRef();
export default function PostWrite() {
    const [fileUiIdMax, setFileUiIdMax] = useState(0);
    const [existFileList, setExistFileList] = useState([]);
    const location = useLocation();
    const pageNum = location.state;
    const prevPath = "/" + location.pathname.split("/")[1];
    const action = useAdminInfoActionState();
    const nav = useNavigate();
    const boardId = findByLocation(1);
    const menuName = findByLocation(3);
    const { postId } = useParams();

    useEffect(() => {
        /**
         * NHN Toast UI Editor 사용함. 
         * 리액트용으로 나온 버전도 있지만, 리액트용으로 나온 버전은 프로젝트 리액트 버전과 맞지 않아서 일반 자바스크립트 버전을 사용함.
         * (리액트용을 사용하려면 프로젝트의 리액트 버전을 다운그레이드 해야 함)
         *  */ 
        // refEditor.current = new Editor({
        //     el: document.querySelector("#editor"),
        //     initialEditType: "wysiwyg",
        //     previewStyle: "vertical",
        //     height: "430px",
        //     initialValue: "",
        //     useCommandShortcut: true,
        // });

        /**
         * Toast UI Editor 사용 안 함: 토스트에디터는 
         * 여러 이미지 한 번에 올리기, 가운데 정렬, 새 탭에서 링크 열기 기능이 없어서 summernote 사용.
         * */
        window.$("#summernote_post").summernote({
            height: 430,
            callbacks: {
                onImageUpload: function (files) {
                    uploadSummernoteImageFile(files[0],this);
                }
            }
        });

        function uploadSummernoteImageFile(file, editor) {
            const header = {
                "Content-Type": "multipart/form-data",
            };

            // set axios body
            const frm = new FormData();
            frm.append("multipartFile", file);
            const body = frm;

            // call
            axios.post(process.env.REACT_APP_API_URL + `/api/admin/fileOnly`, body, header)
                .then((Response) => {
                    const url = process.env.REACT_APP_PREVIEW_URL + Response.data;
                    window.$(editor).summernote('pasteHTML', "<img src='" + url +"' />");
                });
            // console.log('res', res);
        }

        async function fetchData() {
            if(postId !== '0') { // 수정일 경우
                const content = await loadDb()
                setHtml(content);
            }
        }
        fetchData()

        // if boardId === '6' then hide writerName
        if(boardId === '6') {
            document.getElementById('tr-writerName').style.display = 'none';
            document.getElementById('tr-editor').style.display = 'none';
        }

        return () => {
            // console.log("ToastUiEditor useEffect cleanup");
        };
    }, []);

    const refTitle = React.createRef();
    const refWriterName = React.createRef();
    const refViewDate = React.createRef();

    const refCategoryId = React.createRef();

    const refEntryDepart = React.createRef();
    const refEntryDuration = React.createRef();
    const refEmployeeType = React.createRef();

    // const refArticleURL = React.createRef();
    const refArticlePressName = React.createRef();
    const refIsMain = React.createRef();
    const refLead = React.createRef();

    let savedHTML = "";

    function findByLocation(num) {
        const splitPath = location.pathname.split('/');

        switch (num) {
            case 1: // boardId 구하기
                if (splitPath[1] === "notice") {
                    return '1';
                } else if (splitPath[1] === "recruit") {
                    return '2';
                } else if (splitPath[1] === "history") {
                    return '6';
                } else if (splitPath[1].includes("resources")) {
                    return '3';
                }
                return '0';
            case 2: // categoryId 구하기 -> 현재 사용 X
                if (splitPath[1].includes("resources")) {
                    const splitNum = splitPath[1].split("resources");
                    if (splitNum[1] === '') {
                        return '1'
                    }
                    return splitNum[1];
                }
                return '1';
            default: // menuName 구하기
                if (splitPath[1] === "notice") {
                    return '공지사항';
                } else if (splitPath[1] === "recruit") {
                    return '채용공고';
                } else if (splitPath[1] === "history") {
                    return '연혁';
                } else if (splitPath[1].includes("resources")) {
                    return 'Resources';
                }
                return '문의사항';
        }
    }

    function validate() {
        // 공통
        if(refTitle.current.value === '') {
            alert('제목을 입력해주세요.');
            return -1;
        }
        if(refViewDate.current.value === '') {
            alert('메인 노출 날짜를 입력해주세요.');
            return -1;
        }

        switch(boardId) {
            case '2': // 채용공고
                if(refEntryDepart.current.value === '' || 
                    refEntryDuration.current.value === '' || 
                    refEmployeeType.current.value === '') {
                    alert('채용공고 필수 항목을 입력해주세요.');
                    return -1;
                }
                break;
            case '3': // Resources
                if(refArticlePressName.current.value === '' ||
                    refLead.current.value === '') {
                    alert('Resources 필수 항목을 입력해주세요.');
                    return -1;
                }
                break;
            default:
                break;
        }
        return 0;
    }

    async function write() {
        const resValidate = validate();
        if(resValidate !== 0)
            return;
        
        const ret = await saveDb();
        if(ret.code === 0) {
            if(boardId === '3') { // Resources만
                const retFilePreview = await uploadFiles(ret.boardId, ret.postId, true);
                if(retFilePreview !== 0) {
                    alert('프리뷰 이미지 업로드 실패했습니다.');
                    return;
                }
            }    
            const retFile = await uploadFiles(ret.boardId, ret.postId);
            // console.log('retFile', retFile);
            if(retFile === 0) {
                alert('등록되었습니다.');
                nav(prevPath, {state : pageNum.toString()});
            } else {
                alert('파일 업로드에 실패했습니다.');
            }
        } else {
            alert('등록에 실패했습니다.');
        }

    }

    function getEditorHTML() {
        const instance = refEditor.current;
        return instance.getHTML();
    }

    function loadEditorHTML(html) {
        const instance = refEditor.current;
        return instance.setHTML(html);
    }

    function getEditorSummernoteHTML() {
        const html = window.$("#summernote_post").summernote("code")
        return html;
    }

    function loadEditorSummernoteHTML(html) {
        window.$("#summernote_post").summernote("code", html);
    }

    // db에 저장
    async function saveDb() {
        
        // savedHTML = getEditorHTML();
        savedHTML = getEditorSummernoteHTML();

        /* body 처리 */
        const body = {
            boardId: boardId,
            postId: postId,
            title: refTitle.current.value,
            writerName: refWriterName.current.value,
            content: savedHTML,
            viewDate: refViewDate.current.value,
            postStatCode: document.querySelector('input[name="postStatCode"]:checked').value,
        }
        // console.log('body', body);

        /* additionalValue 처리 */
        const additionalValue = {}
        switch(boardId) {
            case '2': // 채용공고
                additionalValue['entryDepart'] = refEntryDepart.current.value;
                additionalValue['entryDuration'] = refEntryDuration.current.value;
                additionalValue['employeeType'] = refEmployeeType.current.value;
                break;
            case '3': // Resources
                // additionalValue['articleURL'] = refArticleURL.current.value;
                additionalValue['articlePressName'] = refArticlePressName.current.value;
                additionalValue['isMain'] = refIsMain.current.checked ? true : false;
                additionalValue['lead'] = refLead.current.value;
                break;
            default:
                break;
        }
        // console.log('additionalValue:', additionalValue);
        let strUrlEncoded = encodeURIComponent(JSON.stringify(additionalValue));
        if(Object.keys(additionalValue).length === 0) {
            strUrlEncoded = '';
        }

        /* categoryId 처리 */
        const categoryId = boardId === '3' ? refCategoryId.current.value : 1
        body.categoryId = categoryId;

        /* axios call */
        let response
        if(postId !== '0') { // 수정
            response = await axios.put(process.env.REACT_APP_API_URL + `/api/admin/post?additionalValueJson=${strUrlEncoded}`, body);    
        } else {
            response = await axios.post(process.env.REACT_APP_API_URL + `/api/admin/post?boardId=${boardId}&categoryId=${categoryId}&additionalValueJson=${strUrlEncoded}`, body);
        }

        if (response.data.message !== undefined) {
            action.logOut();
            nav('/login', {state: response.data.message});
        }

        if(response.status === 200) {
            return {code: 0, boardId: response.data.boardId, postId: response.data.postId};
        } else {
            return {code: -1};
        }

    }

    // 해당 postId의 db data를 읽는다.
    async function loadDb() {
        const res = await axios.get(`/api/admin/post/${boardId}/${postId}`);
        const data = res.data;
        const content = {
            id: data.postId,
            boardId: data.boardId,
            categoryId: data.categoryId,
            title: data.title,
            content: data.content,
            writerName: data.writerName,
            viewDate: data.viewDate,
            postStatCode: data.postStatCode,
            additionalColumns: data.additionalColumns,
            additionalValues: data.additionalValues,
            attachedFiles: data.attachedFiles,
        }
        return content;
    }

    // db에 저장된 data를 화면에 뿌린다.
    function setHtml(content) {
        refTitle.current.value = content.title;
        refWriterName.current.value = content.writerName;
        refViewDate.current.value = content.viewDate.replaceAll('.', '-')
        document.querySelector(`input[name="postStatCode"][value="${content.postStatCode}"]`).checked = true;
        // loadEditorHTML(content.content);
        loadEditorSummernoteHTML(content.content);

        switch(boardId) {
            case '2': // 채용공고
                const json2 = JSON.parse(content.additionalValues[0].json);
                refEntryDepart.current.value = json2.entryDepart;
                refEntryDuration.current.value = json2.entryDuration;
                refEmployeeType.current.value = json2.employeeType;
                break;
            case '3': // Resources
                const json3 = JSON.parse(content.additionalValues[0].json);
                // refArticleURL.current.value = json3.articleURL;
                refArticlePressName.current.value = json3.articlePressName;
                if(json3.isMain === true) {
                    refIsMain.current.checked = true;
                }
                if(json3.lead) {
                    refLead.current.value = json3.lead;
                }
                refCategoryId.current.value = content.categoryId;
                break;
            default:
                break;
        }

        if(content.attachedFiles.length > 0) {
            setExistFileList(content.attachedFiles);
        }
    }

    // 이미 업로드한 파일을 삭제한다.
    function deleteFileInExist(fileId) {
        // console.log('fileId', fileId);
        const ul = document.querySelector('.exist-file-list');
        const li = document.querySelector(`li[data-fileid="${fileId}"]`);
        
        axios.delete(process.env.REACT_APP_API_URL + `/api/admin/file/${fileId}`)

        ul.removeChild(li);
    }


    // 여러 파일을 업로드한다.
    async function uploadFiles(boardId, postId, isPreview=false) {
        let fileUiList = document.querySelectorAll('input[name="fileResource"]');
        if(isPreview) {
            fileUiList = document.querySelectorAll('input[name="fileResourcePreview"]');
        }
        if(fileUiList.length === 0) {
            return 0;
        }
        const realFileList = [...fileUiList].filter((v, i) => v.value !== '');
        if(realFileList.length === 0) {
            return 0;
        }

        let res = 0
        for (const realFile of realFileList) {
            res += await uploadFile(realFile.files[0], boardId, postId, isPreview);
        }

        if(res === 0) { // 모든 uploadFile이 성공했을 경우
            return 0
        } else {
            return -1
        }
    }

    // 하나의 파일을 업로드한다.
    async function uploadFile(upFile, boardId, postId, isPreview=false) {
        // set axios header
        const header = {
            "Content-Type": "multipart/form-data",
        };

        // set axios body
        const frm = new FormData();
        frm.append("multipartFile", upFile);
        const body = frm;

        // call
        const res = await axios.post(process.env.REACT_APP_API_URL + `/api/admin/file?boardId=${boardId}&postId=${postId}&isPreview=${isPreview ? '1' : '0'}`, body, header)
        // console.log('res', res);
        
        if(res.status === 200) {
            return 0
        } else {
            return -1
        }
    }

    // 파일추가 ui를 추가한다.
    function addInputFileEl(isPreview=false) {
        const inputFilesId = isPreview ? 'inputFilesPreview' : 'inputFiles';
        const inputFiles = document.getElementById(inputFilesId)
        const newDiv = document.createElement('div');
        const newDivId = 'fileUi' + fileUiIdMax
        newDiv.id = newDivId;
        const newEl = document.createElement('input');
        newEl.type = 'file';
        newEl.name = isPreview ? 'fileResourcePreview' : 'fileResource';
        newDiv.appendChild(newEl);

        const newElDeleteEl = document.createElement('button');
        newElDeleteEl.type = 'button';
        newElDeleteEl.innerText = '삭제';
        newElDeleteEl.addEventListener('click', deleteInputFileEl.bind(this, newDivId));
        newDiv.appendChild(newElDeleteEl);

        inputFiles.appendChild(newDiv);

        setFileUiIdMax(fileUiIdMax + 1);
    }

    // 파일추가 ui를 삭제한다.
    function deleteInputFileEl(divId) {
        const tobeDeletedEl = document.getElementById(divId);
        tobeDeletedEl.remove();
    }

    // post를 삭제한다.
    async function deletePost() {
        if(window.confirm('삭제하시겠습니까?')) {
            const response = await axios.delete(process.env.REACT_APP_API_URL + `/api/admin/post/${boardId}/${postId}`)

            if(response.status === 200) {
                alert('삭제되었습니다.');
                nav(prevPath, {state: pageNum.toString()});
            } else {
                alert('삭제에 실패했습니다. 관리자에게 문의해주세요.');
            }
                
        }
    }

    // 파일 다운로드
    function downloadFile(fileId) {
        window.location.href = process.env.REACT_APP_API_URL + `/api/admin/file/${fileId}`
    }

    function goBack() {
        nav(prevPath, {state: pageNum.toString()});
    }

    return (
        <DivArea id='PostWrite'>
            <h1 style={{marginTop: 0}}>{menuName}</h1>
            <Table>
                <colgroup>
                    <col width="200px" />
                    <col width="*" />
                </colgroup>
                <tbody>
                    {/* START ONLY 채용공고 */}
                    {boardId === '2' && <>
                    <Tr>
                        <TdHeader>직군</TdHeader>
                        <Td><InputText type="text" name="entryDepart" ref={refEntryDepart} /></Td>
                    </Tr>
                    <Tr>
                        <TdHeader>지원기간</TdHeader>
                        <Td><input type="date" name="entryDuration" ref={refEntryDuration} /></Td>
                    </Tr>
                    <Tr>
                        <TdHeader>직원유형</TdHeader>
                        <Td>
                            <select style={{ padding: '10px'}} id="employeeType" ref={refEmployeeType}>
                                <option value={'정규직'}>정규직</option>
                                <option value={'계약직'}>계약직</option>
                            </select>
                        </Td>
                    </Tr>
                    </>}
                    {/* END ONLY 채용공고 */}
                    {/* START ONLY Resources */}
                    {boardId === '3' && <>
                    {/* <Tr>
                        <TdHeader>기사 URL<br/>(http or https 를 제외한 URL)</TdHeader>
                        <Td><InputText type="text" name="articleURL" ref={refArticleURL} /></Td>
                    </Tr> */}
                    <Tr>
                        <TdHeader>언론사명</TdHeader>
                        <Td><InputText type="text" name="articlePressName" ref={refArticlePressName} /></Td>
                    </Tr>
                    <Tr>
                        <TdHeader>메인 노출 여부</TdHeader>
                        <Td>
                            <input type="checkbox" name="isMain" defaultChecked={true} ref={refIsMain} />
                        </Td>
                    </Tr>
                    <Tr>
                        <TdHeader>카테고리</TdHeader>
                        <Td>
                        <select style={{ padding: '10px'}} id="categoryId" ref={refCategoryId}>
                            <option value={'2'}>Seminar/Webinar</option>
                            <option value={'3'}>Press Release</option>
                            <option value={'4'}>Media</option>
                            <option value={'5'}>Case studies</option>
                            <option value={'6'}>BV Contents</option>
                        </select>
                        </Td>
                    </Tr>
                    </>}
                    <Tr>
                        <TdHeader>제목</TdHeader>
                        <Td><InputText type="text" name="title" ref={refTitle} /></Td>
                    </Tr>
                    {boardId === '3' && <>
                    <Tr>
                        <TdHeader>Lead</TdHeader>
                        <Td>
                            <InputTextArea type="textarea" name="lead" ref={refLead} placeholder='줄바꿈은 <br>을 입력'/>
                            {/* <div id='leadtest' style={{height: '50px'}}>aa</div> */}
                        </Td>
                    </Tr>
                    </>}
                    {/* END ONLY Resources */}
                    <Tr id='tr-writerName'>
                        <TdHeader>작성자</TdHeader>
                        <Td><InputText type="text" name="writerName" ref={refWriterName} /></Td>
                    </Tr>
                    <Tr id='tr-editor'>
                        <TdHeader>내용</TdHeader>
                        {/* <Td><div id="editor" ref={refEditor}></div></Td> */}
                        <Td><div id="summernote_post"></div></Td>
                    </Tr>
                    <Tr>
                        <TdHeader>메인 노출 날짜</TdHeader>
                        <Td><input type="date" name="viewDate" ref={refViewDate} /></Td>
                    </Tr>
                    <Tr>
                        <TdHeader>공개여부</TdHeader>
                        <Td>
                            <label><input type="radio" name="postStatCode" value="01" defaultChecked />공개</label>
                            <label><input type="radio" name="postStatCode" value="02" />비공개</label>
                        </Td>
                    </Tr>
                    
                </tbody>
            </Table>

            {/* Resources 썸네일 */}
            {
            boardId === '3' && <>
            <DivHeader>프리뷰(썸네일)</DivHeader>
            <div id="inputedFilesPreview">
                <ul className="exist-file-list">
                    {existFileList.map((file, index) => {
                        if(file.isDeleted === false && file.isPreview === true) {
                            return (
                                <li key={index} data-fileid={file.attachedFileId}>
                                    <span style={{cursor: 'pointer'}} onClick={() => downloadFile(file.attachedFileId)}>{file.originalFileName}</span>
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteFileInExist(file.attachedFileId)}>삭제</button>
                                    {/* <img alt='' id="inputFileBtn" src={process.env.REACT_APP_API_URL + `/api/admin/file/${file.attachedFileId}`} /> */}
                                </li>
                            )     
                        }
                    })}
                </ul>
            </div>
            <div id='inputFilesPreview'></div>
            </>
            }

            <hr />

            {/* 첨부파일 */}
            { boardId !== '6' && <DivHeader>첨부파일</DivHeader> }
            <div id="inputedFiles">
                <ul className="exist-file-list">
                    {existFileList.map((file, index) => {
                        if(file.isDeleted === false && file.isPreview === false) {
                            return (
                                <li key={index} data-fileid={file.attachedFileId}>
                                    <span style={{cursor: 'pointer'}} onClick={() => downloadFile(file.attachedFileId)}>{file.originalFileName}</span>
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteFileInExist(file.attachedFileId)}>삭제</button>
                                </li>
                            )     
                        }
                    })}
                </ul>
            </div>
            <div id='inputFiles'></div>

            <hr />

            {/* 버튼들 */}
            <ButtonGroup >
                <Button onClick={() => goBack()}>목록으로</Button>
                <Button onClick={() => write()}>저장</Button>
                {postId !== '0' && <Button onClick={() => deletePost()}>삭제하기</Button>}
                {boardId !== '6' && <Button onClick={() => addInputFileEl()} >첨부파일 추가</Button>}
                {boardId === '3' && <Button onClick={() => addInputFileEl(true)} >프리뷰(썸네일) 추가</Button>}
            </ButtonGroup>
        </DivArea>
    )
}