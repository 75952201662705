import React, {Component} from 'react';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class SpectrumValue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile : deviceDetect()
        };
    }
    render() {
        const {data, pos} = this.props;
        const position = this.findPosition(pos);

        if (this.state.isMobile) {
          return (
              <div className={"valueCircle top"+ pos}>
                  {data}
              </div>
          );
        };
        return (
            <div className={"valueCircle "+ position}>
                {data}
            </div>
        );
    }

    findPosition = (pos) => {
        switch (pos) {
            case 0:
                return 'top left'
            case 1:
                return 'top right'
            case 2:
                return 'bottom left'
        }

        return 'bottom right'
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default SpectrumValue;