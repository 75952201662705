import React, {Component} from 'react';
import CompanyCard from "./CompanyCard";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class CompanyList extends Component {
    state = {
        companyCardList : [],
        isMobile : deviceDetect()
    };

    render() {
        const companyCards = this.state.companyCardList.map((card, index) => <CompanyCard key={card.id} card={card} />);

        if (this.state.isMobile) {
            return (
                <div className="companyList_mobile">
                    {companyCards}
                </div>
            );
        }

        return (
            <div className="companyList">
                {companyCards}
            </div>
        );
    }

    getCompanyCard = () => {
        const cards = [
            {
                id : 0,
                name : 'KDB산업은행',
                url : require('../../../../static/assets/components/Investment/logo_kdb.png')
            },
            {
                id : 1,
                name : '신한은행',
                url : require('../../../../static/assets/components/Investment/logo_shinhan.png')
            },
            {
                id : 2,
                name : '하나은행',
                url : require('../../../../static/assets/components/Investment/logo_hana.png')
            },
            {
                id : 3,
                name : 'KB인베스트먼트',
                url : require('../../../../static/assets/components/Investment/logo_kbinvestment.png')
            },
            {
                id : 4,
                name : 'Enlight Ventures',
                url : require('../../../../static/assets/components/Investment/logo_enlight ventures.png')
            },
            {
                id : 5,
                name : '신한 DS',
                url : require('../../../../static/assets/components/Investment/logo_shinhands.png')
            },
            {
                id : 6,
                name : 'KT estate',
                url : require('../../../../static/assets/components/Investment/logo_kt estate.png')
            },
            {
                id : 7,
                name : '야놀자 클라우드',
                url : require('../../../../static/assets/components/Investment/logo_yanolja cloud.jpg')
            },
            {
                id : 8,
                name : 'KoDATA',
                url : require('../../../../static/assets/components/Investment/logo_kodata.png')
            },
        ];

        this.setState({
            companyCardList : cards
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getCompanyCard();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default CompanyList;