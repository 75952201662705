import React, {Component, Suspense} from 'react';
import GeoDataAI from "../../components/About/AboutUs/AboutGeoDataAI/GeoDataAI";
import BusinessInsight from "../../components/About/AboutUs/AboutInsight/BusinessInsight";
import Investment from "../../components/About/AboutUs/AboutInvestment/Investment";
import History from "../../components/About/AboutUs/AboutHistory/History";
import '../../static/css/pages/about.css';
import LoadingIndicator from "../../static/utils/LoadingIndicator/loadingIndicator";
import Announcement from "../../components/About/Announcement/announcement";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";


class About extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div className="aboutContainer_mobile">
                    <div className="geoDataBackground_mobile">
                        <GeoDataAI />
                        <BusinessInsight />
                    </div>
                    {/*<Suspense fallback={<LoadingIndicator />}>*/}
                    <Investment />
                    {/*</Suspense>*/}
                    <Announcement />
                    <div className="historyBackground_mobile">
                        <History />
                    </div>
                </div>
            )
        }
        return (
            <div className="aboutContainer">
                <div className="geoDataBackground">
                    <GeoDataAI />
                    <Suspense fallback={<LoadingIndicator />}>
                        <BusinessInsight />
                    </Suspense>
                </div>
                <Suspense fallback={<LoadingIndicator />}>
                    <Investment />
                </Suspense>
                <Announcement />
                <div className="historyBackground">
                    <History />
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default About;