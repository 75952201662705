import React from "react";
import styles from './index.module.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class Aideveloper extends React.Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return (
              <div className={styles.background_mb}>
                  <div className={styles.top_mb}>
                      <div className={styles.header_mb}>Service</div>
                      <div className={styles.title_mb}>
                          <div>SSS</div>
                          <div className={styles.title_middle_mb}>vs</div>
                          <div>DSS</div>
                      </div>
                  </div>
                  {/*<div className={styles.svgTop}>*/}
                  {/*    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">*/}
                  {/*        <path d="M 0 0 V 140" fill="none" stroke="#1473E6" strokeWidth={2}/>*/}
                  {/*    </svg>*/}
                  {/*</div>*/}
                  <div className={styles.items_mb}>
                      <div className={styles.item_mb}>
                          <div className={styles.item_title_mb}>
                              <div>Standard</div>
                              <div>Searching Service</div>
                          </div>
                          <div className={styles.item_body_mb + " " + styles.mb5}>
                              <div>표준 AI 알고리즘</div>
                              <div>표준 공간데이터</div>
                              <div>검색 대상 필지 제한</div>
                              <div>분석 사업 범위 제한</div>
                          </div>
                      </div>
                      <div className={styles.item_mb}>
                          <div className={styles.item_title_mb + " " + styles.mt5}>
                              <div>Deep</div>
                              <div>Searching Service</div>
                          </div>
                          <div className={styles.item_body_mb}>
                              <div>커스텀 알고리즘</div>
                              <div>커스텀 공간 정보</div>
                              <div>검색 대상 무제한</div>
                              <div>분석 사업 범위 무제한</div>
                          </div>
                      </div>
                  </div>
                  {/*<div className={styles.svgBottom}>*/}
                  {/*    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">*/}
                  {/*        <path d="M 0 0 V 130" fill="none" stroke="#1473E6" strokeWidth={2}/>*/}
                  {/*    </svg>*/}
                  {/*</div>*/}
              </div>
            );
        }
        return (<div className={styles.bkgnd} >

            <div className={styles.content}>
                <div className={styles.left}>
                    <div className={styles.title}>Service</div>
                    <div className={styles.head}> <span className={styles.head_highlight}>SSS vs DSS</span></div>
                </div>

                <div className={styles.items}>
                    <div className={styles.item}>
                        <div className={styles.item_no}>Standard<br/> Searching Service</div>
                        <div className={styles.item_text}>표준 AI 알고리즘<br/>표준 공간데이터<br/>검색 대상 필지 제한<br/>분석 사업 범위 제한</div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.item_no}>Deep<br/> Searching Service</div>
                        <div className={styles.item_text}>커스텀 알고리즘<br/>커스텀 공간 정보<br/>검색 대상 무제한<br/>분석 사업 범위 무제한</div>
                    </div>
                </div>
            </div>

        </div>);
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Aideveloper;