import React, {Component} from 'react';
import SpectrumValue from "./spectrumValue";
import line_bottom from "../../../static/assets/components/spectrum/line/line_bottom.png";
import line_left from "../../../static/assets/components/spectrum/line/line_left.png";
import line_right from "../../../static/assets/components/spectrum/line/line_right.png";
import line_right_bottom from "../../../static/assets/components/spectrum/line/line_right_bottom.png";
import line_right_top from "../../../static/assets/components/spectrum/line/line_right_top.png";
import line_top from "../../../static/assets/components/spectrum/line/line_top.png";
import line_top_left from "../../../static/assets/components/spectrum/line/line_top_left.png";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

class SpectrumCenter extends Component {
    state = {
        circleData : [],
        isMobile : deviceDetect()
    }

    render() {
        const innerCircle = this.state.circleData.map((circle, index) => <SpectrumValue key={index} data={circle} pos={index} />)
        if (this.state.isMobile) {
            return(
              <div className="spectrumCenter_mobile">
                  <div className="spectrumLine">
                      <div className="spectrumStick">
                          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                              <path d="m 0 30 v 600" fill="none" stroke="white" strokeWidth="5" strokeDasharray="0.005 16" strokeLinecap="round" strokeDashoffset="3000" />
                          </svg>
                          {innerCircle}
                      </div>
                  </div>
              </div>
            );
        }
        return (
            <div className="spectrumCenter">
                <div className="spectrumInf">
                    <svg xmlns="http://www.w3.org/2000/svg" width="900px" height="820px" viewBox="3 4 250 250">
                        <path className="dot-inf" d="m 103.63281,149.48096 -8.60449,9.71533 C 82.425886,171.7311 63.519203,175.45224 47.10709,168.62798 30.695009,161.80373 20.001644,145.7747 20.001644,128 c 0,-17.7747 10.693365,-33.80373 27.105446,-40.627976 16.412113,-6.82426 35.318796,-3.10312 47.92123,9.431686 65.56579,62.26919 65.94336,62.39258 65.94336,62.39258 12.60243,10.63743 31.50912,16.25595 47.92123,9.43169 16.41208,-6.82425 27.10545,-22.85328 27.10545,-40.62798 0,-17.7747 -10.69337,-33.80373 -27.10545,-40.627976 -16.41211,-6.82426 -35.3188,-3.10312 -47.92123,9.431686 l -8.60449,9.71533"/>
                    </svg>
                    {innerCircle}
                    <div className="spectrumLines">
                        <img className="line_bottom" src={line_bottom} alt="line_bottom" onError={(e) => e.target.src = `${noImg}`}/>
                        <img className="line_left" src={line_left} alt="line_left" onError={(e) => e.target.src = `${noImg}`}/>
                        <img className="line_right" src={line_right} alt="line_right" onError={(e) => e.target.src = `${noImg}`}/>
                        {/*<img className="line_top" src={line_top} alt="line_bottom" onError={(e) => e.target.src = `${noImg}`}/>*/}
                        <img className="line_right_bottom" src={line_right_bottom} alt="line_right_bottom" onError={(e) => e.target.src = `${noImg}`}/>
                        <img className="line_right_top" src={line_right_top} alt="line_right_top" onError={(e) => e.target.src = `${noImg}`}/>
                        <img className="line_top_left" src={line_top_left} alt="line_top_left" onError={(e) => e.target.src = `${noImg}`}/>
                    </div>
                </div>
            </div>
        );
    }

    getCircleData = () => {
        const data = [
            'API',
            'Web Service',
            'Data',
            'SaaS'
        ];

        this.setState({
            circleData : data
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getCircleData();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default SpectrumCenter;