import React, {Component} from 'react';
import HistoryYearList from "./HistoryYearList";
import '../../../../static/css/components/AboutHistory.css';
import axios from "axios";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";


class History extends Component {
    state = {
        historyList : [],
        firstHistory : {
            year : 2015,
            month : 5,
            prefix : '케이앤컴퍼니',
            postfix : '(現 빅밸류) 설립'
        },
        isMobile : deviceDetect()
    };

    render() {
        const histories = this.state.historyList.map((hist, index) => <HistoryYearList key={index} year={hist} white={index%2 === 0 ? true: false} />)

        if (this.state.isMobile) {
            return (
                <div className="historyComponent_mobile">
                    <div className="vertical-div-mobile" />
                    <div className="history-background-white">
                        <div className="historyHeader">빅밸류 <span className="bold">History</span></div>
                    </div>
                    <div className="history-main_mobile">
                        {histories}
                    </div>
                    <div className="establishCircleOut">
                        <div className="establishCircleIn">
                            <div className="establishYear">{this.state.firstHistory.year}</div>
                            <div className="establishMonth">{this.paddingNum(this.state.firstHistory.month)}월</div>
                            <div className="establishBody">{this.state.firstHistory.prefix}</div>
                            <div className="establishBody">{this.state.firstHistory.postfix}</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="historyComponent">
                <hr className="vertical-middle-line" />
                <div className="history-background-white">
                    <div className="historyHeader">빅밸류 <span className="bold">History</span></div>
                </div>
                <div>
                    {histories}
                </div>
                <div className="establishCircleOut">
                    <div className="establishCircleIn">
                        <div className="establishYear">{this.state.firstHistory.year}</div>
                        <div className="establishMonth">{this.paddingNum(this.state.firstHistory.month)}월</div>
                        <div className="establishBody">{this.state.firstHistory.prefix}</div>
                        <div className="establishBody">{this.state.firstHistory.postfix}</div>
                    </div>
                </div>
            </div>

        );
    }

    getHistories = () => {
        const PARAMS = {
            boardId : 6,
            categoryId : 1
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/postListNoPage", {
            params : PARAMS
        })
            .then((Response) => {
                let data = [];
                let first = Response.data.splice(0,1)[0];

                Response.data.forEach((hist)=> {
                    let parse = hist.viewDate.split('.')
                    let isYFind = false;
                    data.forEach((d) => {
                        if (d.year === parse[0]) {
                            let isMFind = false;
                            isYFind = true;
                            d.monthData.forEach((m) => {
                                 if (m.month === parse[1]) {
                                     m.datas.unshift(hist.title)
                                     isMFind = true;
                                 }
                            });
                            if (!isMFind) {
                                d.monthData.unshift({
                                    month : parse[1],
                                    datas : [
                                        hist.title
                                    ]
                                })
                            }
                        }
                    })
                    if (!isYFind) {
                        data.unshift({
                            year : parse[0],
                            monthData : [
                                {
                                    month : parse[1],
                                    datas : [
                                        hist.title
                                    ]
                                }
                            ]
                        })
                    }
                });

                let start = true;
                data.forEach((d) => {
                    d.startWithLeft = start;
                    start = d.monthData % 2 == 0;
                })

                this.setState({
                    historyList : data
                })
            })
            .catch((Error)=>{console.log(Error)});
    };

    paddingNum = (mon) => {
        return String(mon).padStart(2, '0');
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getHistories();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default History;