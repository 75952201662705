import React, {Component} from 'react';
import ProcessLevel from "./processLevel";
import '../../../../static/css/components/process.css';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class Process extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div className="processComponent_mobile">
                    <div className="processHeader_mobile">
                        <div className="processIntro_mobile">Process</div>
                        <div className="processTitle_mobile">채용 절차</div>
                    </div>
                    <ProcessLevel />
                    <div className="processNotice_mobile">
                        <div className="noticeHeader">입사지원 전 확인해주세요.</div>
                        <ul className="noticeBody">
                            <li>입사지원시 본인의 성명, 연락처, 이메일 주소 등을 정확하게 기재 해주세요. 이력서에 기재된 학력 및 경력 사항 및 포트폴리오가 거짓일 경우 합격이 취소될 수 있습니다.</li>
                            <li>면접 일정은 서류 전형 합격자에 한해 개별 안내 드리며 1차 실무자 면접, 2차 임원 면접 순으로 진행합니다.</li>
                            <li>2차, 최종 면접 후 합격, 탈락 여부 개별 통보해 드립니다.</li>
                            <li>최종 합격자는 개별 통보합니다.</li>
                            <li>빅밸류 지원 관련 문의는 이메일 <CopyToClipboard className="contact_copy_text" text="recruit@bigvalue.co.kr" onCopy={() => alert("클립보드에 복사되었습니다.")}><span>recruit@bigvalue.co.kr</span></CopyToClipboard>으로 언제든 보내 주세요.</li>
                        </ul>
                    </div>
                </div>
            )
        }

        return (
            <div className="processComponent">
                <div className="processHeader">
                    <div className="processIntro">Process</div>
                    <div className="processTitle">채용 절차</div>
                </div>
                <ProcessLevel />
                <div className="processNotice">
                    <div className="noticeHeader">입사지원 전 확인해주세요.</div>
                    <ul className="noticeBody">
                        <li>입사지원시 본인의 성명, 연락처, 이메일 주소 등을 정확하게 기재 해주세요. 이력서에 기재된 학력 및 경력 사항 및 포트폴리오가 거짓일 경우 합격이 취소될 수 있습니다.</li>
                        <li>면접 일정은 서류 전형 합격자에 한해 개별 안내 드리며 1차 실무자 면접, 2차 임원 면접 순으로 진행합니다.</li>
                        <li>1차, 2차, 최종 면접 시 모든 면접자분께 합격, 탈락 여부 개별 통보해 드립니다.</li>
                        <li>최종 합격자는 개별 통보합니다.</li>
                        <li>빅밸류 지원 관련 문의는 이메일 <CopyToClipboard className="contact_copy_text" text="recruit@bigvalue.co.kr" onCopy={() => alert("클립보드에 복사되었습니다.")}><span>recruit@bigvalue.co.kr</span></CopyToClipboard>으로 언제든 보내 주세요.</li>
                    </ul>
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Process;