
import ic1 from "./assets/ic_토지.svg";
import ic2 from "./assets/ic_인허가.svg";
import ic3 from "./assets/ic_건축물.svg";
import ic4 from "./assets/ic_실거래가.svg";
import ic5 from "./assets/ic_부수정보.svg";
import createServiceData from "../../ServiceData";


const VadvisorServiceData = createServiceData({

    type: 'Vadvisor',
    title: 'V-Advisor',
    afterWord: '가',

    itemsProps: [{
        category: '토지/지적',

        iconImage: ic1,

        dataList: [
            '토지대장',
            '지적도',
            '표준지',
            '공시지가',
            '이용현황'
        ]
    }, {
        category: '계획/인허가',

        iconImage: ic2,

        dataList: [
            '용도지역',
            '지구단위',
            '건축인허가',
            '주택인허가',
            '사용승인'
        ]
    }, {
        category: '건축물',

        iconImage: ic3,

        dataList: [
            '총괄표제',
            '표제부',
            '전유부',
            '대지권',
            '감가상각'
        ]
    }, {
        category: '실거래가',

        iconImage: ic4,

        dataList: [
            '토지실거래',
            '상업/업무 실거래',
            '단독/다가구',
            '등기부등본'
        ]
    }, {
        category: '부수정보',

        iconImage: ic5,

        dataList: [
            '지변율',
            '격차율',
            '신축단가'
        ]
    }]
});

export default VadvisorServiceData;