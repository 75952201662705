import React, { useState, useEffect } from 'react';
import BackGroundVideo from '../../../static/assets/components/leadership/wave_effect.mp4';
import '../../../static/css/components/Leadership.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

const Leadership = () => {

    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    if (isMobile) {
        return (
          <div className="Leadership_mobile">
              <div className="bgvideoLeadership_mobile">
                  <video muted autoPlay loop>
                      <source src={BackGroundVideo} type="video/mp4" />
                  </video>
              </div>
              <div className="lsTitleArea_mobile">
                  <div className="lsTitle_mobile">Vision</div>
                  <div className="lsTitleDesc_mobile">공간AI, 세상을 밝히다.</div>
              </div>
              <div className="lsContentArea_mobile">
                  {/* <div id='lsWeAre' className="lsWeAre">우리는,</div> */}
                  <div className="lsContent_mobile scrollup">
                      <p>
                          기술이 더 나은 내일을 <br />
                          만들 수 있다는 믿음.
                      </p>
                      <p>
                          우리는 인공지능시세를 시작으로 <br />
                          공간AI라는 새로운 시장을 개척하고 있습니다.
                      </p>
                      <p>
                          가보지 않은 길이기에 매번 새로운 <br />
                          문제에 직면했습니다.
                      </p>
                      <p>
                          그러나, 문제가 해결되면 그것이 곧 <br />
                          혁신임을 깨달았습니다.
                      </p>
                      <p>
                          우리는 공간데이터 그리고 인공지능 시장의 <br />
                          선두 주자로 걸음마다 흔적을 남기고 있습니다.
                      </p>
                      <p>
                          기술로 세상을 밝히겠다는 의지 <br />
                          혁신을 이뤄낼 거라는 집념 <br />
                          기술력으로 증명해내는
                      </p>
                      <p>
                          우리는 빅밸류입니다.
                      </p>
                  </div>
                  <div className='mask_mobile'></div>
              </div>
          </div>
        );
    }

    return (
        <div id='Leadership' className="Leadership ver2">
            <div className="bgvideoLeadership">
                <video muted autoPlay loop>
                    <source src={BackGroundVideo} type="video/mp4" />
                </video>
            </div>
            {/* version 1 */}
            {/* <div id='lsTitleArea' className="lsTitleArea">
                <div id='lsTitle' className="lsTitle">Leadership</div>
                <div id='lsTitleDesc' className="lsTitleDesc">빅데이터 시대의 <br/>미래를 준비합니다</div>
            </div>
            <div id='lsContentArea' className="lsContentArea">
                <div id='lsWeAre' className="lsWeAre">우리는,</div>
                <div id='lsContent' className="lsContent scrollup">
                    <p><span className='bold'>자유</span>와 <span className='bold'>책임</span>을 믿습니다.</p>
                    <p>데이터를 <span className='bold'>사랑</span>하고 기술을 <span className='bold'>신뢰</span>합니다.</p>
                    <p>동료를 <span className='bold'>존중</span>하고 다름을 <span className='bold'>인정</span>합니다.</p>
                    <p><span className='bold'>혁신</span>을 선도하고 <span className='bold'>변화</span>를 즐깁니다.</p>
                    <p><span className='bold'>토론</span>하고 <span className='bold'>공유</span>합니다.</p>
                    <p>고객의 <span className='bold'>가치</span>를 높입니다.</p>
                </div>
            </div> */}
            {/* version 2 */}
            <div id='lsTitleArea' className="lsTitleArea">
                <div id='lsTitle' className="lsTitle">Vision</div>
                <div id='lsTitleDesc' className="lsTitleDesc">공간AI, 세상을 밝히다.</div>
            </div>
            <div id='lsContentArea' className="lsContentArea">
                {/* <div id='lsWeAre' className="lsWeAre">우리는,</div> */}
                <div id='lsContent' className="lsContent scrollup">
                    <p>기술이 더 나은 내일을</p>
                    <p>만들 수 있다는 믿음.</p>
                    <br/>
                    <br/>
                    <p>우리는 인공지능시세를 시작으로</p>
                    <p>공간AI라는 새로운 시장을 개척하고 있습니다.</p>
                    <br/>
                    <br/>
                    <p>가보지 않은 길이기에 매번 새로운</p>
                    <p>문제에 직면했습니다.</p>
                    <br/>
                    <br/>
                    <p>그러나, 문제가 해결되면 그것이 곧</p>
                    <p>혁신임을 깨달았습니다.</p>
                    <br/>
                    <br/>
                    <p>우리는 공간데이터 그리고 인공지능 시장의</p>
                    <p>선두 주자로 걸음마다 흔적을 남기고 있습니다.</p>
                    <br/>
                    <br/>
                    <p>기술로 세상을 밝히겠다는 의지</p>
                    <p>혁신을 이뤄낼 거라는 집념</p>
                    <p>기술력으로 증명해내는</p>
                    <br/>
                    <br/>
                    <p>우리는 빅밸류입니다.</p>
                </div>
            </div>
            <div className='mask'></div>
        </div>
    );
}

export default Leadership;