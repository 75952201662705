import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA4 from 'react-ga4';
import './index.css';

import App from './App';
import Admin from './Admin';

const root = ReactDOM.createRoot(document.getElementById('root'));

// host/admin 형식의 URL이면 관리자 페이지로 렌더링
const isAdmin = window.location.href.search(window.location.host + '/admin') !== -1;

const TRACKING_ID_GA4 = process.env.REACT_APP_GOOGLE_ANALYTICS4_TRACKING_ID;
ReactGA4.initialize(TRACKING_ID_GA4);

root.render(
  <>
    {isAdmin ? <Admin/> : <App/>}
  </>
);
