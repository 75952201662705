import React, {Component} from 'react';
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";

class BusinessInsightCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile : deviceDetect()
        }
    }

    render() {
        const {title, body, logos} = this.props;
        const bodyTag = body.map((content, index) => <li key={index} className="cardContent">{content}</li>);
        const logosTag = logos.map((logo, index) => <div key={index} className="cardLogo"><img src={logo} alt="logo" onError={(e) => e.target.src = `${noImg}`}/></div>);

        if (this.state.isMobile) {
            return (
              <div className="businessCard_mobile">
                  <div className="cardTitle">{title}</div>
                  <div className="cardBody">
                      <ul className="bodyList">
                          {bodyTag}
                      </ul>
                  </div>
                  <div className="cardLogos">
                      {logosTag}
                  </div>
              </div>
            );
        }

        return (
            <div className="businessCard">
                <div className="cardTitle">{title}</div>
                <div className="cardBody">
                    <ul className="bodyList">
                        {bodyTag}
                    </ul>
                </div>
                <div className="cardLogos">
                    {logosTag}
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default BusinessInsightCard;