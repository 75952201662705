import React, {Component} from 'react';
import noImg from "../../../../static/assets/utils/noimg.png";

class CompanyCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { card } = this.props;

        return (
            <div className="companyCard">
                <img src={card.url} alt={card.name} onError={(e) => e.target.src = `${noImg}`} />
            </div>
        );
    }
}

export default CompanyCard;