import React, {Component} from 'react';
import "../../static/css/components/solutionComment.css";
import noImg from "../../static/assets/utils/noimg.png";

class Comments extends Component {
    render() {
        const { title, afterWord, img, commentList} = this.props.data;
        const commentCards = commentList.map((commentData, index) => <div key={index} className="commentCard"><div className="commentCardFlex"><div>{commentData.comment}<div className="commentCardBy">{commentData.by}</div></div><img src={commentData.avatar} alt="avatar" onError={(e) => e.target.src = `${noImg}`}/></div></div>)

        return (
            <div className="commentMobile">
                <div className="commentTop">
                    <div className="commentHeader">Comments</div>
                    <div className="commentTitle">{title} 고객 경험</div>
                    <div className="commentSubHeader">{title}{afterWord} 직접 경험한 고객사의 후기를 확인해보세요!</div>
                    <div className="commentImg">
                        <img src={img} alt="solutionImgs" />
                    </div>
                </div>
                <div className="commentCardList">
                    {commentCards}
                </div>
            </div>
        );
    }
}

export default Comments;