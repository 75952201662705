import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS4_TRACKING_ID);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if(initialized) {
            let path = location.pathname;
            let title = '빅밸류';

            if (path === '/' || path === '') {
                title = '메인';
            } else if (path.includes('dataMap')) {
                title = '데이터맵';
            } else if (path.includes('solution')) {
                title = '솔루션';
            } else if (path.includes('resource')) {
                title = '리소스';
            } else if (path.includes('about')){
                if (path.includes('contactUs') || path.includes('contactus')) title = '컨택트 어스';
                else title = '어바웃 어스';
            } else if (path.includes('admin')) {
                title = '어드민';
            } else {
                title = '오류 페이지';
            }

            ReactGA.send({hitType: "pageview",path: location.pathname, location: location.pathname, title: title, referer: document.referrer})
        }
    }, [initialized, location]);
}

export default RouteChangeTracker;