import React, {Component} from 'react';

class DataCore extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {image, data} = this.props;

        return (
            <div className="dataCore">
                <div className="coreImg">{image}</div>
                <div className="core">{data}</div>
                <div className="core">정보</div>
            </div>
        );
    }
}

export default DataCore;