import React from "react";
import CompanyLocation from "../../components/CompanyLocation";



class CompanyLocationPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {return(<>

        <CompanyLocation />

    </>);}
}

export default CompanyLocationPage;