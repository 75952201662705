import React, {useEffect, useState} from 'react';
import logo from '../../static/assets/components/header/logo.png'
import {useLocation, useNavigate} from "react-router-dom";
import '../../static/css/pages/Admin.css';
import {useAdminInfoActionState, useAdminInfoValueState} from "./AdminInfoStore";
import axios from "axios";
import noImg from "../../static/assets/utils/noimg.png";

const AdminLogin = () => {

    const userInfo = useAdminInfoValueState();
    const action = useAdminInfoActionState();
    const isLogin = userInfo.id !== '' && userInfo.id !== 'undefined';
    const nav = useNavigate();
    const [userId, setUserId] = useState("");
    const [userPw, setUserPw] = useState("");
    const {state} = useLocation();

    useEffect(() => {
        if (isLogin) nav("/");
    },[userInfo])

    useEffect(() => {
        if (state !== undefined && state !== null) {
            alert(state);
            nav("/login")
        }
    },[state])

    function changeValue(event) {
        const name = event.target.name;
        if (name === "password") {
            setUserPw(event.target.value);
        } else {
            setUserId(event.target.value);
        }
    }

    function doLogin() {
        if (userId === "") {
            document.querySelector("#loginId").focus();
            return;
        }
        if (userPw === "") {
            document.querySelector("#loginPassword").focus();
            return;
        }

        let formData = new FormData();

        formData.append("email", userId);
        formData.append("password", userPw);

        axios.post(process.env.REACT_APP_API_URL + '/api/login', formData)
            .then((response) => {
                const message = response.data.message;

                if (message === undefined) {
                    action.logIn({id : userId})
                    localStorage.setItem("login", "true");
                    nav('/');
                } else {
                    alert(message);
                    document.querySelector("#loginId").focus();
                }

            })
            .catch((Error) => {
                alert("로그인 중 문제가 발생했습니다. 잠시 후 다시 시도해주세요.");
            });
    }

    return (
        <div className="AdminLogin">
            <div className="loginHeader">관리자 로그인</div>
            <div className="loginHeader-logo"><img src={logo} alt="로고" width="250px" onError={(e) => e.target.src = `${noImg}`}/></div>
            <div className="loginForm">
                <form>
                    <div className="formInput">
                        <label>아이디</label>
                        <input type="text" id="loginId" name="id" value={userId} onChange={changeValue}/>
                    </div>
                    <div className="formInput">
                        <label>비밀번호</label>
                        <input type="password" id="loginPassword" name="password" value={userPw} onChange={changeValue} autoComplete="false" />
                    </div>
                    <div className="loginBtnGroup">
                        <div className="loginBtn" onClick={doLogin}>로그인</div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;