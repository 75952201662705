import React from "react";
import {isMobile} from "react-device-detect";
import AideveloperContents from "../../components/Solution/AiDeveloper/AideveloperContents";
import AideveloperComments from "../../components/Solution/AiDeveloper/AideveloperComments";
import AideveloperProcess from "../../components/Solution/AiDeveloper/AideveloperProcess";
import AideveloperServiceData from "../../components/Solution/AiDeveloper/AideveloperServiceData";
import aiDeveloperImg from "../../static/assets/components/solutionContent/aiDeveloper/aiDeveloperImg.png";
import ailobigProcess from "../../static/assets/components/solutionContent/aiLobig/ailobigProcess_mobile.png";
import avatar1 from "../../components/Solution/AiDeveloper/AideveloperComments/assets/avatar1.png";
import avatar2 from "../../components/Solution/AiDeveloper/AideveloperComments/assets/avatar2.png";
import avatar3 from "../../components/Solution/AiDeveloper/AideveloperComments/assets/avatar3.png";
import styles from "../../components/Solution/AiDeveloper/AideveloperComments/index.module.css";
import Content from "../../components/Solution/content";
import AilobigServiceData from "../../components/Solution/AiLobig/AilobigServiceData";
import AilobigProcess from "../../components/Solution/AiLobig/AilobigProcess";
import Comments from "../../components/Solution/comments";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";


class AilobigPage extends React.Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            const contentData = {
                id : 2,
                header : 'AI Developer',
                title : '부동산 개발 타당성 검토, AI로 한번에 해결하세요',
                body : <div>주소 입력만으로 다양한 개발 사업의 수요분석, 분양가 예측, 비용 분석을 제공하며, 인허가 난이도를 분석하여 객관적인 사업성 지표를 제공합니다.<div className="subBody">전국 3천만 필지에 대해 최적의 사업성 부지를 빅밸류 빅데이터 기술로 검색해보세요.</div></div>,
                laptop : aiDeveloperImg
            };

            const commentData = {
                title : 'AI Developer',
                afterWord : '를',
                img : ailobigProcess,
                commentList : [
                    {
                        comment : <div className="commentCardBody">이렇게 쉽게 전국 토지를 서칭할 수 있을거라고는 상상해 보지 못했습니다. AI Developer는 <span className="text_highlight">소유자의 구입시점, 가격, 성별, 나이, 재산 사항 등을 모두 분석</span>하여 지주 작업에 필요한 정보 일체를 손쉽게 얻을 수 있습니다.</div>,
                        avatar : avatar1,
                        by : '대형 건설사 개발사업팀 팀장'
                    },
                    {
                        comment : <div className="commentCardBody">AI Developer의 <span className="text_highlight">분양가 산정 과정은 매우 투명하고 직관적</span>이라서 사용하기 편리합니다. 매달 데이터가 갱신되어 관리하거나 검토 중인 모든 사업장의 분양성 정보가 업데이트되어 너무나 편리합니다.</div>,
                        avatar : avatar2,
                        by : '국내 10대 주택 브랜드 건설사 마케팅팀'
                    },
                    {
                        comment : <div className="commentCardBody">국내 일반 산업단지의 허가 시점, 분양시점, 입주시점 정보를 모두 보유하고 해당 시점으로 돌아가서 <span className="text_highlight">시뮬레이션이 가능한 분석 기능을 제공해 줍니다.</span> <span className="text_highlight">딥서치 기능을 통해 </span>쉽게 산업단지 분양성 지표를 얻을 수 있었습니다.</div>,
                        avatar : avatar3,
                        by : '국내 대형 할인마트 데이터 담당 팀장'
                    }
                ]
            };

            return (
              <div>
                  <Content data={contentData} />
                  <AideveloperServiceData/>
                  <AideveloperProcess/>
                  <Comments data={commentData} />
              </div>
            );
        }

        return(
            <>
                <AideveloperContents/>
                <AideveloperServiceData/>
                <AideveloperProcess/>
                <AideveloperComments/>
            </>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default AilobigPage;