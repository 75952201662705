import React, {Component} from 'react';
import DataCore from "./DataCore";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";

class InsightShape extends Component {
    state = {
        dataCores : [],
        isMobile : deviceDetect()
    };

    render() {
        const dataCore = this.state.dataCores.map((core, index) => <DataCore key={index} image={core.image} data={core.data} />);

        if (this.state.isMobile) {
            return (
                <div className="insightShapeComponent_mobile">
                    <div className="dataCoreSet_mobile">
                        {dataCore}
                    </div>
                    <div className="coreMiddle_mobile"></div>
                    <div className="bigvalueDataCore_mobile">
                        <div className="top">BIGVALUE</div>
                        <div className="bottom">Data Core</div>
                    </div>
                </div>
            );
        }

        return (
            <div className="insightShapeComponent">
                <div className="hr-sect">
                    <div className="dataCoreSet">
                        {dataCore}
                    </div>
                    <div className="bigvalueDataCore">
                        <div className="top">BIGVALUE</div>
                        <div className="bottom">Data Core</div>
                    </div>
                </div>
            </div>
        );
    }

    getDataCore = () => {
        const cores = [
            {
                image : <img src={require("../../../../static/assets/components/businessInsight/토지건축물_icon.png")} alt="icon" onError={(e) => e.target.src = `${noImg}`}/>,
                data : '토지/건축물'
            },
            {
                image : <img src={require("../../../../static/assets/components/businessInsight/도시계획_icon.png")} alt="icon" onError={(e) => e.target.src = `${noImg}`}/>,
                data : '도시 계획'
            },
            {
                image : <img src={require("../../../../static/assets/components/businessInsight/인구분포_icon.png")} alt="icon" onError={(e) => e.target.src = `${noImg}`}/>,
                data : '인구분포'
            }
        ];

        this.setState({
            dataCores : cores
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getDataCore();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default InsightShape;