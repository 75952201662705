import React, {Component} from 'react';
import styles from "./index.module.css";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class PrivacyStatement extends Component {

  state = {
    isMobile: deviceDetect()
  }

  render() {
    const index = [
      "개인정보의 수집",
      "개인정보의 이용목적",
      "개인정보의 보유 및 이용 기간",
      "개인정보의 파기",
      "개인정보의 제공",
      "이용자의 권리보호",
      "개인정보의 기술적/관리적 보호 대책",
      "개인정보관리책임자 및 담당 부서의 연락처",
      "링크 사이트에 대한 책임",
      "쿠키의 운용 및 거부",
      "고지의 의무"
    ]
    const indexList = index.map((i, index) => <li key={index}>{i}</li>)
    if (this.state.isMobile) {
      return (
        <div className={styles.form_textarea2_mb}>
          <div>
            <div>
              <span className={styles.blue + " " + styles.fw700}>㈜빅밸류</span>
              <span>(이하 "회사"라 함)는 「신용정보의 이용 및 보호에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」, 「통신비밀보호법」 등 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 따른 개인정보 취급 방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.</span>
            </div>
            <div>본 개인정보 취급 방침은 회사가 제공하는 제반 서비스 이용에 적용되며 다음과 같은 내용을 담고 있습니다.</div>
          </div>
          <div>
            <ol>
              {indexList}
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>1. 개인정보의 수집</div>
            <ol className={styles.circleOl}>
              <li>
                <div>회사가 이용자의 개인정보를 수집하는 경우에는 아래의 원칙을 준수하고 있으며, 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구합니다.</div>
                <ol className={styles.textOl}>
                  <li>
                    <div>서비스 제공에 필요한 최소한의 개인정보를 수집합니다.</div>
                    <ul className={styles.hyphenUl}>
                      <li>필수정보의 수집: 해당 서비스의 본질적 기능을 수행하기 위한 정보를 필수항목으로 수집합니다.</li>
                      <li>선택정보의 수집: 이용자에게 특화된 서비스를 제공하기 위해 최소한의 정보를 추가 수집하는 경우, 선택항목으로 수집합니다. 다만, 선택항목을 입력하지 않는 경우에도 다른 서비스 이용 제한은 없습니다.</li>
                    </ul>
                  </li>
                  <li>
                    <div>민감 정보를 수집하지 않습니다.</div>
                    <ul className={styles.hyphenUl}>
                      <li>회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록, 의료정보 등)를 수집하지 않습니다. 단, 법령에서 민감 정보의 처리를 요구하거나 허용하는 경우에는 반드시 이용자에게 해당 사실을 알리고 사전 동의를 구하도록 합니다.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <div>회사가 수집하는 개인정보 항목은 아래와 같습니다.</div>
                <div className={styles.ptb10}>[개인정보 수집 항목]</div>
                <table className={styles.table + " " + styles.mtb5}>
                  <thead>
                  <tr>
                    <th colSpan="4">개인정보 수집 항목</th>
                  </tr>
                  <tr>
                    <th scope="col">구분</th>
                    <th scope="col">개인정보 수집 항목</th>
                    <th scope="col">수집 근거</th>
                    <th scope="col">수집, 이용목적</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>웹사이트 내 문의하기</td>
                    <td>이름, 이메일, 전화번호, 회사명</td>
                    <td>이용자의 동의</td>
                    <td>문의 사항 및 처리 결과 회신</td>
                  </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <div>회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다</div>
                <ol className={styles.textOl}>
                  <li>홈페이지, 서면 양식, 팩스, 전화, 고객센터, 이메일, 협력회사로부터의 제공, 생성 정보 수집 툴을 통한 수집</li>
                  <li>홈페이지를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</li>
                </ol>
              </li>
              <li>
                회사는 개인정보의 수집 · 이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다. 단, 법정대리인의 동의를 얻은 경우에는 만 14세 미만 이용자의 개인정보를 수집•이용할 수 있습니다.
              </li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>2. 개인정보의 이용목적</div>
            <div className={styles.pt5 + " " + styles.pl15}>회사는 이용자의 소중한 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 사전에 이용자의 동의를 구합니다.</div>
            <div className={styles.ptb5 + " " + styles.pl25}>웹사이트 : 이름, 이메일, 전화번호, 회사명</div>
            <div className={styles.pb5 + " " + styles.pl15}>회사는 서비스 제공을 위하여 수집한 모든 개인정보와 생성 정보를 문의 사항 및 처리결과 회신용으로 이용합니다.</div>
          </div>
          <div>
            <div className={styles.fw700}>3. 개인정보의 보유 및 이용 기간</div>
            <ol className={styles.circleOl}>
              <li>이용자의 개인정보는 개인정보의 수집 및 이용목적 달성을 위해 이용 기간에만 제한적으로 이용하고 있습니다. 다만, 관계 법령에 의해 보관해야 하는 정보는 법령이 정한 기간 보관합니다.</li>
              <li>회원에서 탈퇴한 후의 민원 처리를 위하여 내부지침에 따라 회원 탈퇴 후 1년간 CI/DI, 성명, 생년월일, 내/외국인 구분, 성별, 연락처 정보를 보관합니다.</li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>4. 개인정보의 파기</div>
            <ol className={styles.parenthesisOl}>
              <li>법령에 의하여 수집, 이용되는 이용자의 정보</li>
              <table className={styles.table + " " + styles.mtb5}>
                <thead>
                <tr>
                  <th>법령</th>
                  <th>보유·이용목적</th>
                  <th>보유항목</th>
                  <th>보유·이용 기간</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td rowSpan="2">통신비밀보호법</td>
                  <td>법원의 영장을 받아 수사기관이 요청 시 제공</td>
                  <td>로그기록, 접속지 정보 등</td>
                  <td>3개월</td>
                </tr>
                <tr>
                  <td>표시·광고에 관한 기록</td>
                  <td>표시·광고 기록</td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td rowSpan="3">전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                  <td>대금결제 및 재화 등의 공급 기록</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>계약 또는 청약 철회 등에 관한 기록</td>
                  <td>소비자 식별정보 계약/청약 철회 기록</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>소비자 불만 또는 분쟁 처리에 관한 기록</td>
                  <td>소비자 식별정보 분쟁 처리 기록</td>
                  <td>3년</td>
                </tr>
                </tbody>
              </table>
              <li>회사 내부 정책에 의하여 수집, 이용되는 이용자의 정보</li>
              <table className={styles.table + " " + styles.mtb5}>
                <thead>
                <tr>
                  <th>구분</th>
                  <th>보유·이용목적</th>
                  <th>보유항목</th>
                  <th>보유·이용 기간</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>회사 내부 정책</td>
                  <td>웹 문의 사항 답변</td>
                  <td>이름, 이메일, 전화번호, 회사명</td>
                  <td>웹사이트 내 문의 후 1년</td>
                </tr>
                </tbody>
              </table>
            </ol>
            <div className={styles.pl15}>이용자의 개인정보는 개인정보의 수집 · 이용목적이 달성되면 해당 정보를 지체 없이 파기합니다. 다만, 관계 법령에 의해 개인정보를 보관해야 하는 경우에는 기존 저장 공간과 구분된 별도의 저장공간에 옮겨서 정해진 기간 저장한 후 파기합니다. 이때 별도 저장되는 개인정보는 법령에 의한 경우 외에는 절대 다른 용도로 이용되지 않습니다.</div>
            <div className={styles.pb5 + " " + styles.pl15}>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적인 방법을 이용하여 완전하게 삭제하고, 종이로 출력된 개인정보는 분쇄하거나 소각하여 파기합니다.</div>
            <div className={styles.pl15}>개인정보 파기의 절차 및 방법은 다음과 같습니다.</div>
            <ol className={styles.circleOl}>
              <li>
                <div>파기 절차</div>
                <div>회사는 파기 사유가 발생한 시점부터 별도 지정한 보유·이용 기간이 지난 시점에 바로 파기합니다.</div>
              </li>
              <li>
                <div>파기 방법</div>
                <div>회사는 전자적 파일형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적인 방법 또는 물리적인 방법을 이용하여 파기하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.</div>
              </li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>5. 개인정보의 제공</div>
            <ol className={styles.circleOl}>
              <li>약속한 서비스를 제공하기 위해 다음과 같은 업무를 위탁하고 있습니다. 이용자에게 약속한 서비스를 제공하는 데에 반드시 필요한 업무 중 일부를 외부 업체가 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁받은 업체가 개인정보보호 관계 법령을 위반하지 않도록 관리 · 감독하고 있습니다.</li>
              <div className={styles.ptb10}>[개인정보 위탁업체]</div>
              <table className={styles.table + " " + styles.mtb5}>
                <thead>
                <tr>
                  <th colSpan="3">개인정보 위탁업체</th>
                </tr>
                <tr>
                  <th>위탁업체</th>
                  <th>위탁업무 내용</th>
                  <th>위탁개인정보 항목</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td colSpan="3">현재 없음</td>
                </tr>
                </tbody>
              </table>
              <li>
                <div>다양한 서비스를 제공하기 위해 다음과 같이 개인정보를 제 3자에게 제공하고 있습니다. 특정 서비스 이용에 필요한 목적을 위해 최소한의 개인정보를 제3자에게 제공하고 있습니다. 개인정보를 제 3자에게 제공해야 하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구합니다. 해당 서비스의 이용을 원치 않는 경우, 개인정보 제 3자 제공 동의를 거부할 수 있으며, 동의를 거부하더라도 다른 서비스 이용에 영향을 미치지 않습니다. 다만, 아래의 경우에는 예외로 하고 있습니다.</div>
                <ol className={styles.textOl}>
                  <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                  <li>
                    <div>이용자가 사전에 동의한 경우</div>
                    <div className={styles.ptb3}>[제3자 정보제공업체] -현재 없음-</div>
                    <div>※ 위 사항은 각각의 특정 서비스가 결합된 제휴 서비스만 해당합니다. 따라서, 해당 서비스 이용을 원치 않는 고객은 동의를 거부할 수 있으며, 본 동의를 거부하더라도 다른 서비스 이용에 영향을 미치지 않습니다.</div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>6. 이용자의 권리 보호</div>
            <div className={styles.pl15}>회사는 이용자의 권리를 다음과 같이 보호하고 있습니다.</div>
            <ol className={styles.circleOl}>
              <li>이용자 및 법정대리인은 개인정보의 조회, 수정, 삭제를 요청할 수 있으며, 회사는 정책에 따라 본인확인 절차를 거쳐 이를 조치하겠습니다.</li>
              <li>언제든지 개인정보 제공에 관한 동의 철회 및 삭제를 요청할 수 있습니다. 삭제를 요청하는 경우 아래로 연락 부탁드립니다.</li>
              <div className={styles.ptb3}>Email: <span className={styles.red}>sales@bigvalue.co.kr</span></div>
              <li>이용자가 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.</li>
              <li>회사는 이용자 혹은 법정 대리인의 요청으로 해지 또는 삭제된 개인정보는 "3. 개인정보의 보유 및 이용 기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>7. 개인정보의 기술적/관리적 보호 대책</div>
            <div className={styles.pl15}>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</div>
            <ol className={styles.circleOl}>
              <li>
                <div>내부 관리계획의 수립 및 시행</div>
                <div>개인정보 내부 관리계획 규정 등을 수립하고 시행하고 있습니다.</div>
              </li>
              <li>
                <div>개인정보의 암호화</div>
                <div>개인정보 처리시스템에서의 ID, 비밀번호 및 고유식별번호는 암호화되어 관리되고 있습니다.</div>
              </li>
              <li>
                <div>해킹 등에 대비한 기술적 대책</div>
                <div>해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 · 점검하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</div>
              </li>
              <li>
                <div>개인정보 처리시스템 접근 제한</div>
                <div>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입 차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</div>
              </li>
              <li>
                <div>개인정보 문서에 대한 안전성 확보 조치</div>
                <div>원칙적으로 개인정보 문서는 그 취급을 제한하고 있으며, 업무 수행상 불가피하게 취급하는 개인정보 문서는 분실, 도난, 유출, 변조, 훼손되지 아니하도록 안전성 확보 조치를 다 하고 있습니다.</div>
              </li>
              <li>
                <div>개인정보 취급 직원의 최소화 및 교육</div>
                <div>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있으며, 정기적으로 교육을 실시하고 있습니다.</div>
              </li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>8. 개인정보 관리책임자 및 담당 부서의 연락처</div>
            <div className={styles.pl15}>회사는 이용자의 개인정보가 훼손되거나 침해되지 않도록 최선을 다하고 있으며, 법령상 또는 계약상 의무를 위반하여 사고가 발생한 경우에는 개인정보보호 책임자로서 모든 책임을 집니다. 다만, 회사가 개인정보 보호를 위해 법률상 요구되는 기술적, 물리적, 관리적 조치를 다 하였음에도 불구하고, 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 책임을 지지 않습니다.</div>
            <ol className={styles.circleOl}>
              <li>개인정보관리(보호) 책임자</li>
              <table className={styles.table + " " + styles.mtb5}>
                <thead>
                <tr>
                  <th colSpan="3">개인정보관리(보호) 책임자</th>
                </tr>
                <tr>
                  <th>개인정보 책임자</th>
                  <th>직급</th>
                  <th>소속</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>김동현</td>
                  <td>본부장</td>
                  <td>개발본부</td>
                </tr>
                </tbody>
              </table>
              <li>
                <div>담당부서 연락처</div>
                <ol className={styles.textOl}>
                  <li>주소: 서울특별시 중구 서소문로 138 대한일보빌딩 12층</li>
                  <li>Tel: 02-779-9366, Fax: 02-779-9364</li>
                  <li>Email: <span className={styles.red}>sales@bigvalue.co.kr</span></li>
                </ol>
              </li>
              <li>
                <div>기타 개인정보 침해에 대한 신고 및 상담</div>
                <div>이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</div>
                <div>아래의 기관은 정부 기관 소속으로서, 회사의 자체적인 개인정보 불만 처리 또는 개인정보 피해구제 결과에 만족하지 못하실 경우, 자세한 도움이 필요하실 경우에는 문의하여 주시기 바랍니다.</div>
                <ul className={styles.bigUl}>
                  <li>
                    <div>개인정보 침해 신고센터</div>
                    <div>홈페이지 : https://privacy.kisa.or.kr</div>
                    <div>전화번호 : (국번 없이) 118</div>
                  </li>
                  <li>
                    <div>개인정보 분쟁조정위원회</div>
                    <div>홈페이지 : https://www.kopico.go.kr</div>
                    <div>전화번호 : 1833-6972</div>
                  </li>
                  <li>
                    <div>대검찰청 사이버수사과</div>
                    <div>홈페이지 : https://www.spo.go.kr</div>
                    <div>전화번호 : (국번 없이) 1301</div>
                  </li>
                  <li>
                    <div>경찰청 사이버범죄 신고시스템</div>
                    <div>홈페이지 : https://ecrm.police.go.kr</div>
                    <div>전화번호 : (국번 없이) 182</div>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>9. 링크 사이트에 대한 책임</div>
            <div className={styles.ptb5 + " " + styles.pl15}>회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 다만, 링크된 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "개인정보 취급 방침"이 적용되지 않습니다.</div>
          </div>
          <div>
            <div className={styles.fw700}>10. 쿠키의 운용 및 거부</div>
            <div className={styles.pt5 + " " + styles.pl15}>회사는 쿠키(Cookie)를 설치 · 운영하고 있고, 이용자는 이를 거부할 수 있습니다. 쿠키는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고, 맞춤형 서비스를 제공하기 위해 사용됩니다.</div>
            <ol className={styles.circleOl}>
              <li>
                <div>쿠키의 정의</div>
                <div>쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장된 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고, 맞춤화된 서비스를 제공하게 됩니다.</div>
              </li>
              <li>
                <div>쿠키 사용 목적</div>
                <div>쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위한 서비스 개선에 활용합니다. 쿠키를 통해 이용자는 보다 손쉽게 회사가 제공하는 서비스를 이용할 수 있게 됩니다. 또한 회사는 여러 서비스의 접속 빈도, 방문 시간, 각종 이벤트 참여도, 방문 횟수 등을 분석하여 이용자의 취향과 관심 분야를 파악합니다. 이를 바탕으로 광고를 포함한 개인 맞춤형 서비스를 제공합니다.</div>
              </li>
              <li>
                <div>쿠키 설치/운영 및 거부</div>
                <div>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.</div>
              </li>
              <li>
                <div>설정 방법의 예</div>
                <ol className={styles.textOl}>
                  <li>Internet Explorer의 경우 웹 브라우저 상단의 도구 메뉴 >인터넷 옵션 >개인정보 >설정</li>
                  <li>Chrome의 경우 웹 브라우저 우측의 설정 메뉴 >화면 하단의 고급 설정 표시 >개인정보의 콘텐츠 설정 버튼 >쿠키</li>
                  <li>브라우저의 버전 및 종류에 따라 설정 방법에 차이가 있을 수 있습니다. 보다 자세한 설명은 해당 브라우저의 도움말을 이용하시기 바랍니다.</li>
                </ol>
              </li>
            </ol>
          </div>
          <div>
            <div className={styles.fw700}>11. 고지의 의무</div>
            <div className={styles.ptb5 + " " + styles.pl15}>회사는 위 내용에 대한 추가, 삭제, 수정이 있을 경우 시행일로부터 최소 7일 전에 공지사항 등을 통해 이용자에게 설명 드립니다. 다만, 이용자의 소중한 권리 또는 의무와 관련된 중요한 내용의 변경은 최소 30일 전에 알려드립니다.</div>
            <div className={styles.ptb5}>개인정보 취급 방침 시행 일자: <span className={styles.red}>2023년 2월 8일</span></div>
          </div>
        </div>
      )
    }
    return (
      <div className={styles.form_textarea2}>
        <div>
          <div>
            <span className={styles.blue + " " + styles.fw700}>㈜빅밸류</span>
            <span>(이하 "회사"라 함)는 「신용정보의 이용 및 보호에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」, 「통신비밀보호법」 등 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 따른 개인정보 취급 방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.</span>
          </div>
          <div>본 개인정보 취급 방침은 회사가 제공하는 제반 서비스 이용에 적용되며 다음과 같은 내용을 담고 있습니다.</div>
        </div>
        <div>
          <ol>
            {indexList}
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>1. 개인정보의 수집</div>
          <ol className={styles.circleOl}>
            <li>
              <div>회사가 이용자의 개인정보를 수집하는 경우에는 아래의 원칙을 준수하고 있으며, 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구합니다.</div>
              <ol className={styles.textOl}>
                <li>
                  <div>서비스 제공에 필요한 최소한의 개인정보를 수집합니다.</div>
                  <ul className={styles.hyphenUl}>
                    <li>필수정보의 수집: 해당 서비스의 본질적 기능을 수행하기 위한 정보를 필수항목으로 수집합니다.</li>
                    <li>선택정보의 수집: 이용자에게 특화된 서비스를 제공하기 위해 최소한의 정보를 추가 수집하는 경우, 선택항목으로 수집합니다. 다만, 선택항목을 입력하지 않는 경우에도 다른 서비스 이용 제한은 없습니다.</li>
                  </ul>
                </li>
                <li>
                  <div>민감 정보를 수집하지 않습니다.</div>
                  <ul className={styles.hyphenUl}>
                    <li>회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록, 의료정보 등)를 수집하지 않습니다. 단, 법령에서 민감 정보의 처리를 요구하거나 허용하는 경우에는 반드시 이용자에게 해당 사실을 알리고 사전 동의를 구하도록 합니다.</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <div>회사가 수집하는 개인정보 항목은 아래와 같습니다.</div>
              <div className={styles.ptb10}>[개인정보 수집 항목]</div>
              <table className={styles.table + " " + styles.mtb5}>
                <thead>
                <tr>
                  <th colSpan="4">개인정보 수집 항목</th>
                </tr>
                <tr>
                  <th scope="col">구분</th>
                  <th scope="col">개인정보 수집 항목</th>
                  <th scope="col">수집 근거</th>
                  <th scope="col">수집, 이용목적</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>웹사이트 내 문의하기</td>
                  <td>이름, 이메일, 전화번호, 회사명</td>
                  <td>이용자의 동의</td>
                  <td>문의 사항 및 처리 결과 회신</td>
                </tr>
                </tbody>
              </table>
            </li>
            <li>
              <div>회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다</div>
              <ol className={styles.textOl}>
                <li>홈페이지, 서면 양식, 팩스, 전화, 고객센터, 이메일, 협력회사로부터의 제공, 생성 정보 수집 툴을 통한 수집</li>
                <li>홈페이지를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등</li>
              </ol>
            </li>
            <li>
              회사는 개인정보의 수집 · 이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다. 단, 법정대리인의 동의를 얻은 경우에는 만 14세 미만 이용자의 개인정보를 수집•이용할 수 있습니다.
            </li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>2. 개인정보의 이용목적</div>
          <div className={styles.pt5 + " " + styles.pl15}>회사는 이용자의 소중한 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 사전에 이용자의 동의를 구합니다.</div>
          <div className={styles.ptb5 + " " + styles.pl25}>웹사이트 : 이름, 이메일, 전화번호, 회사명</div>
          <div className={styles.pb5 + " " + styles.pl15}>회사는 서비스 제공을 위하여 수집한 모든 개인정보와 생성 정보를 문의 사항 및 처리결과 회신용으로 이용합니다.</div>
        </div>
        <div>
          <div className={styles.fw700}>3. 개인정보의 보유 및 이용 기간</div>
          <ol className={styles.circleOl}>
            <li>이용자의 개인정보는 개인정보의 수집 및 이용목적 달성을 위해 이용 기간에만 제한적으로 이용하고 있습니다. 다만, 관계 법령에 의해 보관해야 하는 정보는 법령이 정한 기간 보관합니다.</li>
            <li>회원에서 탈퇴한 후의 민원 처리를 위하여 내부지침에 따라 회원 탈퇴 후 1년간 CI/DI, 성명, 생년월일, 내/외국인 구분, 성별, 연락처 정보를 보관합니다.</li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>4. 개인정보의 파기</div>
          <ol className={styles.parenthesisOl}>
            <li>법령에 의하여 수집, 이용되는 이용자의 정보</li>
            <table className={styles.table + " " + styles.mtb5}>
              <thead>
              <tr>
                <th>법령</th>
                <th>보유·이용목적</th>
                <th>보유항목</th>
                <th>보유·이용 기간</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td rowSpan="2">통신비밀보호법</td>
                <td>법원의 영장을 받아 수사기관이 요청 시 제공</td>
                <td>로그기록, 접속지 정보 등</td>
                <td>3개월</td>
              </tr>
              <tr>
                <td>표시·광고에 관한 기록</td>
                <td>표시·광고 기록</td>
                <td>6개월</td>
              </tr>
              <tr>
                <td rowSpan="3">전자상거래 등에서의 소비자 보호에 관한 법률</td>
                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                <td>대금결제 및 재화 등의 공급 기록</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>계약 또는 청약 철회 등에 관한 기록</td>
                <td>소비자 식별정보 계약/청약 철회 기록</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>소비자 불만 또는 분쟁 처리에 관한 기록</td>
                <td>소비자 식별정보 분쟁 처리 기록</td>
                <td>3년</td>
              </tr>
              </tbody>
            </table>
            <li>회사 내부 정책에 의하여 수집, 이용되는 이용자의 정보</li>
            <table className={styles.table + " " + styles.mtb5}>
              <thead>
              <tr>
                <th>구분</th>
                <th>보유·이용목적</th>
                <th>보유항목</th>
                <th>보유·이용 기간</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>회사 내부 정책</td>
                <td>웹 문의 사항 답변</td>
                <td>이름, 이메일, 전화번호, 회사명</td>
                <td>웹사이트 내 문의 후 1년</td>
              </tr>
              </tbody>
            </table>
          </ol>
          <div className={styles.pl15}>이용자의 개인정보는 개인정보의 수집 · 이용목적이 달성되면 해당 정보를 지체 없이 파기합니다. 다만, 관계 법령에 의해 개인정보를 보관해야 하는 경우에는 기존 저장 공간과 구분된 별도의 저장공간에 옮겨서 정해진 기간 저장한 후 파기합니다. 이때 별도 저장되는 개인정보는 법령에 의한 경우 외에는 절대 다른 용도로 이용되지 않습니다.</div>
          <div className={styles.pb5 + " " + styles.pl15}>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적인 방법을 이용하여 완전하게 삭제하고, 종이로 출력된 개인정보는 분쇄하거나 소각하여 파기합니다.</div>
          <div className={styles.pl15}>개인정보 파기의 절차 및 방법은 다음과 같습니다.</div>
          <ol className={styles.circleOl}>
            <li>
              <div>파기 절차</div>
              <div>회사는 파기 사유가 발생한 시점부터 별도 지정한 보유·이용 기간이 지난 시점에 바로 파기합니다.</div>
            </li>
            <li>
              <div>파기 방법</div>
              <div>회사는 전자적 파일형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적인 방법 또는 물리적인 방법을 이용하여 파기하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.</div>
            </li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>5. 개인정보의 제공</div>
          <ol className={styles.circleOl}>
            <li>약속한 서비스를 제공하기 위해 다음과 같은 업무를 위탁하고 있습니다. 이용자에게 약속한 서비스를 제공하는 데에 반드시 필요한 업무 중 일부를 외부 업체가 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁받은 업체가 개인정보보호 관계 법령을 위반하지 않도록 관리 · 감독하고 있습니다.</li>
            <div className={styles.ptb10}>[개인정보 위탁업체]</div>
            <table className={styles.table + " " + styles.mtb5}>
              <thead>
              <tr>
                <th colSpan="3">개인정보 위탁업체</th>
              </tr>
              <tr>
                <th>위탁업체</th>
                <th>위탁업무 내용</th>
                <th>위탁개인정보 항목</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td colSpan="3">현재 없음</td>
              </tr>
              </tbody>
            </table>
            <li>
              <div>다양한 서비스를 제공하기 위해 다음과 같이 개인정보를 제 3자에게 제공하고 있습니다. 특정 서비스 이용에 필요한 목적을 위해 최소한의 개인정보를 제3자에게 제공하고 있습니다. 개인정보를 제 3자에게 제공해야 하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구합니다. 해당 서비스의 이용을 원치 않는 경우, 개인정보 제 3자 제공 동의를 거부할 수 있으며, 동의를 거부하더라도 다른 서비스 이용에 영향을 미치지 않습니다. 다만, 아래의 경우에는 예외로 하고 있습니다.</div>
              <ol className={styles.textOl}>
                <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                <li>
                  <div>이용자가 사전에 동의한 경우</div>
                  <div className={styles.ptb3}>[제3자 정보제공업체] -현재 없음-</div>
                  <div>※ 위 사항은 각각의 특정 서비스가 결합된 제휴 서비스만 해당합니다. 따라서, 해당 서비스 이용을 원치 않는 고객은 동의를 거부할 수 있으며, 본 동의를 거부하더라도 다른 서비스 이용에 영향을 미치지 않습니다.</div>
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>6. 이용자의 권리 보호</div>
          <div className={styles.pl15}>회사는 이용자의 권리를 다음과 같이 보호하고 있습니다.</div>
          <ol className={styles.circleOl}>
            <li>이용자 및 법정대리인은 개인정보의 조회, 수정, 삭제를 요청할 수 있으며, 회사는 정책에 따라 본인확인 절차를 거쳐 이를 조치하겠습니다.</li>
            <li>언제든지 개인정보 제공에 관한 동의 철회 및 삭제를 요청할 수 있습니다. 삭제를 요청하는 경우 아래로 연락 부탁드립니다.</li>
            <div className={styles.ptb3}>Email: <span className={styles.red}>sales@bigvalue.co.kr</span></div>
            <li>이용자가 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.</li>
            <li>회사는 이용자 혹은 법정 대리인의 요청으로 해지 또는 삭제된 개인정보는 "3. 개인정보의 보유 및 이용 기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>7. 개인정보의 기술적/관리적 보호 대책</div>
          <div className={styles.pl15}>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</div>
          <ol className={styles.circleOl}>
            <li>
              <div>내부 관리계획의 수립 및 시행</div>
              <div>개인정보 내부 관리계획 규정 등을 수립하고 시행하고 있습니다.</div>
            </li>
            <li>
              <div>개인정보의 암호화</div>
              <div>개인정보 처리시스템에서의 ID, 비밀번호 및 고유식별번호는 암호화되어 관리되고 있습니다.</div>
            </li>
            <li>
              <div>해킹 등에 대비한 기술적 대책</div>
              <div>해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 · 점검하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</div>
            </li>
            <li>
              <div>개인정보 처리시스템 접근 제한</div>
              <div>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입 차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</div>
            </li>
            <li>
              <div>개인정보 문서에 대한 안전성 확보 조치</div>
              <div>원칙적으로 개인정보 문서는 그 취급을 제한하고 있으며, 업무 수행상 불가피하게 취급하는 개인정보 문서는 분실, 도난, 유출, 변조, 훼손되지 아니하도록 안전성 확보 조치를 다 하고 있습니다.</div>
            </li>
            <li>
              <div>개인정보 취급 직원의 최소화 및 교육</div>
              <div>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있으며, 정기적으로 교육을 실시하고 있습니다.</div>
            </li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>8. 개인정보 관리책임자 및 담당 부서의 연락처</div>
          <div className={styles.pl15}>회사는 이용자의 개인정보가 훼손되거나 침해되지 않도록 최선을 다하고 있으며, 법령상 또는 계약상 의무를 위반하여 사고가 발생한 경우에는 개인정보보호 책임자로서 모든 책임을 집니다. 다만, 회사가 개인정보 보호를 위해 법률상 요구되는 기술적, 물리적, 관리적 조치를 다 하였음에도 불구하고, 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 책임을 지지 않습니다.</div>
          <ol className={styles.circleOl}>
            <li>개인정보관리(보호) 책임자</li>
            <table className={styles.table + " " + styles.mtb5}>
              <thead>
              <tr>
                <th colSpan="3">개인정보관리(보호) 책임자</th>
              </tr>
              <tr>
                <th>개인정보 책임자</th>
                <th>직급</th>
                <th>소속</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>김동현</td>
                <td>본부장</td>
                <td>개발본부</td>
              </tr>
              </tbody>
            </table>
            <li>
              <div>담당부서 연락처</div>
              <ol className={styles.textOl}>
                <li>주소: 서울특별시 중구 서소문로 138 대한일보빌딩 12층</li>
                <li>Tel: 02-779-9366, Fax: 02-779-9364</li>
                <li>Email: <span className={styles.red}>sales@bigvalue.co.kr</span></li>
              </ol>
            </li>
            <li>
              <div>기타 개인정보 침해에 대한 신고 및 상담</div>
              <div>이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</div>
              <div>아래의 기관은 정부 기관 소속으로서, 회사의 자체적인 개인정보 불만 처리 또는 개인정보 피해구제 결과에 만족하지 못하실 경우, 자세한 도움이 필요하실 경우에는 문의하여 주시기 바랍니다.</div>
              <ul className={styles.bigUl}>
                <li>
                  <div>개인정보 침해 신고센터</div>
                  <div>홈페이지 : https://privacy.kisa.or.kr</div>
                  <div>전화번호 : (국번 없이) 118</div>
                </li>
                <li>
                  <div>개인정보 분쟁조정위원회</div>
                  <div>홈페이지 : https://www.kopico.go.kr</div>
                  <div>전화번호 : 1833-6972</div>
                </li>
                <li>
                  <div>대검찰청 사이버수사과</div>
                  <div>홈페이지 : https://www.spo.go.kr</div>
                  <div>전화번호 : (국번 없이) 1301</div>
                </li>
                <li>
                  <div>경찰청 사이버범죄 신고시스템</div>
                  <div>홈페이지 : https://ecrm.police.go.kr</div>
                  <div>전화번호 : (국번 없이) 182</div>
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>9. 링크 사이트에 대한 책임</div>
          <div className={styles.ptb5 + " " + styles.pl15}>회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 다만, 링크된 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "개인정보 취급 방침"이 적용되지 않습니다.</div>
        </div>
        <div>
          <div className={styles.fw700}>10. 쿠키의 운용 및 거부</div>
          <div className={styles.pt5 + " " + styles.pl15}>회사는 쿠키(Cookie)를 설치 · 운영하고 있고, 이용자는 이를 거부할 수 있습니다. 쿠키는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고, 맞춤형 서비스를 제공하기 위해 사용됩니다.</div>
          <ol className={styles.circleOl}>
            <li>
              <div>쿠키의 정의</div>
              <div>쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장된 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고, 맞춤화된 서비스를 제공하게 됩니다.</div>
            </li>
            <li>
              <div>쿠키 사용 목적</div>
              <div>쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위한 서비스 개선에 활용합니다. 쿠키를 통해 이용자는 보다 손쉽게 회사가 제공하는 서비스를 이용할 수 있게 됩니다. 또한 회사는 여러 서비스의 접속 빈도, 방문 시간, 각종 이벤트 참여도, 방문 횟수 등을 분석하여 이용자의 취향과 관심 분야를 파악합니다. 이를 바탕으로 광고를 포함한 개인 맞춤형 서비스를 제공합니다.</div>
            </li>
            <li>
              <div>쿠키 설치/운영 및 거부</div>
              <div>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.</div>
            </li>
            <li>
              <div>설정 방법의 예</div>
              <ol className={styles.textOl}>
                <li>Internet Explorer의 경우 웹 브라우저 상단의 도구 메뉴 >인터넷 옵션 >개인정보 >설정</li>
                <li>Chrome의 경우 웹 브라우저 우측의 설정 메뉴 >화면 하단의 고급 설정 표시 >개인정보의 콘텐츠 설정 버튼 >쿠키</li>
                <li>브라우저의 버전 및 종류에 따라 설정 방법에 차이가 있을 수 있습니다. 보다 자세한 설명은 해당 브라우저의 도움말을 이용하시기 바랍니다.</li>
              </ol>
            </li>
          </ol>
        </div>
        <div>
          <div className={styles.fw700}>11. 고지의 의무</div>
          <div className={styles.ptb5 + " " + styles.pl15}>회사는 위 내용에 대한 추가, 삭제, 수정이 있을 경우 시행일로부터 최소 7일 전에 공지사항 등을 통해 이용자에게 설명 드립니다. 다만, 이용자의 소중한 권리 또는 의무와 관련된 중요한 내용의 변경은 최소 30일 전에 알려드립니다.</div>
          <div className={styles.ptb5}>개인정보 취급 방침 시행 일자: <span className={styles.red}>2023년 2월 8일</span></div>
        </div>
      </div>
    );
  }

  windowResize = () => {
    const res = deviceDetect();

    this.setState({
      isMobile: res
    })
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.windowResize);
  }
}

export default PrivacyStatement;