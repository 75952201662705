import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import ArticleList from "../../Resources/Article/ArticleList";
import ArticlePreviewHeader from "./ArticlePreviewHeader";
import MobileArticleHeader from "./MobileArticleHeader";
import '../../../static/css/components/ArticlePreview.css';
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

function ArticlePreview() {

    const numbers = [3,6,4];
    const limit = 2;
    const [select, setSelect] = useState(3);
    const [isMobile, setMobile] = useState(deviceDetect());
    const result = numbers.map((num) => <ArticleList key={num} categoryId={num} page={0} limit={limit}><ArticlePreviewHeader key={num} value={num} /></ArticleList>);

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    function selectCategory(change) {
        if (change !== select) {
            setSelect(change);
        }
    }

    if (isMobile) {
        return (
          <div className="articlePreview_mobile">
              <div className="previewHeader">BIGVALUE News</div>
              <MobileArticleHeader select={select} selectCategory={selectCategory}/>
              <ArticleList categoryId={select} page={0} limit={limit + 2} />
              <div className="previewBtn">
                  <Link to='/resources'>
                      <div className="articleBtn"></div>
                  </Link>
              </div>
          </div>
        );
    }

    return (
        <div className="articlePreview">
            <div className="previewHeader">BIGVALUE News</div>
            <div className="previewList">
                {result}
            </div>
            <div className="previewBtn">
                <Link to='/resources'>
                    <div className="articleBtn"></div>
                </Link>
            </div>
        </div>
    );
}

export default ArticlePreview;