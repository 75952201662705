
import ic1 from "./assets/ic_ailobig1.png";
import ic2 from "./assets/ic_ailobig2.png";
import ic3 from "./assets/ic_ailobig3.png";
import ic4 from "./assets/ic_ailobig4.png";
import ic5 from "./assets/ic_ailobig5.png";
import createServiceData from "../../ServiceData";


const AilobigServiceData = createServiceData({

    type: 'ailobig',
    title: 'AI LOBIG',
    afterWord: '이',

    itemsProps: [{
        category: '거주 인구',

        iconImage: ic1,

        dataList: [
            '연령',
            '성별',
            '자산/소득',
            '거주환경',
            '소비성향'
        ]
    }, {
        category: '직장 인구',

        iconImage: ic2,

        dataList: [
            '기업 규모',
            '업종/업태',
            '소득수준',
            '인근환경',
            '교통환경'
        ]
    }, {
        category: '접근성',

        iconImage: ic3,

        dataList: [
            '도로',
            '경사도',
            '대중교통',
            '거리'
        ]
    }, {
        category: '경쟁자',

        iconImage: ic4,

        dataList: [
            '매장규모',
            '개폐업',
            '위치',
            '경쟁강도'
        ]
    }, {
        category: '민간정보',

        iconImage: ic5,

        dataList: [
            '하나카드',
            'LPoint',
            '신한은행'
        ]
    }]
});

export default AilobigServiceData;