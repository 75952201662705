import {Component} from 'react';
import icArrow_mobile from '../../../../static/assets/components/aboutGeoDataAI/arrow_mobile.svg';
import icAILearning from '../../../../static/assets/components/aboutGeoDataAI/AI학습_icon.svg';
import icInfer from '../../../../static/assets/components/aboutGeoDataAI/추론_icon.svg';
import logoBigvalue_mobile from '../../../../static/assets/components/aboutGeoDataAI/logo_빅밸류_mobile.svg';
import contentsvg from '../../../../static/assets/components/aboutGeoDataAI/content.svg';
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";

export default class GeoDataAIShape extends Component {

  state = {
    isMobile: deviceDetect()
  }

  render() {
    if (this.state.isMobile) {
      return (
        <div className="geoDataAIShape_mobile">
          <div className="customerDataCircle_mobile">
            <div>고객 내부</div>
            <div>데이터</div>
          </div>
          <div className="aboutArrow_mobile mt3"><img src={icArrow_mobile} alt="arrow" onError={(e) => e.target.src = `${noImg}`}/></div>
          <div className="bigvalueAnalysisCircles_mobile">
            <div className="circle mrm10">
              <img className="circleIc" src={icAILearning} alt="AILearning" onError={(e) => e.target.src = `${noImg}`}/>
              <div className="circleTitle">AI학습</div>
              <div className="circleBody">
                <div>추론용 최신</div>
                <div>공간데이터</div>
              </div>
            </div>
            <div className="circleMiddle"><img src={logoBigvalue_mobile} alt="logo" onError={(e) => e.target.src = `${noImg}`}/></div>
            <div className="circle mlm10">
              <img className="circleIc" src={icInfer} alt="Infer" onError={(e) => e.target.src = `${noImg}`}/>
              <div className="circleTitle">추론</div>
              <div className="circleBody">
                <div>학습용 과거</div>
                <div>공간데이터</div>
              </div>
            </div>
          </div>
          <div className="aboutArrow_mobile mb3">
            <img src={icArrow_mobile} alt="arrow" onError={(e) => e.target.src = `${noImg}`}/>
          </div>
          <div className="digitalTransformCircle_mobile">
            <div className="digitalHeader">Digital</div>
            <div className="digitalHeader">Transformation</div>
            <div className="digitalBody">서비스 혁신 / 업무 혁신</div>
          </div>
        </div>
      );
    }

    return (
      <div className="geoDataAIShape">
        <img src={contentsvg} alt="geoDataAIShape" className="geoDataAIShapeSVG"/>
      </div>
    );
  }

  windowResize = () => {
    const res = deviceDetect();

    this.setState({
      isMobile: res
    })
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.windowResize);
  }
}
