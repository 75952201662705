import React from "react";
import {isMobile} from "react-device-detect";
import VadvisorContents from "../../components/Solution/VAdvisor/VadvisorContents";
import VadvisorServiceData from "../../components/Solution/VAdvisor/VadvisorServiceData";
import VadvisorProcess from "../../components/Solution/VAdvisor/VadvisorProcess";
import VadvisorComments from "../../components/Solution/VAdvisor/VadvisorComments";
import Content from "../../components/Solution/content";
import Comments from "../../components/Solution/comments";
import vAdvisorImg from "../../static/assets/components/solutionContent/vAdvisor/vAdvisorImg.png";
import vAdvisorProcess from "../../static/assets/components/solutionContent/vAdvisor/vAdvisorProcess_mobile.png";
import avatar1 from "../../components/Solution/VAdvisor/VadvisorComments/assets/avatar.png";
import avatar2 from "../../components/Solution/VAdvisor/VadvisorComments/assets/avatar2.png";
import styles from "../../components/Solution/VAdvisor/VadvisorComments/index.module.css";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";

class Vadvisor extends React.Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            const contentData = {
                id : 3,
                header : 'V-Advisor',
                title : '토지·건축물 매입 시가 산정 솔루션 V-Advisor',
                body : <div>▲토지 기본 정보(지목·토지면적·공시 지가·토지이용현황 등) ▲건축물 기본 정보(표제부·인허가상 건물 정보·대지면적·건축면적·연면적 등)를 한 눈에 확인은 물론 AI 토지·건축물 시가 추정까지 한 번에 가능한 AI 시가 산정 솔루션입니다. <div className="subBody">1일 단위 업데이트로 최신 정보 기반 부동산 평가서를 단 5분만에 받아 볼 수 있습니다.</div></div>,
                laptop : vAdvisorImg
            };

            const commentData = {
                title : 'V-Advisor',
                afterWord : '를',
                img : vAdvisorProcess,
                commentList : [
                    {
                        comment : <div className="commentCardBody">평가 업무에 큰 변화를 가져왔습니다. <span className="text_highlight">시간 단축과 데이터 정확도가 모두 향상</span>됐어요. 지점 행원들과도 인터페이스를 보면서 평가 결과를 <br/>전달하다 보니 이해도 빨라졌습니다. 메신저 연동기능과 AI 학습 기능의 업그레이드가 매우 기대됩니다.</div>,
                        avatar : avatar1,
                        by : '시중은행 여신심사팀 팀장'
                    },
                    {
                        comment : <div className="commentCardBody">지도 인터페이스에서 <span className="text_highlight">클릭만으로 최종 보고서와 엑셀까지 모든 수치가 연동</span>되어 제공되는 기능이 많은 오류를 줄이고 시간을 절약해 주었습니다.</div>,
                        avatar : avatar2,
                        by : '대형 평가사 이사'
                    }
                ]
            };

            return (
                <div>
                    <Content data={contentData} />
                    <VadvisorServiceData/>
                    <VadvisorProcess/>
                    <Comments data={commentData} />
                </div>
            );
        }

        return(
            <>
                <VadvisorContents/>
                <VadvisorServiceData/>
                <VadvisorProcess/>
                <VadvisorComments/>
            </>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Vadvisor;