import React, {Component} from 'react';
import icAccur from '../../../static/assets/components/strength/icAccuracy.svg';
import icExtData from '../../../static/assets/components/strength/icExtensiveData.svg';
import icMR from '../../../static/assets/components/strength/icMachineReadable.svg';
import icTime from '../../../static/assets/components/strength/icTimeSeries.svg';
import icCloud from '../../../static/assets/components/strength/icCloudInfra.svg';
import icDataLab from '../../../static/assets/components/strength/icDataLab.svg';
import '../../../static/css/components/Strength.css';
import noImg from "../../../static/assets/utils/noimg.png";

class Strength extends Component {
    constructor(props) {
        super(props);

        this.state = {
            num : this.props.value,
            class : 'strength-' + this.props.value,
            icon : '',
            header : '',
            body : '',
            sub : '',
        }
    }

    render() {
        return (
            <div className={this.state.class}>
                <div className="imgBackground">
                    {this.state.icon}
                </div>
                <div className="strength-header">{this.state.header}</div>
                <div className="strength-body">{this.state.body}<br/>{this.state.sub}</div>
            </div>
        );
    }

    getStrength = () => {
        const strList = [
            {
                header: '데이터 정확도',
                body: '오차율 10% 이내',
                sub : ''
            },
            {
                header: '광범위한 데이터',
                body: '전국/상세 정보',
                sub : ''
            },
            {
                header: '정제 연결 기술',
                body: 'Machine Readable',
                sub : ''
            },
            {
                header: '시계열 정보',
                body: '다차원적 정보 해석 및',
                sub : '목적 부합 추세 탐지'
            },
            {
                header: '공간분석 기술력',
                body: 'Advanced AI/ML',
                sub : 'Cloud Infrastructure'
            },
            {
                header: '데이터 연구소',
                body: 'Data Scientist 15명',
                sub : ''
            }
        ];

        let icon = '';

        if (this.props.value === 1) {
            icon = <img src={icAccur} alt={strList[0].header} onError={(e) => e.target.src = `${noImg}`}/>
        } else if (this.props.value === 2) {
            icon = <img src={icExtData} alt={strList[1].header} onError={(e) => e.target.src = `${noImg}`}/>
        } else if (this.props.value === 3) {
            icon = <img src={icMR} alt={strList[2].header} onError={(e) => e.target.src = `${noImg}`}/>
        } else if (this.props.value === 4) {
            icon = <img src={icTime} alt={strList[3].header} onError={(e) => e.target.src = `${noImg}`}/>
        } else if (this.props.value === 5) {
            icon = <img src={icCloud} alt={strList[4].header} onError={(e) => e.target.src = `${noImg}`}/>
        } else {
            icon = <img src={icDataLab} alt={strList[5].header} onError={(e) => e.target.src = `${noImg}`}/>
        }
        this.setState({
            num : this.props.value,
            class : 'strength-' + this.props.value,
            icon : icon,
            header : strList[this.props.value-1].header,
            body : strList[this.props.value-1].body,
            sub : strList[this.props.value-1].sub,
        });
    }

    componentDidMount() {
        this.getStrength();
    }
}

export default Strength;