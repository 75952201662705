import React, {useState, useContext, createContext, useMemo} from 'react';
import axios from "axios";

// START context API setting
// ref: https://velog.io/@velopert/react-context-tutorial
const AdminInfoValueContext = createContext();
const AdminInfoActionsContext = createContext();

function AdminInfoProvider({ children }) {

    const [userInfo, setUserInfo] = useState({
        id : ''
    })

    const action = useMemo(() => ({
        logIn(loggedUserInfo) {
            setUserInfo(loggedUserInfo)
        },
        logOut() {
            setUserInfo({id: ''})
            localStorage.removeItem("login")
            axios.get(process.env.REACT_APP_API_URL + '/api/logout');
        }
    }), []);

    return (
        <AdminInfoActionsContext.Provider value={action}>
            <AdminInfoValueContext.Provider value={userInfo}>
                {children}
            </AdminInfoValueContext.Provider>
        </AdminInfoActionsContext.Provider>
    );
}

function useAdminInfoValueState() {
    const value = useContext(AdminInfoValueContext);
    if (value === undefined) {
        throw new Error("useGlobalStoreState should be used within GlobalStoreProvider");
    }
    return value;
}

function useAdminInfoActionState() {
    const value = useContext(AdminInfoActionsContext);
    if (value === undefined) {
        throw new Error("useGlobalStoreState should be used within GlobalStoreProvider");
    }
    return value;
}

export { AdminInfoProvider, useAdminInfoValueState, useAdminInfoActionState };
