import React, { useState, useContext } from 'react';

// START context API setting
// ref: https://velog.io/@velopert/react-context-tutorial
const GlobalStoreContext = React.createContext();

function GlobalStoreProvider({ children }) {
  const globalVar = {
    selectedBoardElementId: '',
    refreshCounter: 0,
  }
  const globalStoreState = useState(globalVar);
  return (
    <GlobalStoreContext.Provider value={globalStoreState}>
      {children}
    </GlobalStoreContext.Provider>
  );
}

function useGlobalStoreState() {
  const value = useContext(GlobalStoreContext);
  if (value === undefined) {
    throw new Error("useGlobalStoreState should be used within GlobalStoreProvider");
  }
  return value;
}









const RefreshCounterContext = React.createContext();

function RefreshCounterProvider({ children }) {
  const refreshCounterState = useState(0);
  return (
    <RefreshCounterContext.Provider value={refreshCounterState}>
      {children}
    </RefreshCounterContext.Provider>
  );
}

function useRefreshCounterState() {
  const value = useContext(RefreshCounterContext);
  if (value === undefined) {
    throw new Error("useRefreshCounterState should be used within RefreshCounterProvider");
  }
  return value;
}
// END context API setting

export { GlobalStoreProvider, 
  useGlobalStoreState,
  RefreshCounterProvider,
  useRefreshCounterState,
 };