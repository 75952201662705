//import React from "react";

import styles from './index.module.css';
import {isMobile} from "react-device-detect";
import {useEffect, useState} from "react";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

function Ailobig() {

    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    if (isMobile) {
        return (
          <div className={styles.background_mb}>
              <div className={styles.top_mb}>
                  <div className={styles.title_mb}>Process</div>
                  <div className={styles.head_mb}>서비스 절차</div>
                  <div className={styles.description_mb}>공간 빅데이터 기반으로 매출 데이터를 학습·분석해 비즈니스에 필요한 인사이트를 제공합니다. </div>
              </div>
              <div className={styles.items_mb}>
                  <div className={styles.item_no_mb + " " + styles.grid_no1}>01</div>
                  <div className={styles.item_text_mb + " " + styles.text_align_left + " " + styles.grid_text1}>POS 데이터수집</div>
                  <div className={styles.item_no_mb + " " + styles.grid_no2}>02</div>
                  <div className={styles.item_text_mb + " " + styles.text_align_right + " " + styles.grid_text2}>주소 정제 · 정보 결합</div>
                  <div className={styles.item_no_mb + " " + styles.grid_no3}>03</div>
                  <div className={styles.item_text_mb + " " + styles.text_align_left + " " + styles.grid_text3}>업종 선택</div>
                  <div className={styles.item_no_mb + " " + styles.grid_no4}>04</div>
                  <div className={styles.item_text_mb + " " + styles.text_align_right + " " + styles.grid_text4}>AI 학습</div>
                  <div className={styles.item_no_mb + " " + styles.grid_no5}>05</div>
                  <div className={styles.item_text_mb + " " + styles.text_align_left + " " + styles.grid_text5}>매출 예측</div>
                  <div className={styles.middleLine}></div>
              </div>
          </div>
        );
    }

    return (<div className={styles.bkgnd} >

        <div className={styles.content}>

            <div className={styles.top}>
                <div className={styles.title}>Process</div>
                <div className={styles.head}>서비스 절차</div>
                <div className={styles.description}>공간 빅데이터 기반으로 매출 데이터를 학습·분석해 비즈니스에 필요한 인사이트를 제공합니다. </div>
            </div>

            <div className={styles.items}>
                <div className={styles.item}>
                    <div className={styles.item_no}>01</div>
                    <div className={styles.item_text}>POS<br/>데이터수집</div>
                </div>

                <div className={styles.item}>
                    <div className={styles.item_no}>02</div>
                    <div className={styles.item_text}>주소 정제<br/>정보 결합</div>
                </div>

                <div className={styles.item}>
                    <div className={styles.item_no}>03</div>
                    <div className={styles.item_text}>업종 선택</div>
                </div>

                <div className={styles.item}>
                    <div className={styles.item_no}>04</div>
                    <div className={styles.item_text}>AI 학습</div>
                </div>

                <div className={styles.item}>
                    <div className={styles.item_no}>05</div>
                    <div className={styles.item_text}>매출 예측</div>
                </div>
            </div>
        </div>

    </div>);
}

export default Ailobig;