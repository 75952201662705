import {BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";
import ScrollToTop from "./static/utils/ScrollToTop/ScrollToTop";
import Main from "./templates/Main/main";
import Resource from "./templates/Resources/resource";
import ResourceDetail from "./templates/ResourceDetail/resourceDetail";
import About from "./templates/About/about";
import Career from "./templates/Career/career.jsx";
import AiDeveloper from "./templates/AiDeveloper/aiDeveloper";
import Villasise from "./templates/Villasise/villasise";
import Vadvisor from "./templates/Vadvisor/vadvisor";
import AiLobig from "./templates/AiLobig/aiLobig";
import Contact from "./components/contact";
import DataMap from "./templates/DataMap/dataMap";
import LeadershipPage from "./templates/Leadership/LeadershipPage";
import CompanyLocation from "./templates/CompanyLocation/companyLocationPage";
import ContactUsPage from "./templates/ContactUs/ContactUsPage";
import Footer from "./components/Footer/footer";
import Sliders from "./components/About/Careers/Carousel/sliders";
import PrivacyStatement from "./components/About/ContactUs/privacyStatement";
import RouteChangeTracker from "./static/utils/RouteChangeTracker/RouteChangeTracker";
import PageNotFound from "./templates/PageNotFound/pageNotFound";
import './App.css';

function App() {
    return (
        <>
            <BrowserRouter>
                <Header />
                <ScrollToTop />
                <RouteChangeTracker />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/dataMap" element={<DataMap />} />
                    <Route path="/resources" element={<Resource />} />
                    <Route path="/resources/:category" element={<Resource />} />
                    <Route path="/resources/:category/:articleId" element={<ResourceDetail />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/about/*"  >
                        <Route path="careers" element={<Career />}/>
                        <Route path="vision" element={<LeadershipPage />}/>
                        <Route path="contactUs" element={<ContactUsPage />}/>
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                    <Route path="/companyLocation" element={<CompanyLocation />} />
                    <Route path="/solution" index element={ <Navigate to="/solution/al" />} />
                    <Route path="/solution/*">
                        <Route path="al" index element={<AiLobig />} />
                        <Route path="ad" index element={<AiDeveloper />} />
                        <Route path="vi" index element={<Villasise />} />
                        <Route path="va" index element={<Vadvisor />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                    <Route path="carousel" element={<Sliders />} />
                    <Route path="privacy" element={<PrivacyStatement />} />
                    <Route path="/*" element={<PageNotFound />} />
                </Routes>
                <Contact/>
                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default App;