import React, {Component} from 'react';
import axios from "axios";
import AnnounceBoard from "./announceBoard";
import  '../../../static/css/components/announcement.css';
import AnnouncePage from "./announcePage";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class Announcement extends Component {
    state = {
        page : 0,
        limit : 5,
        boardList : [],
        data : [],
        isMobile : deviceDetect()
    }

    render() {
        const board = this.state.boardList.map((board) => <AnnounceBoard data={board} key={board.rowNum} />)

        if (this.state.isMobile) {
            return (
                <div className="announcement_mobile">
                    <div className="announcementTitle_mobile">공지사항</div>
                    <div className="announceBoardSummary boardHeader">
                        <div className="boardTitle">제목</div>
                        <div className="boardDate">등록일</div>
                    </div>
                    {board}
                    <AnnouncePage data={this.state.data} changePage={this.changePageNum} />
                </div>
            );
        }

        return (
            <div className="announcement">
                <div className="announcementTitle">공지사항</div>
                <div className="announceBoardSummary boardHeader">
                    <div className="boardIndex">번호</div>
                    <div className="boardTitle">제목</div>
                    <div className="boardWriter">작성자</div>
                    <div className="boardDate">등록일</div>
                </div>
                {board}
                <AnnouncePage data={this.state.data} changePage={this.changePageNum} />
            </div>
        );
    }

    getBoardList = () => {
        const PARAMS = {
            boardId: 1,
            categoryId: 1,
            page : this.state.page,
            size : this.state.limit,
            sort : "viewDate,DESC"
        }

        axios.get(process.env.REACT_APP_API_URL + '/api/postList', {
            params: PARAMS
        })
            .then((Response) => {

                this.setState({
                    boardList : Response.data.data,
                    data : Response.data
                })
            })
            .catch((Error)=>{console.log(Error)});
    }

    changePageNum = (data) => {
        this.setState({ page : data - 1 })
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getBoardList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            this.getBoardList();
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Announcement;