import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryName : '',
            isMobile : deviceDetect()
        }
    };

    render() {
        const { article } = this.props;

        if (this.state.isMobile) {
            return (
                <Link to={'/resources/' + this.state.categoryName + '/' + article.postId} >
                    <div className="articleCard_mobile">
                        <div className="articleCardHeader">{article.articlePressName}</div>
                        <div className="articleCardTitle">{article.title}</div>
                        <div className={'articleCardBody' + (article.imgExist ? '':'-imgOff')} dangerouslySetInnerHTML={{ __html: article.lead }}></div>
                        <div className="articleCardDate">{article.date}</div>
                        <div className={"articleCardImg" + (article.imgExist ? '':'-imgOff')}>
                            <img src={process.env.REACT_APP_PREVIEW_URL + article.previewFileName} alt={article.title}
                                 onError={(e) => e.target.src = `${noImg}`}
                            />
                        </div>
                    </div>
                </Link>
            );
        }
        return (
            <Link to={'/resources/' + this.state.categoryName + '/' + article.postId} >
                <div className="articleCard">
                    <div className="articleCardHeader">{article.articlePressName}</div>
                    <div className="articleCardTitle">{article.title}</div>
                    <div className={'articleCardBody' + (article.imgExist ? '':'-imgOff')} dangerouslySetInnerHTML={{ __html: article.lead }}></div>
                    <div className="articleCardDate">{article.date}</div>
                    <div className={"articleCardImg" + (article.imgExist ? '':'-imgOff')}>
                        <img src={process.env.REACT_APP_PREVIEW_URL + article.previewFileName} alt={article.title}
                             onError={(e) => e.target.src = `${noImg}`}
                        />
                    </div>
                </div>
            </Link>
        );
    }

    findCategory = () => {
        const category = ['','sw','pr','media','cs','bv'];
        return category[this.props.article.categoryId - 1];
    };

    getArticle = () => {
        let categoryName = this.findCategory(this.props.article.categoryId);

        this.setState({
            categoryName : categoryName
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getArticle();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Article;