import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import styles from './index.module.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";


function Contact(props) {
    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    const link = (url) => {

        window.location = url;
    }

    let pathname = useLocation().pathname;

    if (pathname.includes('/ContactUs') || pathname.includes('/contactUs'))
    {
        return <div></div>;
    }

    if (isMobile) {
        return (
          <div className={styles.bkgnd_mb}>
              <div className={styles.content_mb}>
                  <div className={styles.title_mb}>Contact us</div>
                  <div className={styles.description_mb}>궁금한 사항이 있으신가요? 문의사항을 남겨주시면 담당자가 답변드리겠습니다.</div>
                  <button className={styles.button_mb} onClick={()=>link('/about/contactUs')}>문의하기</button>
              </div>
          </div>
        );
    }

    return(<div className={styles.bkgnd}>

        <div className={styles.content}>

            <div className={styles.title}>Contact us</div>

            <div className={styles.description}>궁금한 사항이 있으신가요? 문의 사항을 남겨주시면 담당자가 답변드리겠습니다.</div>

            <button className={styles.button} onClick={()=>link('/about/contactUs')}>문의하기</button>

        </div>

    </div>);
}

export default Contact;

