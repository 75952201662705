import React, {useState, useEffect, useContext} from 'react';

import styled from 'styled-components';
import PostsTableData from './PostsTableData';
import Pager from './Pager';
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../../components/About/ContactUs/index.module.css";



const ButtonWrite = styled.button`
border: 1px solid #448fdd;
color: #448fdd;
background-color: #ffffff;
font-size: 14px !important;
height: 40px;
cursor: pointer;
padding: 10px 20px;
display: inline-block;
box-sizing: border-box;
width: 200px;

&:hover {
    text-decoration: underline;
}
`

const HeaderFlex = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const H3 = styled.h3`
font-size: 20px;
`

const InputFlex = styled.div`
display: flex;
justify-content: end;
`;

const SelectInput = styled.select`
  width: 80px;
  height: 28px;
  font-size: 15px;
  text-align: start;
  padding-left: 5px;
  border: rgba(33, 33, 33, 0.8) 1px solid;
  border-radius: 3px;
  border-collapse: collapse;
`;

const Input = styled.input`
  height: 28px;
  font-size: 15px;
  text-align: start;
  border: rgba(33, 33, 33, 0.8) 1px solid;
  border-radius: 3px;
  border-collapse: collapse;
`;

const Button = styled.button`
  width: 60px;
  font-size: 15px;
  background-color: rgba(20, 111, 255, 0.8);
  border: rgba(33, 33, 33, 0.8) 1px solid;
  color: white;
`;

const Table = styled.table`
border-collapse: collapse;
border-spacing: 0;
width: 100%;
border: 1px solid #d2d2d2;
font-size: 16px;
white-space: nowrap;
table-layout: fixed;
`
const Thead = styled.thead`
border-top: 1px solid #d2d2d2 !important;
border-bottom: 1px solid #d2d2d2 !important;
`
const Th = styled.th`
padding: 8px 10px !important;
border-left: 1px solid #d2d2d2 !important;
border-right: 1px solid #d2d2d2 !important;
background-color: #F5F5F5 !important;
color: #000000 !important;
font-weight: bold !important;
text-align: center;
`

const Td = styled.td`
padding: 8px 10px !important;
text-align: center;
`
const TdTitle = styled.td`
text-align: left;
padding-left: 20px;
`
export default function PostsTable(props) {

    const navigation = useNavigate();
    const location = useLocation();
    const [searchType, setSearchType] = useState("2");
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        if (!props.renderDataOrNot) {
            setSearchText("");
            setSearchType("2");
        }
    }, [props.renderDataOrNot])

    function onClickButtonWrite() {
        navigation(location.pathname + '/0', {
            state : props.pageInfo.currentPageNumber.toString()
        });
        // props.setScreenState((prev) => {
        //     return {
        //         ...prev,
        //         screenType: 'write'
        //     }
        // })
    }

    function onChangeSelectItem(e) {
        const { value } = e.target;
        setSearchType(value);
    }

    function  onChangeSearch(e) {
        const { value } = e.target;
        setSearchText(value);
    }

    function observeEnter(e) {
        if (e.key === 'Enter') {
            findWithText();
        }
    }

    function findWithText() {
        if (searchText === '') {
            alert("검색어를 입력해주세요.");
            return;
        }
        if (searchType === '1') {
            if (isNaN(searchText)) {
                alert("숫자만 입력해주세요.");
                return;
            }
        }
        props.setScreenState((prev) => {
            return {
                ...prev,
                searchText: searchText,
                searchType: searchType
            }
        })
    }

    function eraseText() {
        props.setScreenState((prev) => {
            return {
                ...prev,
                searchText: '',
                searchType: '2'
            }
        })
        setSearchType('2');
        setSearchText('');
    }

    return (
        <>
            <ButtonWrite onClick={onClickButtonWrite}>등록하기</ButtonWrite>
            <HeaderFlex>
                <H3>전체 {props.totalElements}건</H3>
                <InputFlex>
                    <SelectInput id="selectAdminSearch" name="type" value={searchType} onChange={onChangeSelectItem}>
                        <option value="1">No</option>
                        <option value="2">제목</option>
                        <option value="3">작성자</option>
                    </SelectInput>
                    <Input id="adminSearch" type="text" value={searchText} onChange={onChangeSearch} onKeyPress={observeEnter} />
                    <Button onClick={findWithText}>조회</Button>
                    <Button onClick={eraseText}>초기화</Button>
                </InputFlex>
            </HeaderFlex>
            <Table>
                {props.boardId === '3' ? // Resources
                <colgroup>
                    <col width="5%" />
                    <col width="50%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="*" />
                </colgroup>
                : // 나머지   
                <colgroup>
                    <col width="5%" />
                    <col width="60%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="*" />
                </colgroup>
                }
                <Thead>
                    {props.boardId === '6' ? // 연혁
                    <tr>
                        <Th>No.</Th>
                        <Th>제목</Th>
                        <Th></Th>
                        <Th>연혁</Th>
                        <Th>마지막수정일</Th>
                        <Th></Th>
                    </tr>
                    : 
                    (props.boardId === '3' ? // Resources
                    <tr>
                        <Th>No.</Th>
                        <Th>제목</Th>
                        <Th>카테고리</Th>
                        <Th>작성자</Th>
                        <Th>메인노출일</Th>
                        <Th>마지막수정일</Th>
                        <Th>첨부파일</Th>
                    </tr>
                    : // 나머지
                    <tr>
                        <Th>No.</Th>
                        <Th>제목</Th>
                        <Th>작성자</Th>
                        <Th>메인노출일</Th>
                        <Th>마지막수정일</Th>
                        <Th>첨부파일</Th>
                    </tr>
                    )
                    }
                </Thead>
                {props.hasDataList && props.renderDataOrNot ? <PostsTableData posts={props.posts} setScreenState={props.setScreenState} boardId={props.boardId} pageInfo={props.pageInfo} /> : <tbody></tbody>}
            </Table>
            <Pager pageInfo={{currentPageNumber: props.pageInfo.currentPageNumber, totalPages: props.pageInfo.totalPages}} setScreenState={props.setScreenState} />

        </>
    )
}