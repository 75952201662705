import React, {Component} from 'react';
import arrow_left from '../../../static/assets/components/announcement/arrow_left.png';
import arrow_hover_left from '../../../static/assets/components/announcement/arrow_hover_left.png';
import arrow_right from '../../../static/assets/components/announcement/arrow_right.png';
import arrow_hover_right from '../../../static/assets/components/announcement/arrowt_hover_righ.png';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

class AnnouncePage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        isFirst : true,
        isLast : true,
        left : arrow_left,
        right : arrow_right,
        number : [],
        isMobile : deviceDetect()
    }

    render() {
        const page = this.state.number.map((num) => <div className={"pageNum" + (this.props.data.currentPageNumber + 1 === num ? ' selected' : '')} key={num} onClick={()=> this.props.changePage(num)}>{num}</div>)

        if (this.state.isMobile) {
            return (
              <div className="announcePage_mobile">
                  <div className={"arrow-left" + (this.state.isFirst ? '-hide':'')}
                       onClick={() => this.props.changePage(1)}
                  >
                      <img src={this.state.left} alt="arrow_left" />
                  </div>
                  {page}
                  <div className={"arrow-right" + (this.state.isLast ? '-hide':'')}
                       onClick={() => this.props.changePage(this.props.data.totalPages)}
                  >
                      <img src={this.state.right} alt="arrow_right" onError={(e) => e.target.src = `${noImg}`} />
                  </div>
              </div>
            );
        }

        return (
            <div className="announcePage">
                <div className={"arrow-left" + (this.state.isFirst ? '-hide':'')}
                     onMouseEnter={() => (this.setState({left : arrow_hover_left}))}
                     onMouseLeave={() => (this.setState({left : arrow_left}))}
                     onClick={() => this.props.changePage(1)}
                ><img src={this.state.left} alt="arrow_left" width="18px" height="18px" onError={(e) => e.target.src = `${noImg}`}/></div>
                {page}
                <div className={"arrow-right" + (this.state.isLast ? '-hide':'')}
                     onMouseEnter={() => (this.setState({right : arrow_hover_right}))}
                     onMouseLeave={() => (this.setState({right : arrow_right}))}
                     onClick={() => this.props.changePage(this.props.data.totalPages)}
                ><img src={this.state.right} alt="arrow_right" width="18px" height="18px" onError={(e) => e.target.src = `${noImg}`}/></div>
            </div>
        );
    }

    getNumberList = () => {
        const page = this.props.data.currentPageNumber + 1;
        const total = this.props.data.totalPages;

        let numList = [];
        for (let i=page-2; i <= page +2; i++) {

            if (i <= 0) {
                numList.push(i + 5);
            } else if (i > total){
                numList.push(i - 5);
            } else {
                numList.push(i);
            }
        }

        numList.sort()

        this.setState({
            isFirst : this.props.data.isFirst,
            isLast : this.props.data.isLast,
            number : numList
        })
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getNumberList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.currentPageNumber !== this.props.data.currentPageNumber) {
            this.getNumberList()
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default AnnouncePage;