import React, {Component} from 'react';
import InsightShape from "./InsightShape";
import BusinessInsightCardList from "./BusinessInsightCardList";
import '../../../../static/css/components/AboutInsight.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class BusinessInsight extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div className="businessInsightComponent_mobile">
                    <div className="businessInsightHeader_mobile">
                        <div className="prefix">빅밸류 공간데이터를 통해</div>
                        <div className="postfix">
                            <span className="bold">Business Insight</span>
                            를 확인하세요.
                        </div>
                    </div>
                    <div className="businessInsightBody_mobile">
                        <InsightShape />
                        <BusinessInsightCardList />
                    </div>
                </div>
            );
        }

        return (
            <div className="businessInsightComponent">
                <div className="businessInsightHeader">
                    <div className="prefix">빅밸류 공간데이터를 통해</div>
                    <div className="postfix">
                        <span className="bold">Business Insight</span>
                        를 확인하세요.
                    </div>
                </div>
                <div className="businessInsightBody">
                    <InsightShape />
                    <BusinessInsightCardList />
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default BusinessInsight;