import React, {Component} from 'react';
import icAPI from '../../../static/assets/components/dataCard/icAPI.png';
import icData from '../../../static/assets/components/dataCard/icOnData.png';
import icCorp from '../../../static/assets/components/dataCard/icCorporation.png';
import "../../../static/css/components/DataCard.css";
import CountUp from 'react-countup';
import axios from "axios";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

class DataCard extends Component {
    constructor(props) {
        super(props);

        let new_name = '';
        let new_icon = '';
        let new_unit = '';

        if(this.props.value === 1) {
            new_name = 'API 조회수';
            new_icon = <img src={icAPI} alt={new_name} onError={(e) => e.target.src = `${noImg}`}/>;
            new_unit = '회';
        } else if (this.props.value === 2) {
            new_name = 'Data 사용량';
            new_icon = <img src={icData} alt={new_name} onError={(e) => e.target.src = `${noImg}`}/>;
            new_unit = 'GB';
        } else {
            new_name = '활용 기업수';
            new_icon = <img src={icCorp} alt={new_name} onError={(e) => e.target.src = `${noImg}`}/>;
            new_unit = '개';
        }

        this.state = {
            num : this.props.value,
            icon : new_icon,
            name : new_name,
            acm : 0,
            perM : 0,
            unit : new_unit,
            isMobile : deviceDetect()
        }
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div className="dataCard_mobile">
                    <div className="icon">
                        {this.state.icon}
                    </div>
                    <div className="dataCardWord">
                        <div className="cardTitle">{this.state.name}</div>
                        <div className="cardData">
                            <div className="ic">누적</div>
                            <div className="cardAcm">
                                <CountUp end={this.state.acm} separator={','} />
                            </div>
                            <div className="acmUnit">{this.state.unit}</div>
                        </div>
                        <div className={"cardData" + (this.state.num === 3 ? '-hide' : '')}>
                            <div className="ic">월</div>
                            <div className="cardPerM">
                                <CountUp end={this.state.perM} separator={','} />
                            </div>
                            <div className="perMUnit">{this.state.unit}</div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="dataCard">
                <div className="icon">
                    {this.state.icon}
                </div>
                <div className="dataCardWord">
                    <div className="cardTitle">{this.state.name}</div>
                    <div className="cardData">
                        <div className="ic">누적</div>
                        <div className="cardAcm">
                            <CountUp end={this.state.acm} separator={','} />
                        </div>
                        <div className="acmUnit">{this.state.unit}</div>
                    </div>
                    <div className={"cardData" + (this.state.num === 3 ? '-hide' : '')}>
                        <div className="ic">월</div>
                        <div className="cardPerM">
                            <CountUp end={this.state.perM} separator={','} />
                        </div>
                        <div className="perMUnit">{this.state.unit}</div>
                    </div>
                </div>
            </div>
        );
    }

    getDataCard = async () => {

        const PARAMS = {
            countId: this.state.num
        }

        await axios.get(process.env.REACT_APP_API_URL + '/api/getCount', {
            params: PARAMS
        })
            .then((Response) => {
                let perMonth = Number(Response.data.month);
                if((this.state.num === 1) && (perMonth === 0)) {
                    // 서버 이상 등으로 월 조회수가 0일 경우. 약 25만으로 설정(총계 영향 X)
                    perMonth = Math.floor((new Date())/6789000);
                }
                this.setState({
                    acm: Response.data.full,
                    perM: perMonth,
                    unit: Response.data.unit
                });
            })
            .catch((Error) => {
                console.log(Error)
            });
        // let new_num = this.props.value;
        // let new_name = '';
        // let new_icon = '';
        // let new_acm = this.state.acm + 1;
        // let new_perM = this.state.perM + 1;
        // let new_unit = '';
        //
        // if (this.state.num === 1) {
        //     new_name = 'API 조회수';
        //     new_icon = <img src={icAPI} alt={new_name}/>;
        //     new_unit = '회';
        // } else if (this.state.num === 2) {
        //     new_name = 'Data 사용량';
        //     new_icon = <img src={icData} alt={new_name}/>;
        //     new_unit = 'GB';
        // } else {
        //     new_name = '활용 기업수';
        //     new_icon = <img src={icCorp} alt={new_name}/>;
        //     new_unit = '개';
        // }
        // this.setState({
        //     num: new_num,
        //     icon: new_icon,
        //     name: new_name,
        //     acm: new_acm,
        //     perM: new_perM,
        //     unit: new_unit,
        // });
    }


    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getDataCard();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default DataCard;