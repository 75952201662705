import React, {Component} from 'react';
import noImg from "../../../../static/assets/utils/noimg.png";

class CarouselCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.data;

        return (
            <div className="carouselCard">
                <div className="carouselIcon">
                    <img src={data.img} alt="icon" onError={(e) => e.target.src = `${noImg}`}/>
                </div>
                <div className="carouselBody">
                    {data.body}
                </div>
            </div>
        );
    }
}

export default CarouselCard;