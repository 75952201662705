import React, {Component} from 'react';
import arrow from '../../../../static/assets/components/recruit/arrow.png';
import arrow_close from '../../../../static/assets/components/recruit/arrow_close.png';
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";


class RecruitBoard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isClick: false,
            isMobile: deviceDetect(),
            isExpire: false
        };
    }

    render() {
        return (
            <div className={"board" + (this.state.isExpire ? " expireBoard": "")}>
                <div className="boardHeader" onClick={() => this.setState({isClick: !this.state.isClick})}>
                    <div className="boardIntro">
                        <div className={"boardTitle" + (this.state.isMobile ? "_mobile" : "")}>{this.props.data.title}</div>
                        <div className={"boardSubTitle" + (this.state.isMobile ? "_mobile" : "")}><span className="recruitCareer">{this.props.data.condition}</span> {this.props.data.job}</div>
                        <div className={"boardStatus" + (this.state.isMobile ? "_mobile" : "") + (this.state.isExpire ? " expire":"")}>{this.state.isExpire ? "마감":"진행중"}</div>
                    </div>
                    {
                        this.state.isExpire?
                            <div></div> :
                            <div>
                                <img src={this.state.isClick ? arrow_close : arrow} alt="arrow" onError={(e) => e.target.src = `${noImg}`}/>
                            </div>
                    }
                </div>
                {

                    this.state.isExpire?
                        <div></div> :
                        <div className={"recruitBody" + (this.state.isClick ? "":" hide")} dangerouslySetInnerHTML={ {__html: this.props.data.body} }>
                        </div>
                }
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);

        if (new Date(this.props.data.expire) < new Date()) {
            this.setState({
                isExpire : true
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default RecruitBoard;