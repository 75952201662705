import React, {useEffect, useState} from "react";

import styles from './index.module.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";


function createServiceData(options) {

    //const type = options.type;
    const title = options.title;
    const afterWord = options.afterWord;
    const itemsProps = options.itemsProps;

    return (props) => {

        const [isMobile, setMobile] = useState(deviceDetect());

        useEffect(() => {
            const windowResize = () => {
                const res = deviceDetect();

                setMobile(res);
            }

            window.addEventListener(`resize`, windowResize);

            return () => {
                window.removeEventListener(`resize`, windowResize);
            };
        }, [])

        if (isMobile) {
            return (
                <div className={styles.background_mb}>
                    <div>
                        <div className={styles.title_mb}>{title}</div>
                        <div className={styles.head_mb}>{title}{afterWord} 제공하는 데이터</div>
                    </div>
                    <div className={styles.bottom_mb}>
                        {itemsProps.map((el, index) => (
                            <div className={styles.item_mb} key={index}>
                                <div className={styles.item_icon_mb}>
                                    <img className={styles.item_icon_image_mb} src={el.iconImage} onError={(e) => e.target.src = `${noImg}`}/>
                                </div>
                                <div className={styles.item_category_mb}>{el.category}</div>
                                {el.dataList.map((el2, index2) => (
                                    <div className={styles.item_description_mb} key={index2}>{el2}</div>
                                ))}
                            </div>
                        ))}
                        <div className={styles.item_mb}></div>
                    </div>
                </div>
            );
        }
        return(<div className={styles.bkgnd}>

            <div className={styles.content}>

                <div className={styles.up}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.head}>{title}{afterWord} 제공하는 데이터</div>
                </div>

                <div className={styles.bottom}>

                    {itemsProps.map((el, index) => (

                        <div className={styles.item} key={index}>
                            <div className={styles.item_icon}>
                                <img className={styles.item_icon_image} src={el.iconImage} onError={(e) => e.target.src = `${noImg}`}/>
                            </div>

                            <div className={styles.item_category}>{el.category}</div>

                            {el.dataList.map((el2, index2) => (
                                <div className={styles.item_description} key={index2}>{el2}</div>
                            ))}
                        </div>

                    ))}

                </div>

            </div>

        </div>);
    };
}

export default createServiceData;