import React, {useState, useEffect} from 'react';
import '../../../static/css/components/BigvaluePeople.css';
import {isMobile} from "react-device-detect";
import btn_close
  from "../../../static/assets/components/bigvaluePeople/close.svg";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

const BigvaluePeople = () => {

  // 0: not selected
  // 1: selected first person
  // 2: selected second person
  const [row1SelectedItem, setRow1SelectedItem] = useState(0)
  // const [row2SelectedItem, setRow2SelectedItem] = useState(0)
  const [selectedItem_mobile, setSelectedItem_mobile] = useState(-1);
  const [isMobile, setMobile] = useState(deviceDetect());

  useEffect(() => {
    const windowResize = () => {
      const res = deviceDetect();

      setMobile(res);
    }

    window.addEventListener(`resize`, windowResize);

    return () => {
      window.removeEventListener(`resize`, windowResize);
    };
  }, [])

  if (isMobile) {
    const data_top = [
      {
        id: 1,
        name: '구름',
        position: 'CEO & Co-founder'
      },
      {
        id: 2,
        name: '이병욱',
        position: 'CEO & Co-founder'
      }
    ];
    const details = [
      {
        who: '구름 CEO',
        title: <div className='title'>
          <div>데이터를 통한 산업전반의</div>
          <div>패러다임 변화를 꿈꾼다.</div>
        </div>,
        desc: [
          <li>現 한양대학교 도시공학과 겸임교수</li>,
          <li>現 국가데이터정책위원회 보호·활용분과 전문위원</li>,
          <li>KTB투자증권 IB본부 대체투자팀 과장</li>,
          <li>교보증권 상품개발팀 대리</li>,
          <li>이데일리 금융공학연구소 과장</li>,
          <li>성균관대학교 경영학과</li>
        ]
      },
      {
        who: '이병욱 CEO',
        title: <div className='title'>
          <div>성공을 포기하지 않으면,</div>
          <div>실패는 없다.</div>
        </div>,
        desc: [
          <li>더존비즈온 모바일 사업부 부서장</li>,
          <li>KG이데일리 모바일사업 팀장</li>,
          <li>미국 ASU Thunderbird MBA-Strategy Marketing</li>,
          <li>건국대학교 경영학과</li>
        ]
      }
    ];

    const peopleData_top = data_top.map(
      (card) => <div key={card.id} id={"bpContentPeople_mobile" + (card.id)}
                     className="bpContent_mobile"
                     onClick={() => setSelectedItem_mobile(card.id - 1)}>
        <div className="name_mobile">{card.name}</div>
        <div className="position_mobile">{card.position}</div>
      </div>)
    const detail_li = selectedItem_mobile >= 0
      ? details[selectedItem_mobile].desc.map((item) => item) : null;

    return (
      <div className="bigvaluePeople_mobile">
        <div id='bpTitleArea' className="bpTitleArea_mobile">
          <div id='bpTitle' className="bpTitle_mobile">BIGVALUE People</div>
          <div id='bpTitleDesc' className="bpTitleDesc_mobile">
            <div>빅밸류 경영진을</div>
            <div>소개합니다</div>
          </div>
        </div>
        <div className="bpContentArea_mobile">
          {peopleData_top}
        </div>
        {
          selectedItem_mobile >= 0 ?
            <div className={"detail_mobile select" + (selectedItem_mobile + 1)}>
              <div className="detailHeader">
                <div
                  className="title_mobile">{details[selectedItem_mobile].title}</div>
                <div className='detailClose'
                     onClick={() => setSelectedItem_mobile(-1)}>
                  <img src={btn_close} alt="close"
                       onError={(e) => e.target.src = `${noImg}`}/>
                </div>
              </div>
              <div className="detailBody">
                <ul>
                  {detail_li}
                </ul>
              </div>
            </div> : <div className="detail_mobile noData"/>
        }
      </div>
    );
  }

  return (
    <div id='BigvaluePeople' className="BigvaluePeople">
      <div id='bpTitleArea' className="bpTitleArea">
        <div id='bpTitle' className="bpTitle">BIGVALUE People</div>
        <div id='bpTitleDesc' className="bpTitleDesc">빅밸류 경영진을 소개합니다</div>
      </div>
      <div id='bpContentArea' className="bpContentArea">
        <div id='bpContentRow1' className="bpContentRow">
          <div id='bpContentPeople1'
               className={"bpContentPeople" + (row1SelectedItem === 1 ? ' selected' : ' ')}>
            <div className='portrait' onClick={() => setRow1SelectedItem(1)}>
              <div className="hover">
                <div className="hoverText">
                  <div className='name'>구름</div>
                  <div className='position'>CEO & Co-founder</div>
                </div>
              </div>
            </div>
            <div className={"detail"}>
              <div className='detailText'>
                <div className='who'>구름 CEO</div>
                <div className='title'>"데이터를 통한 산업전반의 <br/>패러다임 변화를 꿈꾼다."</div>
                <div className='desc'>
                  <ul>
                    <li>現 한양대학교 도시공학과 <br/>겸임교수</li>
                    <li>現 국가데이터정책위원회 <br/>보호·활용분과 전문위원</li>
                    <li>KTB투자증권 IB본부 <br/>대체투자팀 과장</li>
                    <li>교보증권 상품개발팀 대리</li>
                    <li>이데일리 금융공학연구소 과장</li>
                    <li>성균관대학교 경영학과</li>
                  </ul>
                </div>
              </div>
              <div className='detailClose'
                   onClick={() => setRow1SelectedItem(0)}></div>
            </div>
          </div>

          <div id='bpContentPeople2'
               className={"right bpContentPeople" + (row1SelectedItem === 2 ? ' selected' : ' ')}>
            <div className="detail">
              <div className='detailText'>
                <div className='who'>이병욱 CEO</div>
                <div className='title'>"성공을 포기하지 않으면, <br/>실패는 없다."</div>
                <div className='desc'>
                  <ul>
                    <li>더존비즈온 모바일 사업부 부서장</li>
                    <li>KG이데일리 모바일사업 팀장</li>
                    <li>미국 ASU Thunderbird <br/>MBA-Strategy Marketing</li>
                    <li>건국대학교 경영학과</li>
                  </ul>
                </div>
              </div>
              <div className='detailClose'
                   onClick={() => setRow1SelectedItem(0)}></div>
            </div>
            <div className='portrait'
                 onClick={() => setRow1SelectedItem(2)}>
              <div className="hover">
                <div className="hoverText">
                  <div className='name'>이병욱</div>
                  <div className='position'>CEO & Co-founder</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BigvaluePeople;