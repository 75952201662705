import React, {useEffect, useState} from 'react';


export default function useDeviceDetect() {
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        const userAgent =
            typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        let mobile = Boolean(
            userAgent.match(
                /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
            )
        );

        if (mobile === false) {
            const width = window.innerWidth;

            if (width <= 500) mobile = true;
        }

        setMobile(mobile);
    }, []);

    return { isMobile };
}

export function deviceDetect() {
    const userAgent =
        typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    let mobile = Boolean(
        userAgent.match(
            /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        )
    );

    if (mobile === false) {
        const width = window.innerWidth;

        if (width <= 1000) mobile = true;
    }

    return mobile;
}