import React, {Component} from 'react';
import Strength from "./Strength";
import '../../../static/css/components/Strength.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class StrengthList extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        const numbers = [1,2,3,4,5,6];
        const result = numbers.map((num) => <Strength key={num} value={num} />)
        if (this.state.isMobile) {
            return (
              <div className="strengthComponent_mobile">
                  <div className="strengthTitle">
                      <div className="whyBigvalue">WHY BIGVALUE?</div>
                      <div className="subTitle">
                          <span className="subTitle-front">빅밸류의 </span>
                          <span className="subTitle-back">경쟁력</span>
                      </div>
                      <div className="pharse">
                          <div>공간AI전문 기업 빅밸류가</div>
                          <div>공간데이터 시장에서 주목받는 이유입니다.</div>
                      </div>
                  </div>
                  <div className="strengthList_mobile">
                      {result}
                  </div>
              </div>
            );
        }
        return (
            <div className="strengthComponent">
                <div className="strengthList">
                    <div className="strengthTitle">
                        <div className="whyBigvalue">WHY BIGVALUE?</div>
                        <div className="subTitle">
                            <span className="subTitle-front">빅밸류의 </span>
                            <span className="subTitle-back">경쟁력</span>
                        </div>
                        <div className="pharse">
                            <div>공간AI전문 기업 빅밸류가</div>
                            <div>공간데이터 시장에서 주목받는 이유입니다.</div>
                        </div>
                    </div>
                    {result}
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default StrengthList;