import React, {Component} from 'react';
import '../../css/utils/loadingIndicator.css';
import {isMobile} from "react-device-detect";
import noImg from "../../assets/utils/noimg.png";

class LoadingIndicator extends Component {
    render() {
        if (isMobile) {
            return (
                <div className="loadingIndicator_mobile">
                    <img src={require("../../assets/utils/Spinner-1s-250px.gif")} alt="loadingIndicator" onError={(e) => e.target.src = `${noImg}`}/>
                </div>
            );
        }
        return (
            <div className="loadingIndicator">
                <img src={require("../../assets/utils/Spinner-1s-250px.gif")} alt="loadingIndicator" onError={(e) => e.target.src = `${noImg}`}/>
            </div>
        );
    }
}

export default LoadingIndicator;