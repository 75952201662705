
import ic1 from "./assets/ic_토지대장.svg";
import ic2 from "./assets/ic_건축물.svg";
import ic3 from "./assets/ic_전유부.svg";
import ic4 from "./assets/ic_유사사례.svg";
import ic5 from "./assets/ic_환경정보.svg";
import createServiceData from "../../ServiceData";

const VillasiseServiceData = createServiceData({

    type: 'Villasise.com',
    title: 'Villasise.com',
    afterWord: '이',

    itemsProps: [{
        category: '토지대장',

        iconImage: ic1,

        dataList: [
            '토지면적',
            '토지이용',
            '공시지가',
            '이용현황',
            '대지권'
        ]
    }, {
        category: '건축물',

        iconImage: ic2,

        dataList: [
            '사용승인',
            '건물 규모',
            '건물 구조',
            '지붕'
        ]
    }, {
        category: '전유부',

        iconImage: ic3,

        dataList: [
            '전용면적',
            '공용면적',
            '호 배치'
        ]
    }, {
        category: '유사사례',

        iconImage: ic4,

        dataList: [
            '걷는거리',
            '유사도',
            '실거래가',
            '유형'
        ]
    }, {
        category: '환경정보',

        iconImage: ic5,

        dataList: [
            '주변환경',
            '인프라',
            '교통',
            '지역인구지표'
        ]
    }]
});

export default VillasiseServiceData;