import React, {Component} from "react";
import '../../static/css/components/Header.css';
import HeaderCategoryList from "./HeaderCategoryList";
import logo from "./assets/logo.png";
import ic_menu from "./assets/ic_menu.png";
import ic_close from "./assets/ic_close.png";
import arrow_left from "./assets/패스 24.png";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import noImg from "../../static/assets/utils/noimg.png";

class Header extends Component {

  state = {
    isCheck: false,
    categories: [
      {id: '1', name: 'About us', isOpen: false},
      {id: '2', name: 'Resources', isOpen: false},
      {id: '3', name: 'Solution', isOpen: false},
      {id: '4', name: 'Data', isOpen: false}
    ],
    isMobile: deviceDetect()
  };

  render() {

    if (this.state.isMobile) {
      const list = this.state.categories.map((menu) => <HeaderCategoryList key={menu.id} value={menu.name} isOpen={menu.isOpen} click={this.checkClick} closeOther={this.changeStateOther}/>);

      return (
        <div className="header_mobile">
          <div className="header_flex">
            <div className="logo" onClick={this.moveToMain}>
              <img src={logo} alt="logo" onError={(e) => e.target.src = `${noImg}`}/>
            </div>
            <div className="category">
              <div className="headerIndex" onClick={() => this.setState({isCheck: !this.state.isCheck}, this.eventScroll)}>
                {this.state.isCheck ?
                  <img className="close" src={ic_close} alt="ic_close" onError={(e) => e.target.src = `${noImg}`}/> :
                  <img className="menu" src={ic_menu} alt="ic_menu" onError={(e) => e.target.src = `${noImg}`}/>
                }
              </div>
            </div>
          </div>
          <div className={"headerMenuList" + (this.state.isCheck ? "" : "-hide")}>
            <div className="headerSubList">
              {list}
            </div>
            <div className="headerContact">
              <div className="headerContactTitle">궁금한 사항이 있으신가요?</div>
              <div className="headerContactBody">문의사항을 남겨주시면 담당자가 답변드리겠습니다.</div>
              <div className="headerContactButton" onClick={this.goContact}>
                <div>문의하기</div>
                <div>
                  <img src={arrow_left} alt="arrow" onError={(e) => e.target.src = `${noImg}`}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    const result = this.state.categories.map((category) => <HeaderCategoryList key={category.id} value={category.name}/>);
    return (
      <div className="header">
        <div className="headerItem">
          <div className="logo" onClick={this.moveToMain}>
            <img src={logo} alt="logo" onError={(e) => e.target.src = `${noImg}`}/>
          </div>
          <div className="category">
            <div className="icsearch"></div>
            {result}
          </div>
        </div>
      </div>
    );
  }

  moveToMain = () => {
    window.location.href = '/'
  }

  checkClick = (val) => {
    this.checkClick(val);
    this.setState({
      isCheck: val
    });
  }

  changeStateOther = (index) => {
    let temp = this.state.categories;
  }

  goContact = () => {
    window.location.href = '/about/contactUs'
  }

  eventScroll = () => {
    if (this.state.isCheck) {
      document.body.style = `overflow: hidden`;
    }
    else {
      document.body.style = `overflow: auto`;
    }
  }

  windowResize = () => {
    const res = deviceDetect();

    this.setState({
      isMobile: res
    })
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.windowResize);
  }
}

export default Header;
