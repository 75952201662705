import React from "react";

import styles from './index.module.css';

import aiDeveloperLaptop from './assets/AIDeveloper-Laptop.png';
import noImg from "../../../../static/assets/utils/noimg.png";


class Aideveloper extends React.Component {

    render() {
        const link = (url) => {

            window.location = url;
        }
        return (<div className={styles.bkgnd}>

            <div className={styles.content}>
                <div className={styles.title}>AI Developer</div>
                <div className={styles.head_highlight}>부동산 개발 타당성 검토, AI로 한번에 해결하세요</div>
                <div className={styles.description}>주소 입력만으로 다양한 개발 사업의 수요분석, 분양가 예측, 비용 분석을 제공하며, 인허가 난이도를 분석하여 객관적인 사업성 지표를 제공합니다. 전국 3천만 필지에 대해 최적의 사업성 부지를 빅밸류 빅데이터 기술로 검색해보세요.</div>
                <button className={styles.button} onClick={()=>link('/about/contactUs')}>문의하기 및 소개서 받기</button>
                <div className={styles.desktop}>
                    <img className={styles.laptop} src={aiDeveloperLaptop} onError={(e) => e.target.src = `${noImg}`}/>
                </div>

            </div>

        </div>);
    }
}

export default Aideveloper;