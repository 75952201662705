import React from "react";
import ContactUs from "../../components/About/ContactUs";


class ContactUsPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {return(<>


        <ContactUs />


    </>);}
}

export default ContactUsPage;