import React, {useEffect, useState} from 'react';
import '../../static/css/pages/LeadershipPage.css';
import Leadership from '../../components/About/Leadership/Leadership';
import BigvaluePeople from '../../components/About/Leadership/BigvaluePeople';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";

const LeadershipPage = () => {

    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    if (isMobile) {
        return (
            <div className="LeadershipPage_mobile">
                <Leadership />
                <BigvaluePeople />
            </div>
        );
    }

    return (
        <div className="LeadershipPage">
            <Leadership />
            <BigvaluePeople />
        </div>
    );
}

export default LeadershipPage;