import React from "react";

import styles from './index.module.css';

import VillasiseLaptop from './assets/VillasiseLaptop.png';
import noImg from "../../../../static/assets/utils/noimg.png";


class Villasise extends React.Component {

    render() {
        const link = (url) => {

            window.location = url;
        }

        const windowOpen = () => {
            window.open("https://www.villasise.com")
        }

        return (<div className={styles.bkgnd}>

            <div className={styles.content}>

                <div>
                    <div className={styles.title}>Villasise.com</div>
                    <div className={styles.head}>
                        <div>세상에 없던 빌라 시세를 만들다</div>
                        <div>금융권에서 선택한 인공지능 시세</div>
                    </div>
                    <div className={styles.description}>
                        <div className={styles.descriptionTop}>
                            Villasise.com은 국내 최초로 선보인 인공지능시세입니다. 금융위원회 혁신금융서비스로 지정, 규제샌드박스 통한 은행업감독규정 개정으로 금융권에서 활발하게 사용되고 있습니다. KB시세가 제공하지 않는 50세대 미만 나홀로 아파트, 연립·다세대, 도시형생활주택은 물론, 거래가 없던 신축 건물의 시세를 매월 업데이트해 조회할 수 있습니다.
                        </div>
                        <div>
                            단순 시세는 NO! 산출 근거와 부동산 시장 분석까지 클릭 한 번으로 리포팅해드립니다.
                        </div>
                    </div>
                    <div className={styles.buttonGrp}>
                        <button className={styles.button} onClick={()=>link('/about/contactUs')}>문의하기 및 소개서 받기</button>
                        {/*<button className={styles.button} onClick={()=>windowOpen()}>빌라시세닷컴 바로가기</button>*/}
                    </div>
                </div>

                <div className={styles.desktop}>
                    <img className={styles.laptop} src={VillasiseLaptop} onError={(e) => e.target.src = `${noImg}`}/>
                </div>

            </div>

        </div>);
    }
}

export default Villasise;