import React, {Component} from 'react';
import DataCard from "./DataCard";
import "../../../static/css/components/DataCard.css"
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class DataCardList extends Component {

    state = {
        isMobile : deviceDetect()
    }
    render() {
        const numbers = [1,2,3];
        const result = numbers.map((num) => <DataCard key={num} value={num} />)
        if (this.state.isMobile) {
            return (
                <div className="dataCardList_mobile">
                    {result}
                </div>
            )
        }
        return (
            <div className="dataCardList">
                {result}
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isMobile === false && this.state.isMobile === true) {
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default DataCardList;