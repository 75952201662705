import React, { Component } from 'react';
import Article from "./Article";
import '../../../static/css/components/Article.css';
import axios from "axios";
import LoadingIndicator from "../../../static/utils/LoadingIndicator/loadingIndicator";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

class ArticleList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleList : [],
            page : 0,
            loading : true,
            loadingNext : false,
            isEmpty : false,
            isFin : false,
            isMobile : deviceDetect()
        }
    };

    render() {
        const result = this.state.articleList.map((article, index) => <Article article={article} key={index}/>);

        if (this.state.isMobile) {
            return (
              <div className="articleListContainer_mobile">
                  {this.state.loading ?
                      <LoadingIndicator /> :
                      this.state.isEmpty ?
                          <div className="articleNodata">글이 존재하지 않습니다.</div> :
                          <div className="articleList_mobile">
                              {result}
                          </div>
                  }
                  <div className="resourcesBtn_mobile">
                      {
                          this.state.loadingNext ? <div className="articleBtn-loading"><img src={require("../../../static/assets/utils/Spinner-1s-250px.gif")} alt="loadingIndicator" width="100px" height="100px" onError={(e) => e.target.src = `${noImg}`}/></div>:
                              <div className={"articleBtn" + (this.state.loading || this.state.isEmpty || this.state.isFin ? "-hide" : "")}
                                   onClick={this.addPage}></div>
                      }
                  </div>
              </div>
            );
        }
        return (
            <div className="articleListContainer">
                {this.props.children}

                {this.state.loading ?
                    <LoadingIndicator /> :
                    this.state.isEmpty ?
                    <div className="articleNodata">글이 존재하지 않습니다.</div> :
                    <div className={'articleList' + (this.props.limit === 2 ? '-vertical' : '')}>
                            {result}
                    </div>
                }
                <div className="resourcesBtn">
                    {
                        this.state.loadingNext ? <div className="articleBtn-loading"><img src={require("../../../static/assets/utils/Spinner-1s-250px.gif")} alt="loadingIndicator" width="100px" height="100px" onError={(e) => e.target.src = `${noImg}`}/></div>:
                        <div className={"articleBtn" + (this.state.loading || this.state.isEmpty || this.state.isFin ? "-hide" : "")}
                             onClick={this.addPage}></div>
                    }
                </div>
            </div>
        );
    }

    addPage = () => {
        this.setState({
            page : this.state.page + 1
        })
    }

   getArticleList = async () => {
        const PARAMS = {
            boardId: 3,
            categoryId: this.props.categoryId,
            page : this.state.page,
            size : this.props.limit,
            sort : "viewDate,DESC"
        }

       if (PARAMS.categoryId === 0) return;
       await axios.get(process.env.REACT_APP_API_URL + '/api/postListPreview', {
            params: PARAMS
        })
            .then((Response) => {
                const article_data = Response.data.data;
                let list = this.state.articleList;

                article_data.forEach((data) => {
                    let jsonObj = JSON.parse(data.additionalValues[0].json)
                    let dataForm = {
                        boardId: data.boardId,
                        postId: data.postId,
                        categoryId : data.categoryId,
                        articlePressName: jsonObj.articlePressName,
                        articleURL: jsonObj.articleURL,
                        title: data.title,
                        lead: jsonObj.lead,
                        date: data.viewDate,
                        imgExist: data.validFileCounts > 0,
                        attachedFiles: data.attachedFiles,
                        previewFileName: data.attachedFiles.filter((file) => file.isPreview === true).map((file) => file.savedFileName)[0],
                    }
                    list.push(dataForm);

                    if (data.rowNum === 1) {
                        this.setState({
                            isFin : true
                        })
                    }
                });

                this.setState({
                    articleList : list,
                    isEmpty : list.length <= 0
                }, () => {
                    this.setState({
                        loading : false,
                        loadingNext : false
                    })
                })
            })
            .catch((Error)=>{console.log(Error)});
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.setState({
            loading : true
        })
        this.getArticleList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page) {
            this.setState({
                loadingNext : true
            })
            this.getArticleList();
        }

        if (prevProps.categoryId !== this.props.categoryId) {
            this.setState({
                articleList : [],
                page : 0,
                isEmpty : false,
                loading : true,
                isFin : false
            }, () => {
                this.getArticleList();
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
        this.setState({
            articleList : [],
            page : 0,
            loading : true,
            isEmpty : false,
            isFin : false
        })
    }
}

export default ArticleList;