import React from "react";
import VillasiseContents from "../../components/Solution/Villasise/VillasiseContents";
import VillasiseInfo from "../../components/Solution/Villasise/VillasiseInfo";
import VillasiseServiceData from "../../components/Solution/Villasise/VillasiseServiceData/VillasiseServiceData";
import {isMobile} from "react-device-detect";
import villasiseImg from "../../static/assets/components/solutionContent/villasise/villasiseImg.png";
import styles from "../../components/Solution/Villasise/VillasiseContents/index.module.css";
import Content from "../../components/Solution/content";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";

class VillasisePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile : deviceDetect()
        }
    }

    render() {
        if (this.state.isMobile) {
            const contentData = {
                id : 4,
                header : 'Villasise.com',
                title : <div><div>세상에 없던 빌라 시세를 만들다</div><div>금융권에서 선택한 인공지능 시세</div></div>,
                body : <div className={styles.description_mb}><div className={styles.descriptionTop_mb}>Villasise.com은 국내 최초로 선보인 인공지능시세입니다. 금융위원회 혁신금융서비스로 지정, 규제샌드박스 통한 은행업감독규정 개정으로 금융권에서 활발하게 사용되고 있습니다. KB시세가 제공하지 않는 50세대 미만 나홀로 아파트, 연립·다세대, 도시형생활주택은 물론, 거래가 없던 신축 건물의 시세를 매월 업데이트해 조회할 수 있습니다.</div><div className="subBody">단순 시세는 NO! 산출 근거와 부동산 시장 분석까지 클릭 한 번으로 리포팅해드립니다.</div></div>,
                laptop : villasiseImg
            };

            return (
                <div>
                    <Content data={contentData} />
                    <VillasiseInfo/>
                    <VillasiseServiceData/>
                </div>
            )
        }
        return(
            <>
                <VillasiseContents/>
                <VillasiseInfo/>
                <VillasiseServiceData/>
            </>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default VillasisePage;