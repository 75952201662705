import styles from './index.module.css';

import aiDeveloperComments from './assets/AIDeveloperComments.png';
import bkgndLogo from '../../AiLobig/AilobigComments/assets/bg_logo.png';
import avatar1 from './assets/avatar1.png';
import avatar2 from './assets/avatar2.png';
import avatar3 from './assets/avatar3.png';
import noImg from "../../../../static/assets/utils/noimg.png";

function AIDeveloperComments(props) {

    return (<div className={styles.bkgnd}>

        <div className={styles.content}>

            <div className={styles.top}>
                <div className={styles.title}>Comments</div>
                <div className={styles.head}>AI Developer 고객 경험</div>
                <div className={styles.description}>AI Developer를 직접 경험한 고객사의 후기를 확인해보세요!</div>
            </div>

            <div className={styles.avatar}>
                <img className={styles.avatar_image} src={avatar1} onError={(e) => e.target.src = `${noImg}`}/>
                <div className={styles.avatar_text}>
                    이렇게 쉽게 전국 토지를 서칭할 수 있을거라고는 <br/> 상상해 보지 못했습니다.   <br/>
                    AI Developer는 <span className={styles.avatar_text_highlight}>소유자의 구입시점, 가격, 성별,</span> <br/>
                    <span className={styles.avatar_text_highlight}>나이, 재산 사항 등을 모두 분석</span>하여
                    지주 작업에  <br/> 필요한 정보 일체를 손쉽게 얻을 수 있습니다.

                    <div className={styles.avatar_by}>대형 건설사 개발사업팀 팀장</div>
                </div>
            </div>

            <div className={styles.avatar1}>
                <img className={styles.avatar_image} src={avatar2} onError={(e) => e.target.src = `${noImg}`}/>
                <div className={styles.avatar_text}>
                    AI Developer의
                    <span className={styles.avatar_text_highlight}>분양가 산정 과정은 매우 투명하고 </span> <br/>
                    <span className={styles.avatar_text_highlight}>직관적</span>이라서 사용하기 편리합니다. <br/>
                    매달 데이터가 갱신되어 관리하거나 검토 중인<br/> 모든 사업장의 분양성 정보가 업데이트되어 <br/>너무나 편리합니다.

                    <div className={styles.avatar_by}>국내 10대 주택 브랜드 건설사 마케팅팀</div>
                </div>
            </div>

            <div className={styles.avatar2}>
                <img className={styles.avatar_image} src={avatar3} onError={(e) => e.target.src = `${noImg}`}/>
                <div className={styles.avatar_text}>
                    국내 일반 산업단지의 허가 시점, 분양시점, 입주시점 <br/>
                    정보를 모두 보유하고 해당 시점으로 돌아가서 <br/>
                    <span className={styles.avatar_text_highlight}>시뮬레이션이 가능한 분석 기능을 제공해 줍니다. </span> <br/>
                    <span className={styles.avatar_text_highlight}>  딥서치 기능을 통해 </span> <br/>쉽게 산업단지 분양성 지표를 얻을 수 있었습니다.

                    <div className={styles.avatar_by}>국산업단지 개발 사업 담당자</div>
                </div>
            </div>

            <img className={styles.ailobig_process_image} src={aiDeveloperComments} onError={(e) => e.target.src = `${noImg}`}/>
            <img className={styles.bkgnd_logo_image} src={bkgndLogo} onError={(e) => e.target.src = `${noImg}`}/>

        </div>


    </div>);
}

export default AIDeveloperComments;