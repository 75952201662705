import React, {Component} from 'react';
import axios from "axios";
import {isMobile} from "react-device-detect";
import RecruitBoard from "./recruitBoard";
import "../../../../static/css/components/recruit.css";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class Recruit extends Component {
    state = {
        recruitData : [],
        isMobile : deviceDetect()
    };

    render() {
        const board = this.state.recruitData.map((data) => <RecruitBoard key={data.id} data={data} />);

        if (this.state.isMobile) {
            return (
              <div className="recruitComponent_mobile">
                  <div className="recruitTitle">
                      <div>채용 안내</div>
                  </div>
                  <div className="recruitBoardList_mobile">
                      {board}
                  </div>
              </div>
            );
        }

        return (
            <div className="recruitComponent">
                <div className="recruitFlex">
                    <div className="recruitTitle">
                        <div>채용 안내</div>
                    </div>
                    <div className="recruitBoardList">
                        {board}
                    </div>
                </div>
            </div>
        );
    }

    getRecruitData = async () => {
        const PARAMS = {
            boardId: 2,
            categoryId: 1,
            page: 0,
            size: 10
        };
        let recuitList = [];

        await axios.get(process.env.REACT_APP_API_URL + '/api/postList', {
            params: PARAMS
        })
            .then((Response) => {

                Response.data.data.forEach(data => {
                    const addition = JSON.parse(data.additionalValues[0].json);
                    const recruit = {
                        id: data.postId,
                        title: data.title,
                        condition: addition.employeeType,
                        job: addition.entryDepart,
                        expire: addition.entryDuration,
                        body: data.content
                    }
                    if (new Date(recruit.expire) < new Date()) {
                        recuitList.push(recruit)
                    } else {
                        recuitList.unshift(recruit);
                    }
                })
            })
            .catch((Error) => {
                console.log(Error)
            });

        this.setState({
            recruitData: recuitList
        });
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getRecruitData();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Recruit;