import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {useLocation, useNavigate} from "react-router-dom";

const Tr = styled.tr`
    border-bottom: 1px solid #e5e5e5;
`
const Td = styled.td`
    padding: 8px 10px !important;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
`
const TdTitle = styled.td`
    text-align: left;
    padding-left: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        text-decoration: underline;
    }
`

export default function ContactsTableData(props) {

    const contacts = props.contacts;
    const navigation = useNavigate();
    const location = useLocation();

    function handleEditClick(contactId) {
        navigation(location.pathname + "/" + contactId, {
            state : props.pageInfo.currentPageNumber.toString()
        });
    }
  
    return (
    <tbody>
      {contacts.map((contact, index) => (
        <Tr key={index}>
          <Td>{contact.idx}</Td>
          <TdTitle onClick={() => handleEditClick(contact.idx)}>{contact.inquiry}</TdTitle>
          <Td>{contact.contactName}</Td>
          <Td>{contact.email}</Td>
          <Td>{contact.createDate}</Td>
          {contact.reply !== undefined ?
            <Td style={{fontWeight: 'bold', color: 'blue'}}>답변등록</Td>
          :
            <Td style={{fontWeight: 'bold', color: 'red'}}>답변대기</Td>
          }
        </Tr>
      ))}
    </tbody>
    );
}