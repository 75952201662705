import React, {useState, useEffect, useContext, useRef} from 'react';
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import styled from 'styled-components';
import { GlobalStoreProvider } from './AdminGlobalStore';
import axios from 'axios';
import PostsTable from './PostsTable';
import PostsTableData from './PostsTableData';
import Pager from './Pager';
import PostWrite from './PostWrite';
import { useGlobalStoreState, useRefreshCounterState } from './AdminGlobalStore';
import {useAdminInfoActionState} from "./AdminInfoStore";
import ContactsTable from "./ContactsTable";
import ContactRead from "./ContactRead";

/* 스타일 정의 */        
const DivArea = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    padding: 0 20px 20px 20px;
    width: 100%;
`;

function AdminContent(props) {

    const location = useLocation();
    const [screenState, setScreenState] = useState({
        screenType: 'list',
        pageNum: (location.state === undefined || location.state === null) ? 0 : location.state,
        categoryId: props.categoryId,
        selectedPostId: 0,
        content: [],
        boardIdFromProps: props.boardId,
        searchText: '',
        searchType: '2'
    });
    const [refreshCounter, ] = useRefreshCounterState()
    const action = useAdminInfoActionState();
    const nav = useNavigate();

    useEffect(() => {
        setScreenState((prev) => {
            return {
                ...prev,
                pageNum: (location.state === undefined || location.state === null) ? 0 : location.state
            }
        });
    }, [location.state]);

    /* useEffect 정의 */
    useEffect(() => {
        callApiList();
    }, [screenState.pageNum, screenState.categoryId, screenState.screenType, screenState.selectedPostId]);

    useEffect(() => {
        if(screenState.screenType !== 'list' ||
        screenState.pageNum !== 0 ||
        screenState.categoryId !== props.categoryId ||
        screenState.selectedPostId !== 0) {
            setScreenState((prevState) => {
                return {
                    ...prevState,
                    screenType: 'list',
                    pageNum: (location.state === undefined || location.state === null) ? 0 : location.state,
                    categoryId: props.categoryId,
                    selectedPostId: 0,
                    searchText: '',
                    searchType: '2'
                }
            }); 
        } else if(screenState.boardIdFromProps === props.boardId) { // side menu 클릭 시
            // do nothing: state 바꾸면서 다시 리렌더할 필요 없다.
        } else {
            callApiList();
        }
    }, [refreshCounter]); // side menu 클릭 시

    useEffect(() => {
        if (screenState.searchText === '') {
            callApiList();
            return;
        }
        callApiListSpecified();
    }, [screenState.searchType, screenState.searchText])

    async function callApiListSpecified() {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/admin/posts/${props.boardId}/${screenState.categoryId}/${screenState.searchType}/${screenState.searchText}?page=${screenState.pageNum}&size=15&sort=postId,desc`)
            .catch((error) => {
                action.logOut();
                nav('/login', {state: response.data.message});
            });

        setScreenState((prev) => {
            return {
                ...prev,
                content: response.data.length == 0 ? [] : response.data,
                boardIdFromProps: props.boardId,
            }
        });
    }

    /* 함수 정의 */
    async function callApiList(page) {
        if (screenState.screenType !== 'list')
             return
        if(page === undefined)
            page = screenState.pageNum;
        let response;
        if (props.boardId == 0 || screenState.categoryId == 0) {
            response = await axios.get(process.env.REACT_APP_API_URL + `/api/contact/paging?page=${screenState.pageNum}&size=15&sort=idx,desc`)
                .catch((error) => {
                    action.logOut();
                    nav('/login', {state: response.data.message});
                });
        } else if (screenState.searchText !== '') {
            response = await axios.get(process.env.REACT_APP_API_URL + `/api/admin/posts/${props.boardId}/${screenState.categoryId}/${screenState.searchType}/${screenState.searchText}?page=${screenState.pageNum}&size=15&sort=postId,desc`)
                .catch((error) => {
                    action.logOut();
                    nav('/login', {state: response.data.message});
                });
        } else {
            response = await axios.get(process.env.REACT_APP_API_URL + `/api/admin/posts/${props.boardId}/${screenState.categoryId}?page=${screenState.pageNum}&size=15&sort=postId,desc`)
                .catch((error) => {
                    action.logOut();
                    nav('/login', {state: response.data.message});
                });
        }
        // console.log('props.boardId: ', props.boardId);
        // console.log(response.data);
        setScreenState((prev) => {
            return {
                ...prev,
                content: response.data,
                boardIdFromProps: props.boardId,
            }
        });
    }
    // const listData2 = await callApiList()
    // console.log('listData2: ', listData2);

    function boardIdToMenuName(boardId) {
        switch (boardId) {
            case "1":
                return "공지사항";
            case "2":
                return "채용공고";
            case "3":
                return "Resources";
            case "6":
                return "연혁";
            default:
                return "문의사항";
        }
    }

    /* 필요한 변수들 정의 */
    const menuName = boardIdToMenuName(props.boardId);
    const hasData = Object.keys(screenState.content).length > 0;
    const hasDataList = Object.keys(screenState.content).length > 0 && screenState.content.data.length > 0;

    const propsForContactsTableData = {
        totalElements: hasData ? screenState.content.totalElements : '-',
        contacts: hasDataList ? screenState.content.data : null,
        hasData: hasData,
        hasDataList: hasDataList,
        pageInfo: {
            currentPageNumber: hasData ? screenState.content.currentPageNumber : 0,
            totalPages: hasData ? screenState.content.totalPages : 0,
        },
        setScreenState: setScreenState,
    }

    const propsForPostsTableData = {
        totalElements: hasData ? screenState.content.totalElements : '-',
        posts: hasDataList ? screenState.content.data : null,
        boardId: props.boardId,
        renderDataOrNot: screenState.boardIdFromProps === props.boardId,
        hasData: hasData,
        hasDataList: hasDataList,
        pageInfo: {
            currentPageNumber: hasData ? screenState.content.currentPageNumber : 0,
            totalPages: hasData ? screenState.content.totalPages : 0,
        },
        setScreenState: setScreenState,
    }

    return (
        <DivArea>
            <h1>{menuName}</h1>
            {screenState.screenType === 'list' && (props.boardId === '0' ? <ContactsTable {...propsForContactsTableData} /> : <PostsTable {...propsForPostsTableData} />)}
        </DivArea>
    );
}

export default AdminContent;