import React, {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import styles from './index.module.css';
import zoom from './assets/img_zoom.png';
import next from'./assets/next.svg';
import building from './assets/img_건축물.png';
import point from './assets/img_좌표.png';
import metro from './assets/img_지하철.png';
import country from './assets/img_지역별.png';
import villa from './assets/img_빌라.png';
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";

function Contact(props) {

    const itemsProps = [{
        category: '빠르게 검색하고\n 확인하는 주택',
        bkgndImage: zoom,
        className: styles.imgZoom,
        className_mb: styles.imgZoom_mb,
        description: '주소를 입력하시면 연립/다세대, 나홀로아파트\n주소로 자동 완성되며 지도와 로드뷰를 통해\n 찾으려는 주택이 맞는지 확인가능합니다.',
        description_el: <div><div>주소를 입력하시면 연립/다세대, 나홀로아파트</div><div>주소로 자동 완성되며 지도와 로드뷰를 통해</div><div>찾으려는 주택이 맞는지 확인가능합니다.</div></div>
    }, {
        category: '건축물 정보를\n 더욱 정확하게',
        bkgndImage: building,
        className: styles.imgBuilding,
        className_mb: styles.imgBuilding_mb,
        description: '공공데이터의 건축물 정보를 제공하며\n 공공데이터의 오류와 결측 정보를 보정한 경우\n 보정 내용을 알려드립니다.',
        description_el: <div><div>공공데이터의 건축물 정보를 제공하며</div><div>공공데이터의 오류와 결측 정보를 보정한 경우</div><div>보정 내용을 알려드립니다.</div></div>
    }, {
        category: '원하는 시세정보를 \n보다 자세하게',
        bkgndImage: villa,
        className: styles.imgVilla,
        className_mb: styles.imgVilla_mb,
        description: '빌라의 각 호별 최신 시세 정보를 바로 확인 가능하며,\n 바로 확인하며 2년간의 월별 시세 추이도 부터\n 최근 5년간 실거래 정보까지 제공합니다.',
        description_el: <div><div>빌라의 각 호별 최신 시세 정보를 바로 확인 가능하며,</div><div>바로 확인하며 2년간의 월별 시세 추이도 부터</div><div>최근 5년간 실거래 정보까지 제공합니다.</div></div>
    }, {
        category: '인근 유사 사례와 \n거래 단가를 한눈에',
        bkgndImage: point,
        className: styles.imgPoint,
        className_mb: styles.imgPoint_mb,
        description: '빌라의 데이터를 분석하여\n가장 유사한 인근 거래 사례 및 단가를\n 한 눈에 볼 수 있게 제공합니다.',
        description_el: <div><div>빌라의 데이터를 분석하여</div><div>가장 유사한 인근 거래 사례 및 단가를</div><div>한 눈에 볼 수 있게 제공합니다.</div></div>
    }, {
        category: '지하철 역부근\n 빌라정보를 쉽게',
        bkgndImage: metro,
        className: styles.imgMetro,
        className_mb: styles.imgMetro_mb,
        description: '빌라 인근에 지하철 역이 있는 경우 역세권 분석을 제공합니다. \n시세 추이 비교 및 역세권 내 빌라의 거래량과 \n시장의 공급 현황을 파악 할 수 있습니다.',
        description_el: <div><div>빌라 인근에 지하철 역이 있는 경우 역세권 분석을 제공합니다.</div><div>시세 추이 비교 및 역세권 내 빌라의 거래량과</div><div>시장의 공급 현황을 파악 할 수 있습니다.</div></div>
    }, {
        category: '지역별 변화 추세를\n 간편하게',
        bkgndImage: country,
        className: styles.imgCountry,
        className_mb: styles.imgCountry_mb,
        description: '선택한 시군구의 인구와 세대수 변화 추이를 제공하여\n 미래 수요 변화를 확인 할 수 있습니다. \n다른 지역의 시군구로의 전입 전출 추이도 확인할 수 있습니다\n\n시장정보는 역세권, 법정동, 시군구 단위까지 제공됩니다.' ,
        description_el: <div><div>선택한 시군구의 인구와 세대수 변화 추이를 제공하여</div><div>미래 수요 변화를 확인 할 수 있습니다.</div><div>다른 지역의 시군구로의 전입 전출 추이도 확인할 수 있습니다.</div><div className={styles.subInfo}>시장정보는 역세권, 법정동, 시군구 단위까지 제공됩니다.</div></div>
    }];
    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    function moveToDown(index) {
        const id = "#villasise_" + (index+1);
        const location = document.querySelector(id).offsetTop + 618;
        window.scrollTo({top: location, behavior: 'smooth'})
    }

    if (isMobile) {
        return (
            <div className={styles.background_mb}>
                {itemsProps.map((el, index) => (<div id={"villasise_" + (index + 1)} key={index}>
                    <div className={styles.word_mb}>
                        <div className={styles.title_mb} >{el.category}</div>
                        <div className={styles.description_mb}>{el.description_el}</div>
                    </div>
                    <div className={styles.image_mb + " " + (index % 2 === 0 ? styles.item_odd_mb : styles.item_even_mb)}>
                        <img className={el.className_mb} src={el.bkgndImage} onError={(e) => e.target.src = `${noImg}`}/>
                    </div>
                    <div className={styles.back_mb}>
                        <div className={styles.num_mb}>0{index + 1} <span className={styles.numGrey}>/ 06</span></div>
                            <div className={styles.next_mb}>
                                {index == 5 ? <div className={styles.arrow_none}></div> :<img /*onClick={() => moveToDown(index)}*/ src={next} onError={(e) => e.target.src = `${noImg}`}/>}
                            </div>
                    </div>
                </div>))}
            </div>
        );
    }

    return(<div className={styles.bkgnd}>

            {itemsProps.map((el, index) => (<div id={"villasise_" + (index + 1)} key={index}>

                <div className={`${styles.item} ${index % 2 === 0 ? styles.item_odd : styles.item_even}`}>
                    <div className={styles.word}>
                        <div className={styles.title} >{el.category}</div>
                        <div className={styles.description}>{el.description}</div>
                    </div>
                    <div className={styles.image}>
                        <img className={el.className} src={el.bkgndImage} onError={(e) => e.target.src = `${noImg}`}/>
                    </div>
                </div>

                <div className={styles.back}>
                    <div className={styles.num}>0{index + 1} <span className={styles.numGrey}>/ 06</span></div>
                    <div className={styles.next}>
                        <img onClick={() => moveToDown(index)} src={next} onError={(e) => e.target.src = `${noImg}`}/>
                    </div>
                </div>

            </div>))}
    </div>);
}

export default Contact;

