import React, {useState, useEffect, useContext} from 'react';

import styled from 'styled-components';
import ContactsTableData from './ContactsTableData';
import Pager from './Pager';

const H3 = styled.h3`
font-size: 20px;
margin-top: 60px;
`
const Table = styled.table`
border-collapse: collapse;
border-spacing: 0;
width: 100%;
border: 1px solid #d2d2d2;
font-size: 16px;
white-space: nowrap;
table-layout: fixed;
`
const Thead = styled.thead`
border-top: 1px solid #d2d2d2 !important;
border-bottom: 1px solid #d2d2d2 !important;
`
const Th = styled.th`
padding: 8px 10px !important;
border-left: 1px solid #d2d2d2 !important;
border-right: 1px solid #d2d2d2 !important;
background-color: #F5F5F5 !important;
color: #000000 !important;
font-weight: bold !important;
text-align: center;
`

export default function ContactsTable(props) {

    return (
        <>
            <H3>전체 {props.totalElements}건</H3>
            <Table>
                <colgroup>
                    <col width="5%" />
                    <col width="50%" />
                    <col width="10%" />
                    <col width="15%" />
                    <col width="10%" />
                    <col width="*" />
                </colgroup>
                <Thead>
                    <tr>
                        <Th>No.</Th>
                        <Th>제목</Th>
                        <Th>작성자</Th>
                        <Th>email</Th>
                        <Th>등록일시</Th>
                        <Th>답변여부</Th>
                    </tr>
                </Thead>
                {props.hasDataList ? <ContactsTableData contacts={props.contacts} setScreenState={props.setScreenState} pageInfo={props.pageInfo}/> : <tbody></tbody>}
            </Table>
            <Pager pageInfo={{currentPageNumber: props.pageInfo.currentPageNumber, totalPages: props.pageInfo.totalPages}} setScreenState={props.setScreenState} />

        </>
    )
}