import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import arrow_hover_left from "../../static/assets/components/announcement/arrow_hover_left.png";
import arrow_left from "../../static/assets/components/announcement/arrow_left.png";
import arrow_hover_right from "../../static/assets/components/announcement/arrowt_hover_righ.png";
import arrow_right from "../../static/assets/components/announcement/arrow_right.png";
import '../../static/css/components/dataMap.css';
import noImg from "../../static/assets/utils/noimg.png";

class DataMap extends Component {

    state = {
        sideBar : true,
        hover : false,
        mapList : [
            {
                id : 0,
                selected : true,
                url : 'https://ai-developer.co.kr/osm_demo/seoul_subway',
                name : '서울 역세권 개발사업'
            },
            {
                id : 1,
                selected : false,
                url : 'https://ai-developer.co.kr/osm_demo/jeju',
                name : '공동주택(APT)'
            },
            {
                id : 2,
                selected : false,
                url : 'https://ai-developer.co.kr/osm_demo/ihousing',
                name : 'KTX역 주변 단독주택부지'
            },
            {
                id : 3,
                selected : false,
                url : 'https://ai-developer.co.kr/osm_demo/namwon',
                name : '남원 인허가가능'
            },
            {
                id : 4,
                selected : false,
                url : 'https://ai-developer.co.kr/osm_demo/select_dandok',
                name : '서울 단독주택부지'
            },
            {
                id : 5,
                selected : false,
                url : 'https://ai-developer.co.kr/osm_demo/logistic_warehouse',
                name : '물류창고'
            }
        ],
        isMobile : deviceDetect()
    }

    render() {
        const sideBar = this.state.mapList.map((map) => <li key={map.id} className={this.state.isMobile ? "sideBarData_mobile" : "sideBarData"}><div className={"dataHeaderName" + (map.selected ?  ' selected':'')}  onClick={() => this.selectChange(map.id)}>{map.name}</div></li>)
        const selected = this.state.mapList.filter((map) => map.selected)

        if (this.state.isMobile) {
            return (
              <div className="dataMap_mobile">
                  {/*<div className="sideBar_mobile">*/}
                  {/*    <div className={"sideBarBody_mobile" + (this.state.sideBar ? '':'-hide')}>*/}
                  {/*        <div className="sideBarMain_mobile">*/}
                  {/*            <div className="sideBarTitle_mobile">Data</div>*/}
                  {/*            <ul className="sideBarDataList_mobile">*/}
                  {/*                {sideBar}*/}
                  {/*            </ul>*/}
                  {/*        </div>*/}
                  {/*        <div className="contactBtn_mobile" spellCheck="false">*/}
                  {/*            <Link to="/about/contactUs">*/}
                  {/*                데이터 구매 문의하기*/}
                  {/*            </Link>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*    <div className="sideBarBtn"*/}
                  {/*         onClick={() => this.setState({sideBar : !this.state.sideBar})}*/}
                  {/*    >*/}
                  {/*        <img src={ this.state.sideBar ? arrow_left : arrow_right } alt="arrow" />*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <iframe title="dataMap" src={selected[0].url} id="iframeMap" className="hide"></iframe>
                  <div className="dataMap_announce">
                      <div>현재 Data Map 서비스는</div>
                      <div>웹에서만 지원하고 있습니다.</div>
                  </div>
              </div>
            );
        }

        return (
            <div className="dataMap">
                <div className="sideBar">
                    <div className={"sideBarBody" + (this.state.sideBar ? '':'-hide')}>
                        <div className="sideBarTitle">Data</div>
                        <ul className="sideBarDataList">
                            {sideBar}
                        </ul>
                        <Link to="/about/contactUs">
                            <div className="contactBtn" spellCheck="false">데이터 구매 문의하기</div>
                        </Link>
                    </div>
                    <div className="sideBarBtn"
                         onMouseEnter={() => this.setState({hover : true})}
                         onMouseLeave={() => this.setState({hover : false})}
                         onClick={() => this.setState({sideBar : !this.state.sideBar})}
                    >
                        <img src={ this.state.sideBar ?
                                this.state.hover ? arrow_hover_left : arrow_left :
                                this.state.hover ? arrow_hover_right : arrow_right
                             }
                             alt="arrow"
                             onError={(e) => e.target.src = `${noImg}`}
                        />
                    </div>
                </div>
                <iframe title="dataMap" src={selected[0].url} id="iframeMap" width="100%" height="100%"></iframe>
            </div>
        );
    }

    selectChange = (id) => {
        let size = this.state.mapList.length;
        let list = this.state.mapList;

        for (let i=0; i<size; i++) {
            if (list[i].selected) {
                list[i].selected = false;
                break;
            }
        }

        list[id].selected = true;

        this.setState({
            mapList: list,
            sideBar: false
        })
    };

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        let element = document.getElementById("iframeMap");
        element.onload = function() { /* console.log('loaded!'); */ };
        element.onerror = function() { /* console.log('error!'); */ };
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default DataMap;