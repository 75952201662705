import React from "react";
import {isMobile} from "react-device-detect";
import AilobigComments from "../../components/Solution/AiLobig/AilobigComments";
import AilobigProcess from "../../components/Solution/AiLobig/AilobigProcess";
import AilobigContents from "../../components/Solution/AiLobig/AilobigContents";
import AilobigServiceData from "../../components/Solution/AiLobig/AilobigServiceData";
import Content from "../../components/Solution/content";
import Comments from "../../components/Solution/comments";
import lobigImg from "../../static/assets/components/solutionContent/aiLobig/ailobig.png";
import ailobigProcess from "../../static/assets/components/solutionContent/aiLobig/ailobigProcess_mobile.png";
import avatar1 from "../../components/Solution/AiLobig/AilobigComments/assets/avatar1.png";
import avatar2 from "../../components/Solution/AiLobig/AilobigComments/assets/avatar2.png";
import avatar3 from "../../components/Solution/AiLobig/AilobigComments/assets/avatar3.png";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";

class AiLobig extends React.Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            const contentData = {
                id : 1,
                header : 'AI LOBIG',
                title : '신규 점포 개발 · AI 매출 예측 솔루션, AI 로빅',
                body : '▲신규 점포 개발  ▲AI 매출 예측 ▲권역별 마케팅 전략까지 한번에 AI 로빅 하나로 가맹점 매출 관리부터 마케팅 전략 수립, 신규 점포 개발, 이전에 관한 시뮬레이션까지 가능한 유통·금융권에서 유용하게 활용할 수 있는 데이터 기반의 AII-IN-ONE Solution',
                laptop : lobigImg
            };

            const commentData = {
                title : 'AI LOBIG',
                afterWord : '을',
                img : ailobigProcess,
                commentList : [
                    {
                        comment : <div className="commentCardBody">최근 아파트 인테리어 내역을 AI LOBIG이 학습하는데 1주일 밖에 걸리지 않았지만 그 결과는 <span className="text_highlight">7년이 넘은 전문가들이 내린 결론과 유사</span>했으며, 데이터를 통해 그 이유를 정확히 확인할 수 있어서 정말 놀라운 경험이었습니다.</div>,
                        avatar : avatar1,
                        by : '국내 1위 인테리어 기업 임원'
                    },
                    {
                        comment : <div className="commentCardBody">전국 모든 매장에 대해서 시장의 변화를 모니터링하고 코칭할 수 있습니다. 점포 개발 과정에서의 불확실성도 사라졌습니다. AI LOBIG은 30초면 전국 어디든 <span className="text_highlight">신규 점포의 예상 매출액을 정확히 예측합니다.</span></div>,
                        avatar : avatar2,
                        by : '국내 300개 이상의 프랜차이즈 기업 마케팅 팀장'
                    },
                    {
                        comment : <div className="commentCardBody">AI LOBIG은 저희 데이터와 공간 정보를 결합하여 학습하면서 <span className="text_highlight">폭발적인 인사이트를 제공</span>합니다. 소비자들이 어떻게 매장에 접근하고 이용하는지 처음 알게 되었습니다.</div>,
                        avatar : avatar3,
                        by : '국내 대형 할인마트 데이터 담당 팀장'
                    }
                ]
            };

            return (
              <div>
                  <Content data={contentData} />
                  <AilobigServiceData />
                  <AilobigProcess/>
                  <Comments data={commentData} />
              </div>
            );
        }

        return(
            <>
                <AilobigContents/>
                <AilobigServiceData />
                <AilobigProcess/>
                <AilobigComments/>
            </>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default AiLobig;