import React from "react";

import styles from './index.module.css';
import {CopyToClipboard} from "react-copy-to-clipboard";
import KakaoMap from "./kakaoMap";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";

class CompanyLocation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile : deviceDetect()
        }

        this.initMap = element => {
            const kakao = window.kakao;

            const position = new kakao.maps.LatLng(37.56374685249533, 126.97630169967772);

            this.map = new kakao.maps.Map(element, {
                center: position,
                level: 3,
                draggable: false,
            });

            const marker = new kakao.maps.Marker({
                // 지도 중심좌표에 마커를 생성합니다
                position: position
            });

            marker.setMap(this.map);

            const lwContent = this.state.isMobile ? '<div style="display: flex; justify-content: center; align-items: center; width: 40vw; height: 20vw; padding: 10px 0px; font-size: 3vw; cursor: pointer; text-align: center;" onclick="navigator.clipboard.writeText(`서울특별시 중구 서소문로 138 대한일보빌딩 12층`); alert(`클립보드에 복사되었습니다.`);">서울특별시 중구 서소문로 138 대한일보빌딩 12층</div>'
                                : '<div style="display: flex; justify-content: center; align-items: center; width: 340px; height: 40px; padding: 10px 0px; font-size: 14px; cursor: pointer; text-align: center;" onclick="navigator.clipboard.writeText(`서울특별시 중구 서소문로 138 대한일보빌딩 12층`); alert(`클립보드에 복사되었습니다.`);">서울특별시 중구 서소문로 138 대한일보빌딩 12층</div>';
            const lwPosition = new kakao.maps.LatLng(37.56374685249533, 126.97630169967772);

            const infoWindow = new kakao.maps.InfoWindow({
                position: lwPosition,
                content: lwContent
            });

            infoWindow.open(this.map, marker);
        };
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div>
                    <div>
                        <KakaoMap initMap={this.initMap} className={styles.map_mb} />
                    </div>
                </div>
            )
        }
        return (<div className={styles.bkgnd}>

            <div className={styles.content}>
                <KakaoMap initMap={this.initMap} className={styles.map} />
            </div>

        </div>);
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isMobile === false && this.state.isMobile === true) {
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default CompanyLocation;