import React, {Component} from 'react';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class AnnounceBoard extends Component {
    state = {
        bodyOpen : false,
        mouseOver : false,
        isMobile : deviceDetect()
    }

    render() {
        const { data } = this.props;

        if (this.state.isMobile) {
            return (
                <div className="announceBoard_mobile">
                    <div className="announceBoardSummary"
                         onClick={() => this.setState({bodyOpen : this.state.bodyOpen === false})}
                    >
                        <div className={"boardTitle" + (this.state.bodyOpen ? ' selected': this.state.mouseOver? ' mouseOn': '')}>{data.title}</div>
                        <div className="boardDate">{data.viewDate}</div>
                    </div>
                    <div className={"announceBody" + (this.state.bodyOpen ? '' : '-hide')}>
                        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                    </div>
                </div>
            )
        }

        return (
            <div className="announceBoard">
                <div className="announceBoardSummary"
                     onClick={() => this.setState({bodyOpen : this.state.bodyOpen === false})}
                     onMouseEnter={() => this.setState({mouseOver : true})}
                     onMouseLeave={() => this.setState({mouseOver : false})}
                >
                    <div className="boardIndex">{data.rowNum}</div>
                    <div className={"boardTitle" + (this.state.bodyOpen ? ' selected': this.state.mouseOver? ' mouseOn': '')}>{data.title}</div>
                    <div className="boardWriter">{data.writerName}</div>
                    <div className="boardDate">{data.viewDate}</div>
                </div>
                <div className={"announceBody" + (this.state.bodyOpen ? '' : '-hide')}>
                    <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default AnnounceBoard;