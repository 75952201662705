import React, {Component} from 'react';
import noPage from "../../static/assets/pages/pageNotFound/error.png";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import "../../static/css/pages/pageNotFound.css";

class PageNotFound extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        return (
            <div className={"pageNotFoundComponent" + (this.state.isMobile ? "_mobile": "")}>
                <div className={"pageNotFoundTitle" + (this.state.isMobile ? "_mobile": "")}>Not Page Found</div>
                <div className={"pageNotFoundBody" + (this.state.isMobile ? "_mobile": "")}>요청하신 페이지를 찾을 수 없습니다!</div>
                <div className={"pageNotFoundImg" + (this.state.isMobile ? "_mobile": "")}>
                    <img src={noPage}
                         srcSet={
                            `${require("../../static/assets/pages/pageNotFound/error@2x.png")}, 
                             ${require("../../static/assets/pages/pageNotFound/error@3x.png")}`
                         }
                         onError={(e) => e.target.src = require("../../static/assets/utils/noimg.png") }
                    />
                </div>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default PageNotFound;