import React, {useEffect, useState} from "react";

import styles from './index.module.css';
import ic1 from "./assets/차별점_img_1.png";
import ic2 from "./assets/차별점_img_2.png";
import ic3 from "./assets/차별점_img_3.png";
import ic4 from "./assets/차별점_img_4.png";

import { FaCircle } from 'react-icons/fa';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";

function Aideveloper(props) {

    const itemsProps = [{
        category: '데이터 최신화',

        iconImage: ic1,

        dataList: [
            '표준지, 개별 공시지가 등 공공데이터 개방 2주 이내 데이터 공급',
            '신축 단가 발표 후 2주 이내 데이터 공급',
            '실거래 개방 1일 이내 식별화 공급'
        ]
    }, {
        category: '사례지도 편집 시 높은 자율도',

        iconImage: ic2,

        dataList: [
            ' 드래그만으로 사례 지도 편집 기능'
        ]
    }, {
        category: '보고서 출력',

        iconImage: ic3,

        dataList: [
            '엑셀, PDF 등 다양한 포맷의 보고서 다운로드 '
        ]
    }, {
        category: '평가사 피드백 반영',

        iconImage: ic4,

        dataList: [
            '감정평가사들의 피드백을 반영해 전문가 편의에 맞춰진 최적의 보고서 작성 기능'
        ]
    }];
    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    if (isMobile) {
        return (
            <div className={styles.background_mb}>
                <div className={styles.top_mb}>
                    <div className={styles.title_mb}>Strength</div>
                    <div className={styles.head_mb}>V-Advisor의 경쟁력</div>
                </div>
                <div className={styles.items_mb}>
                    {itemsProps.map((el, index) => (
                        <div className={styles.item_mb} key={index}>
                            <img className={styles.item_image_mb} src={el.iconImage} onError={(e) => e.target.src = `${noImg}`}/>
                            <div className={styles.item_category_mb}>{el.category}</div>
                            <ul className={styles.item_description_group_mb}>
                            {el.dataList.map((el2, index2) => (
                                <li className={styles.item_description_mb} key={index2}>{el2}</li>
                            ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (<div className={styles.bkgnd} >

        <div className={styles.content}>
            <div className={styles.top}>
                <div className={styles.title}>Strength</div>
                <div className={styles.head}>V-Advisor의 경쟁력</div>
            </div>

            <div className={styles.items}>

            {itemsProps.map((el, index) => (

                <div className={styles.item} key={index}>

                    <img className={styles.item_image} src={el.iconImage} onError={(e) => e.target.src = `${noImg}`}/>

                    <div className={styles.item_category}>{el.category}</div>
                    <ul className={styles.item_description_group}>
                    {el.dataList.map((el2, index2) => (
                        <li className={styles.item_description} key={index2}>
                            {el2}
                        </li>
                    ))}
                    </ul>
                </div>

            ))}

            </div>
        </div>

    </div>);

}

export default Aideveloper;