import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { useRefreshCounterState } from './AdminGlobalStore';


const DivSideMenu = styled.div`
    font-size: 20px;
    border-right: 1px solid #e5e5e5;
    padding: 20px;
    width: 300px;
`;

const Ul = styled.ul`
    list-style: square;
`;

const Ul2 = styled.ul`
`;

const Li = styled.li`
    padding: 0 0 15px 0;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

function AdminSideMenu() {
    const navigation = useNavigate();
    const [refreshCounter, setRefreshCounter] = useRefreshCounterState()
    const location = useLocation();
    //const location = useLocation();

    function goMenu(menu, e) {
        if (e.target.classList.contains("selected")) return;
        setRefreshCounter(refreshCounter + 1);
        navigation('/' + menu);
        const siblings = window.document.querySelectorAll('ul>li');
        for (let i=0; i<siblings.length; i++) {
            if(siblings[i].id === menu) {
                siblings[i].className += " selected";
            } else {
                siblings[i].classList.remove("selected");
            }
        }
    }

    useEffect(() => {
        const siblings = window.document.querySelectorAll('ul>li');
        const path = location.pathname;
        for (let i=0; i<siblings.length; i++) {
            if(path === "/" + siblings[i].id) {
                siblings[i].className += " selected";
            } else {
                siblings[i].classList.remove("selected");
            }
        }
    }, [useLocation().pathname]);
    
    return (
        <DivSideMenu>
            <section>
                <Ul>
                    <Li id="notice" onClick={(e) => goMenu('notice', e)}>공지사항</Li>
                    <Li id="resources" onClick={(e) => goMenu('resources', e)}>Resources</Li>
                    <Ul2>
                        <Li id="resources3" onClick={(e) => goMenu('resources3', e)}>Press Release</Li>
                        <Li id="resources4" onClick={(e) => goMenu('resources4', e)}>Media</Li>
                        <Li id="resources6" onClick={(e) => goMenu('resources6', e)}>BV Content</Li>
                    </Ul2>
                    <Li id="recruit" onClick={(e) => goMenu('recruit', e)}>채용공고</Li>
                    <Li id="history" onClick={(e) => goMenu('history', e)}>연혁</Li>
                    <Li id="contact" onClick={(e) => goMenu('contact', e)}>문의사항</Li>
                </Ul>
            </section>
        </DivSideMenu>
    )
}

export default AdminSideMenu;