import React from "react";

import styles from './index.module.css';

import VAdvisorLaptop from './assets/VAdvisorLaptop.png';
import noImg from "../../../../static/assets/utils/noimg.png";


class Vadvisor extends React.Component {

    render() {
        const link = (url) => {

            window.location = url;
        }
        return (<div className={styles.bkgnd}>

            <div className={styles.content}>

                <div className={styles.left}>
                    <div className={styles.title}>V-Advisor</div>
                    <div className={styles.head}> <span className={styles.head_highlight}>토지·건축물 매입 시가 산정 솔루션 V-Advisor </span></div>
                    <div className={styles.description}>
                        ▲토지 기본 정보(지목·토지면적·공시 지가·토지이용현황 등)
                        <br/>▲건축물 기본 정보(표제부·인허가상 건물 정보·대지면적·건축면적·연면적 등)를
                        <br/>한 눈에 확인은 물론 AI 토지·건축물 시가 추정까지 한 번에 가능한 AI 시가 산정 솔루션입니다. <br/>
                        <br/>1일 단위 업데이트로 최신 정보 기반 부동산 평가서를 단 5분만에 받아 볼 수 있습니다.  </div>
                    <button className={styles.button} onClick={()=>link('/about/contactUs')}>문의하기</button>

                </div>
                <div className={styles.desktop}>
                    <img className={styles.laptop} src={VAdvisorLaptop} onError={(e) => e.target.src = `${noImg}`}/>
                </div>

            </div>

        </div>);
    }
}

export default Vadvisor;