
import ic1 from "./assets/ic_토지지적.png";
import ic2 from "./assets/ic_계획인허가.png";
import ic3 from "./assets/ic_분양가실거래.png";
import ic4 from "./assets/ic_구상미래.png";
import ic5 from "./assets/ic_비용정보.png";
import createServiceData from "../../ServiceData";



const AideveloperServiceData = createServiceData({

    //type: 'ailobig',
    title: 'AI Developer',
    afterWord: '가',
    
    itemsProps: [{
        category: '토지',

        iconImage: ic1,

        dataList: [
            '지목',
            '소유구분',
            '지형고저',
            '지형형상',
            '도로접면'
        ]
    }, {
        category: '비용정보',

        iconImage: ic4,

        dataList: [
            '단지조성공사비',
            '건축공사비 단가',
            '부대비용 적용비율'
        ]
    }, {
        category: '분양가',

        iconImage: ic3,

        dataList: [
            'AI 추정 분양 수익',
            'AI 추정 분양가',
            '시세 및 분양 현황',
            '전출입지역'
        ]
    }, {
        category: '사업비',

        iconImage: ic5,

        dataList: [
            '개략사업성',
            '추정 사업비',
            '지목 별 매입비',
            '필지 별 토지 매입가'
        ]
    }, {
        category: '인허가',

        iconImage: ic2,

        dataList: [
            '인허가지표',
            '계획인구',
            '생활권',
            '용도지역·지구·구역·기타'
        ]
    }]
});

export default AideveloperServiceData;