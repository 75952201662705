import React, {Component} from 'react';
import GeoDataAIShape from "./GeoDataAIShape";
import companyIntroduction from "../../../../static/assets/components/aboutGeoDataAI/companyIntroduction.pdf";
import '../../../../static/css/components/AboutGeoDataAI.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class GeoDataAI extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div className="geoDataAI_mobile">
                    <div className="geoDataHeader_mobile">국내 최초 공간AI 플랫폼</div>
                    <div className="geoDataTitle_mobile">Geospatial Data for AI</div>
{/*
                    <a className="geoDataBtn_mobile" href={companyIntroduction} download="빅밸류 회사 소개서.pdf">
                        회사 소개서
                    </a>
*/}
                    <GeoDataAIShape></GeoDataAIShape>
                </div>
            )
        }
        return (
            <div className="geoDataAI">
                <div className="geoDataHeader">국내 최초 공간AI 플랫폼</div>
                <div className="geoDataTitleGroup">
                    <div className="geoDataTitle">Geospatial Data for AI</div>
{/*
                    <a className="geoDataBtn" href={companyIntroduction} download="빅밸류 회사 소개서.pdf">
                        회사 소개서
                    </a>
*/}
                </div>
                <GeoDataAIShape></GeoDataAIShape>
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default GeoDataAI;