import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {useLocation, useNavigate} from "react-router-dom";

const Div = styled.div`
    margin-top: 20px;
    text-align: center;
`

const Button = styled.button`
    margin-right: 10px;
    border: 1px solid black;
    color: black;
    font-size: 14px !important;
    height: 30px;
    cursor: pointer;
    padding: 10px 20px;
    display: inline-block;
    box-sizing: border-box;
    width: 140px;
    &:hover {
        text-decoration: ${props => props.disabled ? 'none' : 'underline'};
    }
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    background-color: ${props => props.disabled ? '#d2d2d2' : '#ffffff'};
`
export default function Pager({ pageInfo, setScreenState }) {

    const navigation = useNavigate();
    const location = useLocation();

    function setPageNum(pageNum) {
        navigation(location.pathname, {state : pageNum.toString()});
    }

    // console.log('pageInfo', pageInfo);
  return (
    <Div>
        <div>
            <Button disabled={pageInfo.currentPageNumber > 0 ? false : true} onClick={() => setPageNum(0)}>첫 페이지</Button>
            <Button disabled={pageInfo.currentPageNumber > 0 ? false : true} onClick={() => setPageNum(pageInfo.currentPageNumber - 1)}>이전 페이지</Button>
            <Button disabled={pageInfo.currentPageNumber !== (pageInfo.totalPages - 1) ? false : true} onClick={() => setPageNum(pageInfo.currentPageNumber + 1)}>다음 페이지</Button>
            <Button disabled={pageInfo.currentPageNumber !== (pageInfo.totalPages - 1) ? false : true} onClick={() => setPageNum(pageInfo.totalPages - 1)}>마지막 페이지</Button>
        </div>
    </Div>
  );
}