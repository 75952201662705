import React, {Component} from 'react';
import btnLeft_hover from "../../../../static/assets/components/carousel/arrow_left_hover.png";
import btnLeft from "../../../../static/assets/components/carousel/arrow_left.png";
import btnRight from "../../../../static/assets/components/carousel/arrow_right.png";
import btnRight_hover from "../../../../static/assets/components/carousel/arrow_right_hover.png";
import CarouselCard from "./carouselCard";
import '../../../../static/css/components/carousel.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../../static/assets/utils/noimg.png";

class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            num : 1,
            cardData : [],
            showNums : [],
            leftOver : false,
            rightOver : false,
            isMobile : deviceDetect()
        };
    }

    render() {
        const list = this.state.cardData.map((data) => <li className="carouselIndex" key={data.id}>{data.body}</li>)
        const cardList = this.state.showNums.map((num) => <CarouselCard key={num} data={this.state.cardData[num]} />)

        if (this.state.isMobile) {
            return (
              <div className={"carouselComponent_mobile" + (this.props.isLeft ? "-left":"")}>
                  <div className="carouselMain_mobile">
                      <div className="cardList">
                          {cardList}
                      </div>
                      <div className="btnGroup_mobile">
                          <div onClick={() => this.changeNum(-1)}>
                              <img src={btnLeft} alt="arrow_left" onError={(e) => e.target.src = `${noImg}`}/>
                          </div>
                          <div onClick={() => this.changeNum(1)}>
                              <img src={btnRight} alt="arrow_right" onError={(e) => e.target.src = `${noImg}`}/>
                          </div>
                      </div>
                      {this.props.children}
                      <div className="carouselText_mobile">
                          <ul>
                              {list}
                          </ul>
                      </div>
                  </div>
              </div>
            );
        }

        return (
            <div className={"carouselComponent" + (this.props.isLeft ? "-left":"")}>
                <div className="carouselFlex">
                    <div className="carouselIntro">
                        {this.props.children}
                        <div>
                            <ul className="carouselIndexGroup">
                                {list}
                            </ul>
                        </div>
                    </div>
                    <div className="carouselMain">
                        <div className="cardList">
                            {cardList}
                        </div>
                        <div className="btnGroup">
                            <div onClick={() => this.changeNum(-1)}>
                                <img
                                    onMouseOver={e => (e.currentTarget.src = btnLeft_hover)}
                                    onMouseOut={e => (e.currentTarget.src = btnLeft)}
                                    src={btnLeft}
                                    alt="arrow_left"
                                    onError={(e) => e.target.src = `${noImg}`}
                                />
                            </div>
                            <div onClick={() => this.changeNum(1)}>
                                <img
                                    onMouseOver={e => (e.currentTarget.src = btnRight_hover)}
                                    onMouseOut={e => (e.currentTarget.src = btnRight)}
                                    src={btnRight}
                                    alt="arrow_right"
                                    onError={(e) => e.target.src = `${noImg}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeNum = (dir) => {
        let num = this.state.num + dir;
        num = num === -1 ? this.state.cardData.length - 1 : num === this.state.cardData.length ? 0 : num;
        let pre = num === 0 ? this.state.cardData.length - 1 : num - 1;
        let center = num;
        let post = num === this.state.cardData.length - 1 ? 0 : num + 1;

        if (this.state.isMobile) {
            this.setState({
                num : num,
                showNums : [pre, center]
            });
        } else {
            this.setState({
                num : num,
                showNums : [pre, center, post]
            });
        }
    }

    getCardData = () => {
        const companyData = [
            {
                id : 0,
                img : require("../../../../static/assets/components/carousel/ic_우수사원포상.png"),
                body : '우수사원 포상 및 포상금 지급'
            },
            {
                id : 1,
                img : require("../../../../static/assets/components/carousel/ic_리프레쉬.png"),
                body : '장기근속 리프레쉬 휴가, 휴가비 지원'
            },
            {
                id : 2,
                img : require("../../../../static/assets/components/carousel/ic_워크샵.png"),
                body : '워크숍 세미나 개최'
            },
            {
                id : 3,
                img : require("../../../../static/assets/components/carousel/ic_반반차.png"),
                body : '반반 차 시행'
            },
            {
                id : 4,
                img : require("../../../../static/assets/components/carousel/ic_퇴직금.png"),
                body : '퇴직연금 운용'
            },
            {
                id : 5,
                img : require("../../../../static/assets/components/carousel/ic_출퇴근유연제.png"),
                body : '출퇴근 유연제 시행'
            }
        ];
        const personalData = [
            {
                id : 0,
                img : require("../../../../static/assets/components/carousel/ic_건강검진.png"),
                body : '건강검진비 지원'
            },
            {
                id : 1,
                img : require("../../../../static/assets/components/carousel/ic_명절.png"),
                body : '명절 선물'
            },
            {
                id : 2,
                img : require("../../../../static/assets/components/carousel/ic_경조사.png"),
                body : '경조사 지원 (경조금, 화환)'
            },
            {
                id : 3,
                img : require("../../../../static/assets/components/carousel/ic_식대.png"),
                body : '점심 식사 지원'
            },
            {
                id : 4,
                img : require("../../../../static/assets/components/carousel/ic_야근식비.png"),
                body : '야근 시 식대/교통비 지원'
            },
            /*
            {
                id : 0,
                img : require("../../../../static/assets/components/carousel/ic_생일.png"),
                body : '매월 생일파티 및 생일선물 제공'
            },
            {
                id : 5,
                img : require("../../../../static/assets/components/carousel/ic_간식.png"),
                body : '스낵바 무제한 간식 제공'
            },
            {
                id : 6,
                img : require("../../../../static/assets/components/carousel/ic_커피.png"),
                body : '커피 쿠폰 및 커피 머신 제공'
            }*/
        ];
        const choose = this.props.isLeft;

        if (this.state.isMobile) {
            this.setState({
                num : 1,
                cardData : choose ? companyData : personalData,
                showNums : [0, 1],
                leftOver : false,
                rightOver : false
            });
        } else {
            this.setState({
                num : 1,
                cardData : choose ? companyData : personalData,
                showNums : [0, 1, 2],
                leftOver : false,
                rightOver : false
            });
        }
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.getCardData();
        this.oneMinuteCall = setInterval(() => this.changeNum(1), 3000);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Carousel;