import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";
import Carousel from "../../components/About/Careers/Carousel/carousel";
import Process from "../../components/About/Careers/Process/process";
import topImg from "../../static/assets/pages/career/Careers_top_img.png"
import '../../static/css/pages/career.css';
import Recruit from "../../components/About/Careers/Recruit/recruit";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import noImg from "../../static/assets/utils/noimg.png";

const Career = () => {

    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    if (isMobile) {
        return (
            <div className="career_mobile">
                <div className="careerHeader_mobile">
                    <div className="careerTitle_mobile">
                        <div className="careerIntro">Careers</div>
                        <div className="careerBody"><span className="bold">전문성</span>과 <span className="bold">다양성</span>이 공존하는</div>
                        <div className="careerBody">빅밸류와 <span className="bold">함께 성장할 인재</span>를 기다립니다</div>
                    </div>
                    <div className="careerImg_mobile">
                        <img src={topImg} alt="topImg" onError={(e) => e.target.src = `${noImg}`}/>
                    </div>
                </div>
                <Carousel isLeft={true} >
                    <div className="carouselTitle_mobile">개인과 회사의 균형을 중시합니다.</div>
                </Carousel>
                <Carousel isLeft={false}>
                    <div className="carouselTitle_mobile">사람을 중요하게 생각합니다.</div>
                </Carousel>
                <Process />
                {/*<Recruit />*/}
            </div>
        )
    }

    return (
        <div className="career">
            <div className="careerHeader">
                <div className="careerTitle">
                    <div className="careerIntro">Careers</div>
                    <div className="careerBody"><span className="bold">전문성</span>과 <span className="bold">다양성</span>이 공존하는</div>
                    <div className="careerBody">빅밸류와 <span className="bold">함께 성장할 인재</span>를 기다립니다</div>
                    <div className="careerBtn" onClick={moveScroll}>지원하기</div>
                </div>
                <div className="careerImg">
                    <img src={topImg} alt="topImg" onError={(e) => e.target.src = `${noImg}`}/>
                </div>
            </div>
            <Carousel isLeft={true} >
                <div className="carouselTitle">개인과 회사의 균형을 중시합니다.</div>
            </Carousel>
            <Carousel isLeft={false}>
                <div className="carouselTitle">사람을 중요하게 생각합니다.</div>
            </Carousel>
            <Process />
            {/*<Recruit />*/}
        </div>
    );


    function moveScroll() {
        // let location = document.querySelector(".recruitComponent").offsetTop;
        // window.scrollTo({top: location, behavior: 'smooth'})
        window.open("https://www.saramin.co.kr/zf_user/search?search_area=main&search_done=y&search_option[…]=search&searchword=%EB%B9%85%EB%B0%B8%EB%A5%98&loc_cd=101090");
    }
}

export default Career;