import React, {Component} from 'react';
import {Link} from "react-router-dom";
import background from '../../static/assets/components/solutionContent/labtop.png';
import '../../static/css/components/solutionContent.css';
import noImg from "../../static/assets/utils/noimg.png";

class Content extends Component {
    render() {
        const {id, header, title, body, laptop} = this.props.data;
        const btnWord = (id == 3) ? '문의하기' : '문의하기 및 소개서 받기';
        return (
            <div className="solutionContent">
                    <div className="contentHeader">{header}</div>
                    <div className="contentTitle">{title}</div>
                    <div className="contentBody">{body}</div>
                {
                    id == 4 ?
                        <div className="btnGroup">
                            <Link to="/about/contactUs"><div className="contentBtn">{btnWord}</div></Link>
                            {/*<div className="contentBtn" onClick={() => this.windowOpen()}>빌라시세닷컴 바로가기</div>*/}
                        </div> :
                        <Link to="/about/contactUs"><div className="contentBtn">{btnWord}</div></Link>
                }
                    <div className="contentLaptop">
                        <img className="background" src={background} alt="background" onError={(e) => e.target.src = `${noImg}`}/>
                        <img className="mainImg" src={laptop} alt="solutionImg" onError={(e) => e.target.src = `${noImg}`}/>
                    </div>1
            </div>
        );
    }

    windowOpen = () => {
        window.open("https://www.villasise.com")
    }
}

export default Content;