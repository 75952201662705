import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {RiCheckboxBlankCircleFill} from "react-icons/ri";
import arrow_down from "./assets/arrow_open_l.svg";
import arrow_up from "./assets/arrow_folding_l.svg";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import noImg from "../../static/assets/utils/noimg.png";

class HeaderCategoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: '',
      isMouseOver: false,
      isOpen: false,
      listOpen: false,
      childrenActive: false,
      url: '/',
      categoryList: [],
      isMobile: deviceDetect()
    }
  }

  render() {
    // const result = this.state.categoryList.map((subCategory) => <div className="subCategory" key={subCategory.name} ><NavLink className={({ isActive }) => (isActive ? "selected": "")} to={subCategory.url} end>{subCategory.name}</NavLink></div>);
    const result = this.state.categoryList.map((subCategory) =>
      (subCategory.url.indexOf("http") === -1) ?
        <NavLink key={subCategory.name}
                 className={({isActive}) => (isActive ? "selected_child" : "")}
                 to={subCategory.url} end>
          <div className="subCategory">{subCategory.name}</div>
        </NavLink>
        :
        <a target="_blank" href={subCategory.url}>
          <div className="subCategory">{subCategory.name}</div>
        </a>
    );

    if (this.state.isMobile) {
      const result_mobile = this.state.categoryList.map((subCategory) =>
        (subCategory.url.indexOf("http") === -1) ?
          <NavLink key={subCategory.name}
                   className={({isActive}) => (isActive
                     ? "selected_child" : "")}
                   to={subCategory.url}
                   onClick={() => this.props.click(false)} end>
            <li className="subCategory">{subCategory.name}</li>
          </NavLink>
          :
          <a target="_blank" href={subCategory.url}>
            <li className="subCategory">{subCategory.name}</li>
          </a>
      );

      return (
        <div className={"categoryList_mobile"}>
          <div className="categoryListHeader_mobile"
               onClick={() => this.setState({isOpen: !this.state.isOpen})}>
            <div className={this.state.childrenActive ? "selected" : ""}>
              {this.state.category}
            </div>
            <div className="categoryListHeaderArrow">
              <img src={this.state.isOpen ? arrow_up : arrow_down} alt="arrow"
                   onError={(e) => e.target.src = `${noImg}`}/>
            </div>
          </div>
          <ul className={'categoryListBody_mobile' + (this.state.isOpen ? ''
            : '-hide')}>
            {result_mobile}
          </ul>
        </div>
      );
    }

    return (
      <div className="categoryList"
           onMouseEnter={this.mouseOverOn}
           onMouseLeave={this.mouseOverOff}
           onClick={this.getCategoryList}
      >
        <div className="categoryListHeader">
          <NavLink className={({isActive}) => (isActive ? "selected" : "")}
                   to={this.state.url}>
            {this.state.category}
            <div className='selected_category_circle'>
              <RiCheckboxBlankCircleFill size='5'/>
            </div>
          </NavLink>
        </div>
        <div className={'categoryListBody' + (this.state.isMouseOver ? ''
          : '-hide')}>
          {result}
        </div>
      </div>
    );
  }

  mouseOverOn = () => {
    this.setState({
      isMouseOver: true
    });
  };

  mouseOverOff = () => {
    this.setState({
      isMouseOver: false
    });
  };

  getCategoryList = () => {

    const cur_category = {
      category: this.props.value,
      url: '',
      size: 0,
      categoryList: []
    };

    if (cur_category.category === 'About us') {
      cur_category.url = '/about';
      cur_category.categoryList = [
        {
          name: 'About us',
          url: '/about'
        },
        {
          name: 'Vision',
          url: '/about/vision'
        },
        {
          name: 'Careers',
          url: 'https://recruit.bigvalue.co.kr/'
        },
        {
          name: 'Contact us',
          url: '/about/contactUs'
        }
      ]
    } else if (this.props.value === 'Resources') {
      cur_category.url = '/resources';
      cur_category.categoryList = [
        // {
        //     name : 'Seminar/Webinar',
        //     url : '/resources/sw'
        // },
        {
          name: 'Press Release',
          url: '/resources/pr'
        },
        {
          name: 'BV Contents',
          url: '/resources/bv'
        },
        {
          name: 'Media',
          url: '/resources/media'
        },
        // {
        //     name : 'Case Studies',
        //     url : '/resources/cs'
        // },
      ]
      if (this.state.isMobile) {
        cur_category.categoryList.unshift({
          name: 'All',
          url: '/resources'
        })
      }
    } else if (this.props.value === 'Solution') {
      cur_category.url = '/solution';
      cur_category.categoryList = [
        {
          name: 'AI LOBIG',
          url: '/solution/al'
        },
        {
          name: 'AI Developer',
          url: '/solution/ad'
        },
        {
          name: 'V-Advisor',
          url: '/solution/va'
        },
        {
          name: 'Villasise.com',
          url: '/solution/vi'
        }
      ]
    } else {
      cur_category.url = '/dataMap';
      cur_category.categoryList = [
        {
          name: 'Data Map',
          url: '/dataMap'
        }
      ]
    }

    let hasActive = false;
    if (cur_category.url !== '' && window.location.href.includes(
      cur_category.url)) {
      for (let i = 0; i < cur_category.categoryList.length; i++) {
        if (window.location.href.includes(cur_category.categoryList[i].url)) {
          hasActive = true;
        }
      }
    }

    let isOpen = this.props.value.open !== undefined ? this.props.value.open
      : false;

    this.setState({
      category: cur_category.category,
      url: cur_category.url,
      isMouseOver: false,
      listOpen: isOpen,
      childrenActive: hasActive,
      categoryList: cur_category.categoryList
    });
  }

  windowResize = () => {
    const res = deviceDetect();

    this.setState({
      isMobile: res
    })
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.windowResize);
    this.getCategoryList();
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.windowResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        listOpen: this.props.isOpen
      })
    }
  }
}

export default HeaderCategoryList;