import React, {Component} from 'react';
import HistoryMonth from "./HistoryMonth";
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class HistoryMonthList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile : deviceDetect()
        }
    }
    render() {
        const { monthData, isLeft} = this.props;
        const month = monthData.datas.map((data, index) => <HistoryMonth key={index} data={data} />)

        if (this.state.isMobile) {
            return (
                <div className="monthFlex_mobile">
                    <div className="monthSign-div">
                        <div className="monthSign_mobile"></div>
                        <div className="history-month_mobile">{this.paddingNum(monthData.month)}월</div>
                    </div>
                    <div className="monthBody_mobile">
                        {month}
                    </div>
                </div>
            );
        }

        return (
            <div className={"monthFlex-" + (isLeft ? 'left':'right')}
                 >
                <div className={'month-' + (isLeft ? 'left':'right')}>
                    <div className="monthBody">
                        {month}
                    </div>
                    <div className="history-month">{this.paddingNum(monthData.month)}월</div>
                    <div>
                        <div className="monthSign"></div>
                    </div>
                </div>
                <div className="monthOpposite"></div>
            </div>
        );
    }

    paddingNum = (mon) => {
        return String(mon).padStart(2, '0');
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default HistoryMonthList;