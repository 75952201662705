import React, {Component} from 'react';

class ProcessCircle extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const id = this.props.data.id;

        return (
            <div className={(id === 0 ? "white" : "opacity") + "-circle"}>
                <div className="circleNum">{this.paddingNum(id)}</div>
                <div className="circleBody">
                    <div>{this.props.data.body}</div>
                    <div>{this.props.data.sub}</div>
                </div>
            </div>
        );
    }

    paddingNum = (mon) => {
        return String(mon).padStart(2, '0');
    }
}

export default ProcessCircle;