import {Component} from 'react';
import SpectrumMain from "./SpectrumMain";
import '../../../static/css/components/Spectrum.css';
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class Spectrum extends Component {

  state = {
    isMobile: deviceDetect()
  }

  render() {
    if (this.state.isMobile) {
      return (
        <div className="spectrumComponent_mobile">
          <div className="spectrumTitle">
            <div className="title">BIGVALUE <div>Data Spectrum</div></div>
            <div className="subTitle">
              <div>빅밸류는 고객에게</div>
              <div>최고의 가치를 제공하고 있습니다.</div>
            </div>
          </div>
          <SpectrumMain/>
        </div>
      );
    }
    return (
      <div className="spectrumComponent">
        <div className="spectrumTitle">
          <div className="title">BIGVALUE <span>Data Spectrum</span></div>
          <div className="subTitle">
            빅밸류는 고객에게 최고의 가치를 제공하고 있습니다.
          </div>
        </div>
        <SpectrumMain/>
      </div>
    );
  }

  windowResize = () => {
    const res = deviceDetect();

    this.setState({
      isMobile: res
    })
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.windowResize);
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.windowResize);
  }
}

export default Spectrum;
