import {Component} from 'react';
import SpectrumCenter from "./spectrumCenter";
import SpectrumTheme from "./spectrumTheme";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";

class SpectrumMain extends Component {
  state = {
    spectrumList: [],
    isMobile: deviceDetect()
  }

  render() {
    const spectrumTheme = this.state.spectrumList.map((theme) => <SpectrumTheme key={theme.id} data={theme}/>)

    if (this.state.isMobile) {
      return (
        <div className="spectrumMain_mobile">
          <div className="spectrumThemeGrid_mobile">
            {spectrumTheme}
          </div>
          <SpectrumCenter/>
        </div>
      );
    }
    return (
      <div className="spectrumMain">
        <div className="spectrumThemeGrid">
          {spectrumTheme}
        </div>
        <SpectrumCenter/>
      </div>
    );
  }

  getSpectrumList = () => {
    const list = [
      {
        id: 0,
        mobile_id: 6,
        element_id: 'mediation',
        dir: 'leftTop',
        title: '중개',
        icon: require('../../../static/assets/components/spectrum/icon/ic_mediation.png'),
        logos: [
          require('../../../static/assets/components/spectrum/logo/mediation/logo_won.png')
        ],
        url: [
          '/resources/bv/169'
        ]
      },
      {
        id: 1,
        mobile_id: 4,
        element_id: 'construct',
        dir: 'leftCenter',
        title: '건설',
        icon: require('../../../static/assets/components/spectrum/icon/ic_construction.png'),
        logos: [
          // require('../../../static/assets/components/spectrum/logo/construction/logo_DL.png'),
          require('../../../static/assets/components/spectrum/logo/construction/logo_skecoplant.png')
        ],
        url: [
          // '/solution/ad',
          '/solution/ad'
        ]
      },
      {
        id: 2,
        mobile_id: 2,
        element_id: 'circulation',
        dir: 'leftBottom',
        title: '유통',
        icon: require('../../../static/assets/components/spectrum/icon/ic_circulation.png'),
        logos: [
          require('../../../static/assets/components/spectrum/logo/circulation/logo_lpoint.png')
        ],
        url: [
          '/resources/bv/168'
        ]
      },
      {
        id: 3,
        mobile_id: 3,
        // mobile_id: 1,
        element_id: 'finance',
        dir: 'rightBottom',
        // dir: 'topMiddle',
        title: '금융',
        icon: require('../../../static/assets/components/spectrum/icon/ic_finance.png'),
        logos: [
          require('../../../static/assets/components/spectrum/logo/finance/logo_hana.png'),
          require('../../../static/assets/components/spectrum/logo/finance/logo_shinhan.png'),
          require('../../../static/assets/components/spectrum/logo/finance/logo_NH.png'),
          require('../../../static/assets/components/spectrum/logo/finance/logo_sbi.jpg'),
          require('../../../static/assets/components/spectrum/logo/finance/logo_DGB.jpg'),
          // require('../../../static/assets/components/spectrum/logo/finance/logo_papper.png'),
          require('../../../static/assets/components/spectrum/logo/finance/logo_welcome.png')
        ],
        url: [
          '/solution/va',
          '/resources/bv/169',
          '/resources/bv/178',
          '/resources/bv/169',
          '/solution/vi',
          // '/solution/vi',
          '/resources/bv/169'
        ]
      },
      {
        id: 4,
        mobile_id: 5,
        element_id: 'proptech',
        dir: 'rightTop',
        title: '프롭테크',
        icon: require('../../../static/assets/components/spectrum/icon/ic_proptech.png'),
        logos: [
          require('../../../static/assets/components/spectrum/logo/proptech/logo_homes.png'),
          require('../../../static/assets/components/spectrum/logo/proptech/logo_trustay.png'),
          require('../../../static/assets/components/spectrum/logo/proptech/logo_refine.png'),
          require('../../../static/assets/components/spectrum/logo/proptech/logo_banksalad.png')
        ],
        url: [
          '/solution/ad',
          '/solution/ad',
          '',
          '/solution/vi'
        ]
      },
      {
        id: 5,
        mobile_id: 7,
        element_id: 'public',
        dir: 'rightCenter',
        title: '공공기관',
        icon: require('../../../static/assets/components/spectrum/icon/ic_publicIn.svg').default,
        logos: [
          require('../../../static/assets/components/spectrum/logo/publicIn/logo_disease.png'),
          require('../../../static/assets/components/spectrum/logo/publicIn/logo_seoul.png'),
          require('../../../static/assets/components/spectrum/logo/publicIn/logo_qurantine.png'),
          require('../../../static/assets/components/spectrum/logo/publicIn/logo_transport.png'),
          require('../../../static/assets/components/spectrum/logo/publicIn/logo_KAIA.png')
        ],
        url: [
          '/solution/ad',
          '/solution/ad',
          '/resources/bv/180',
          '/solution/ad',
          '/solution/ad'
        ]
      },
      /*{
        id: 6,
        mobile_id: 3,
        element_id: '',
        dir: 'rightBottom',
        title: '프랜차이즈',
        icon: require('../../../static/assets/components/spectrum/icon/ic_franchise.png'),
        logos: [
          require('../../../static/assets/components/spectrum/logo/franchise/logo_alvolo.png')
        ],
        url: [
          '/resources/bv/168'
        ]
      },*/
    ];

    this.setState({
      spectrumList: list
    })
  }

  windowResize = () => {
    const res = deviceDetect();

    this.setState({
      isMobile: res
    })
  }

  componentDidMount() {
    window.addEventListener(`resize`, this.windowResize);
    this.getSpectrumList();
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.windowResize);
  }
}

export default SpectrumMain;
