import React from "react";

import styles from './index.module.css';

import ailobigLaptop from './assets/macbookPro.png';
import noImg from "../../../../static/assets/utils/noimg.png";


class Ailobig extends React.Component {

    render() {
        const link = (url) => {

            window.location = url;
        }
        return (<div className={styles.bkgnd}>

            <div className={styles.content}>
                <div className={styles.title}>AI LOBIG</div>
                <div className={styles.head_highlight}>신규 점포 개발 · AI 매출 예측 솔루션, AI 로빅</div>
                <div className={styles.description}>▲신규 점포 개발  ▲AI 매출 예측 ▲권역별 마케팅 전략까지 한번에
                    AI 로빅 하나로 가맹점 매출 관리부터 마케팅 전략 수립, 신규 점포 개발, 이전에 관한 시뮬레이션까지 가능한
                    유통·금융권에서 유용하게 활용할 수 있는 데이터 기반의 AII-IN-ONE Solution
                </div>
                <button className={styles.button} onClick={()=>link('/about/contactUs')}>문의하기 및 소개서 받기</button>
                <div className={styles.desktop}>
                    <img className={styles.laptop} src={ailobigLaptop} onError={(e) => e.target.src = `${noImg}`}/>
                </div>


            </div>

        </div>);
    }
}

export default Ailobig;