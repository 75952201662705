import React from 'react';

function MobileArticleHeader({select, selectCategory}) {

    const categoryIds = [3, 6, 4];
    const getCategoryName = function (id) {
        const name = ['', 'Seminar·Webinar', 'Press Release', 'Media', 'Case studies', 'BV Contents'];

        return name[id - 1];
    };

    return (
        <div className="articlePreviewHeaderGroup">
            <button className={(select == categoryIds[0])? "selected":""} value={categoryIds[0]} onClick={(e) => selectCategory(e.target.value)}>{getCategoryName(categoryIds[0])}</button>
            <button className={(select == categoryIds[1])? "selected":""} value={categoryIds[1]} onClick={(e) => selectCategory(e.target.value)}>{getCategoryName(categoryIds[1])}</button>
            <button className={(select == categoryIds[2])? "selected":""} value={categoryIds[2]} onClick={(e) => selectCategory(e.target.value)}>{getCategoryName(categoryIds[2])}</button>
        </div>
    );
}

export default MobileArticleHeader;