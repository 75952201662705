import styles from './index.module.css';
import ailobigProcess from './assets/ailobigProcess.png';
import bkgndLogo from '../../AiLobig/AilobigComments/assets/bg_logo.png';
import avatar from './assets/avatar.png';
import avatar2 from './assets/avatar2.png';
import noImg from "../../../../static/assets/utils/noimg.png";

function Ailobig(props) {

  return (<div className={styles.bkgnd}>

    <div className={styles.content}>

      <div className={styles.top}>
        <div className={styles.title}>Comments</div>
        <div className={styles.head}>V-Advisor 고객 경험</div>
        <div className={styles.description}>V-Advisor를 직접 경험한 고객사의 후기를 확인해보세요!</div>
      </div>

      <div className={styles.avatar}>
        <img className={styles.avatar_image} src={avatar} onError={(e) => e.target.src = `${noImg}`}/>
        <div className={styles.avatar_text}>
          평가 업무에 큰 변화를 가져왔습니다.<br/>
          <span className={styles.avatar_text_highlight}>시간 단축과 데이터 정확도가 모두 향상</span>됐어요.<br/>
          지점 행원들과도 인터페이스를 보면서 평가 결과를 <br/>전달하다 보니 이해도 빨라졌습니다. 메신저 연동기능과<br/> AI학습기능의 업그레이드가 매우 기대됩니다.<br/>


          <div className={styles.avatar_by}>시중은행 여신심사팀 팀장</div>
        </div>
      </div>

      <div className={styles.avatar2}>
        <img className={styles.avatar_image} src={avatar2} onError={(e) => e.target.src = `${noImg}`}/>
        <div className={styles.avatar_text}>
          지도 인터페이스에서
          <span className={styles.avatar_text_highlight}> 클릭만으로 최종 보고서와 엑셀까지 모든 수치가 연동</span><br/>
          되어 제공되는 기능이 많은 오류를 줄이고 시간을 절약해 주었습니다.

          <div className={styles.avatar_by}>대형 평가사 이사</div>
        </div>
      </div>

      <img className={styles.ailobig_process_image} src={ailobigProcess} onError={(e) => e.target.src = `${noImg}`}/>
      <img className={styles.bkgnd_logo_image} src={bkgndLogo} onError={(e) => e.target.src = `${noImg}`}/>

    </div>


  </div>);
}

export default Ailobig;