import React from 'react';
import {Navigate, Outlet} from "react-router-dom";

const AuthChecker = () => {

    const isLogin = localStorage.getItem("login");

    if (isLogin) {

        return <Outlet />
    } else {
        return <Navigate to={"/login"} />;
    }
}

export default AuthChecker;