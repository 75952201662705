import React from "react";
import {Link} from "react-router-dom";
import { deviceDetect } from "../../static/utils/IsMobile/isMobileStore.js";
import DataCardList from "../../components/Main/DataCard/DataCardList";
import Spectrum from "../../components/Main/Spectrum/spectrum";
import StrengthList from "../../components/Main/Strength/StrengthList";
import ArticlePreview from "../../components/Main/ArticlePreview/ArticlePreview";
import '../../static/css/pages/main.css';
import BackgroundVideo from "../../static/assets/pages/main/main_map.mp4";
import BackgroundImg from "../../static/assets/pages/main/topmain_background.png";
import backGround_mobile from "../../static/assets/pages/main/main_background_mobile.mp4";

class Main extends React.Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return (
                <div className="main_mobile">
                    <div className="bgvideo_mobile">
                        <video muted autoPlay loop width="100%">
                            <source src={backGround_mobile} type="video/mp4" />
                        </video>
                        <div className="topmainVisual">
                            <div className="topmainWord">
                                <div className="wordTop"><b>금융위</b>, 빅밸류 규제 개선 요청 최초 수용</div>
                                <div className="wordBottom">(대단지 아파트 외 <b>전 주택 담보가치 산정</b> 시 <b>빅밸류 시세 활용 가능</b>)</div>
                            </div>
                            <div className="btnGroup">
                                <Link to="/dataMap" className="mr3">
                                    <div className="btn-datapopup noselect">
                                        빅밸류 데이터 보기
                                    </div>
                                </Link>

                                <Link to="/about/contactUs" className="ml3">
                                    <div className="btn-contact noselect">
                                        문의하기
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <DataCardList />
                    </div>
                    <Spectrum />
                    <StrengthList />
                    <ArticlePreview />
                </div>
            )
        }
        return (
            <div className="main">
                <div className="bgvideo">
                    <video muted autoPlay loop poster={BackgroundImg}>
                        <source src={BackgroundVideo} type="video/mp4" />
                    </video>
                </div>
                <div className="topmainVisual">
                    <div className="topmainWord">
                        <div className="wordTopDesk"><b>'22 금융위원회</b> 빅밸류 규제 개선 요청 최초 수용</div>
                        <div className="wordBottom">(대단지 아파트 외 <b>전 주택 담보가치 산정</b> 시 <b>빅밸류 시세 활용 가능</b>)</div>
                    </div>
                    <div className="btnGroup">
                        <Link to="/dataMap">
                            <div className="btn-datapopup noselect">
                                    빅밸류 데이터 보기
                            </div>
                        </Link>

                        <Link to="/about/contactUs">
                            <div className="btn-contact noselect">
                                문의하기
                            </div>
                        </Link>
                    </div>
                </div>
                <DataCardList />
                <Spectrum />
                <StrengthList />
                <ArticlePreview />
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isMobile === false && this.state.isMobile === true) {
            window.location.reload();
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Main;
