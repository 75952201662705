import React, {useState, useEffect} from 'react';
import arrowImg from '../../static/assets/components/resourceDetail/arrow_right.svg';
import icAttachedFile from '../../static/assets/components/resourceDetail/ic_attachedFile.svg';
import icDownload from '../../static/assets/components/resourceDetail/ic_download.svg';
import btnNext from '../../static/assets/components/resourceDetail/btn_next.svg';
import btnPrev from '../../static/assets/components/resourceDetail/btn_previous.svg';
import '../../static/css/pages/resourceDetail.css';
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingIndicator from "../../static/utils/LoadingIndicator/loadingIndicator";
import {deviceDetect} from "../../static/utils/IsMobile/isMobileStore";
import noImg from "../../static/assets/utils/noimg.png";

const ResourceDetail  = () => {
    const [loading, setLoading] = useState(true);
    const [articleDetail, setArticleDetail] = useState();
    const {category, articleId} = useParams();
    const navigate = useNavigate();
    const [isMobile, setMobile] = useState(deviceDetect());

    useEffect(() => {
        const windowResize = () => {
            const res = deviceDetect();

            setMobile(res);
        }

        window.addEventListener(`resize`, windowResize);

        return () => {
            window.removeEventListener(`resize`, windowResize);
        };
    }, [])

    function goResources() {
        navigate('/resources');
    }

    function goCategory() {
        navigate('/resources/' + category);
    }


    useEffect(() => {
        setLoading(true)
        const categoryDetail = findCategory(category);
        const PARAMS = {
            boardId: 3,
            categoryId: categoryDetail.id,
            postId: articleId
        };
        axios.get(process.env.REACT_APP_API_URL + '/api/post', {
            params : PARAMS
        })
        .then((Response)=>{

            let res = Response.data[1];

            res.attachedFileTag = getAttachedTag(res);
            res.next = getLinkTag(true, Response.data[2], categoryDetail);
            res.prev = getLinkTag(false, Response.data[0], categoryDetail);
            res.categoryName = categoryDetail.name;
            setArticleDetail(res);
            setLoading(false);
        })
        .catch((Error)=>{console.log(Error)});
    }, [articleId, category])

    if (loading) return <LoadingIndicator></LoadingIndicator>;

    if (isMobile) {
        return (
            <div className="resourcesDetail_mobile">
                <div className="resourcesDetailPath_mobile">
                    <div className="pathPostfix" onClick={goCategory}>{articleDetail.categoryName}</div>
                </div>
                <div className="resourcesDetailTitle_mobile">
                    <div className="title">{articleDetail.title}</div>
                    <div className="date">{articleDetail.viewDate}</div>
                </div>
                <div className="resourcesDetailBody_mobile" dangerouslySetInnerHTML={ {__html: articleDetail.content} }></div>
                <div className={"resourcesDetailAttachedFiles_mobile" + (articleDetail.validFileCounts > 0 ? '':'-hide')}>
                    <div className="attachedFilesHeader">
                        <img src={icAttachedFile} alt="attachedFile" onError={(e) => e.target.src = `${noImg}`}/>
                        <div className="attachedHeaderName">첨부파일</div>
                    </div>
                    {articleDetail.attachedFileTag}
                </div>
                <div className="resourcesDetailOther_mobile">
                    {articleDetail.next}
                    {articleDetail.prev}
                </div>
                <div className="resourcesDetailBtn_mobile">
                    <div className="backToResourcesBtn_mobile"><Link to={'/resources/' + (category)}>목록으로</Link></div>
                </div>
            </div>
        );
    }

    return (
        <div className="resourcesDetail">
            <div className="resourcesDetailPath">
                <div className="pathPrefix" onClick={goResources}>Resources</div>
                <div className="pathArrow"><img src={arrowImg} alt="arrow" /></div>
                <div className="pathPostfix" onClick={goCategory}>{articleDetail.categoryName}</div>
            </div>
            <div className="resourcesDetailTitle">
                <div className="title">{articleDetail.title}</div>
                <div className="date">{articleDetail.viewDate}</div>
            </div>
            <div className="resourcesDetailBody" dangerouslySetInnerHTML={ {__html: articleDetail.content} }></div>
            <div className={"resourcesDetailAttachedFiles" + (articleDetail.validFileCounts > 0 ? '':'-hide')}>
                <div className="attachedFilesHeader">
                    <div className="attachedHeaderName"><img src={icAttachedFile} alt="attachedFile" />첨부파일</div>
                    <div className="attachedHeaderDownload" onClick={() => downloadAll(articleDetail)}>전체 다운로드<img src={icDownload} alt="download" /></div>
                </div>
                {articleDetail.attachedFileTag}
            </div>
            <div className="resourcesDetailOther">
                {articleDetail.next}
                {articleDetail.prev}
            </div>
            <div className="resourcesDetailBtn">
                <div className="resourcesBtn"><Link to={'/resources/' + (category)}>목록으로</Link></div>
            </div>
        </div>
    );

    function findCategory(category) {
        const categoryDetail = [
            {
                id : 2,
                name : 'Seminar/Webinar',
                url : 'sw'
            },{
                id : 3,
                name : 'Press Release',
                url : 'pr'
            },{
                id : 4,
                name : 'Media',
                url : 'media'
            },{
                id : 5,
                name : 'Case Studies',
                url : 'cs'
            },{
                id : 6,
                name : 'BV Contents',
                url : 'bv'
            }
        ];

        let selected = 0;
        categoryDetail.forEach((categoryInfo, index) => {
            if (categoryInfo.url.includes(category)) {
                selected = index;
            }
        })

        return categoryDetail[selected];
    }

    function getAttachedTag(detail) {

        return  detail.attachedFiles.filter(file => !file.isPreview).map((file, index) =>
            <div key={file.attachedFileId} className="attachedFilesBody">
                <div className="fileName">{file.originalFileName}</div>
                <div className="fileSize">({changeUnit(file.size)})</div>
                <a className="fileDownload" href={process.env.REACT_APP_API_URL + `/api/admin/file/` + file.attachedFileId} download={file.originalFileName}>
                    다운로드
                    <img src={icDownload} alt="다운로드"/>
                </a>
            </div>
        );
    }

    function changeUnit(size) {

        const byteUnits = ["KB", "MB", "GB", "TB"];

        for (let i=0; i<byteUnits.length; i++) {
            size = (size / 1024).toFixed(1);

            if(size < 1024) {
                return size + byteUnits[i];
            }
        }

        return size + byteUnits[3];
    }

    function getLinkTag(isNext, detail, categoryDetail) {

        if (detail !== null) {
            let url = "/resources/" + categoryDetail.url + "/" + detail.postId;
            return <Link to={url}><div className="otherArticle"><div className="btnImg"><img src={isNext ? btnNext : btnPrev} alt="button" /></div><div className="articleDirection">{isNext ? '다음글' : '이전글'}</div><div className="otherInfo" ><div className="otherCategory">{categoryDetail.name}</div><div className="otherTitle">{detail.title}</div><div className="otherDate">{detail.viewDate}</div></div></div></Link>
        }

        return <div className="otherArticle"><div className="btnImg"><img src={isNext ? btnNext : btnPrev} alt="button" /></div><div className="articleDirection">{isNext ? '다음글' : '이전글'}</div><div className='otherTitle'>{(isNext ? '다음글' : '이전글') + '이 없습니다.'}</div></div>
    }

    function downloadAll(detail) {
        const attachedFiles = detail.attachedFiles;

        attachedFiles.forEach((file, index) => {
            let element = document.createElement('a');
            element.setAttribute('href', process.env.REACT_APP_API_URL + `/api/admin/file/` + file.attachedFileId);
            element.setAttribute('download', file.originalFileName);
            document.body.appendChild(element);
            element.click();
        })
    }
}

export default ResourceDetail;