import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {isMobile} from "react-device-detect";
import line_side from "../../../static/assets/components/spectrum/line_side.svg";
import {deviceDetect} from "../../../static/utils/IsMobile/isMobileStore";
import noImg from "../../../static/assets/utils/noimg.png";

class SpectrumTheme extends Component {
    constructor(props) {
        super(props);
        const logo_start = this.props.data.logos[0];
        const dir = this.props.data.dir;

        this.state = {
            index: 0,
            curLogo: logo_start,
            dir: dir,
            isHover: false,
            isMobile : deviceDetect()
        }
    }
    render() {
        if (this.state.isMobile) {
            return (
                <div id={this.props.data.element_id} className={"spectrumTheme_mobile div" + (this.props.data.mobile_id) + " " + (this.props.data.mobile_id % 2 == 0 ? "floatR":"floatL")}>
                    <NavLink to={this.props.data.url[this.state.index]} className={(this.props.data.mobile_id % 2 == 0 ? "jStart":"jEnd")}>
                        <div className="themeIcon_mobile">
                            <img src={this.props.data.icon} alt={this.props.data.title} onError={(e) => e.target.src = `${noImg}`}/>
                        </div>
                        <div className="themeTitle_mobile">{this.props.data.title}</div>
                        <div className="themeLogo_mobile">
                            <img src={this.state.curLogo} alt={this.props.data.title + "로고"} onError={(e) => e.target.src = `${noImg}`}/>
                        </div>
                    </NavLink>
                    <div className="spectrumSideLine_mobile">
                        <img className={(this.props.data.mobile_id % 2 == 0 ? "transformR":"")} src={line_side} alt="line_side" onError={(e) => e.target.src = `${noImg}`}/>
                    </div>
                </div>
            )
        }
        return (
                <div className={"spectrumTheme " + this.state.dir}
                     onMouseEnter={() => this.setState({isHover : true})}
                     onMouseLeave={() => this.setState({isHover : false})}
                >
                    <NavLink to={this.props.data.url[this.state.index]}>
                        <div className="themeIcon">
                            <img src={this.props.data.icon} alt={this.props.data.title} />
                        </div>
                        <div className={"themeTitle" + (this.state.isHover ? "-hover": "")}>{this.props.data.title}</div>
                        <div className={"themeLogo" + (this.state.isHover ? "-hover": "")}>
                            <img src={this.state.curLogo} alt={this.props.data.title + "로고"} onError={(e) => e.target.src = `${noImg}`}/>
                        </div>
                    </NavLink>
                </div>
        );
    }

    getThemeData = () => {
        const data = this.props.data
        const indexSum = (this.state.index + 1) % data.logos.length;

        this.setState({
            index: indexSum,
            curLogo: data.logos[indexSum],
        })
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
        this.oneMinuteCall = setInterval(() => this.getThemeData(), 2000);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
        clearInterval(this.oneMinuteCall);
    }
}

export default SpectrumTheme;