import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {useLocation, useNavigate} from "react-router-dom";

const Tr = styled.tr`
    border-bottom: 1px solid #e5e5e5;
`
const Td = styled.td`
    padding: 8px 10px !important;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
`
const TdTitle = styled.td`
    text-align: left;
    padding-left: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        text-decoration: underline;
    }
`

export default function PostsTableData(props) {

  const navigation = useNavigate();
  const posts = props.posts;
  const location = useLocation();

  function categoryIdToName(categoryId) {
    categoryId = categoryId.toString();
    switch (categoryId) {
        case "1":
            return "Resources";
        case "2":
            return "Seminar/Webinar";
        case "3":
            return "Press Release";
        case "4":
            return "Media";
        case "5":
            return "Case studies";
        case "6":
            return "BV Contents";
        default:
            return "noname";
    }

  }

    function handleEditClick(postId) {
      console.log(props);
        navigation(location.pathname + '/' + postId, {
            state : props.pageInfo.currentPageNumber.toString()
        });
        // props.setScreenState((prev) => {
        //     return {
        //         ...prev,
        //         screenType: 'modify',
        //         selectedPostId: postId
        //     }
        // })
    }

  // console.log('post: ', posts)

  return (
    <tbody>
      {posts.map((post) => (
        <Tr key={post.postId}>
          <Td>{post.postId}</Td>
          <TdTitle onClick={() => handleEditClick(post.postId)}>{post.title}</TdTitle>
          {props.boardId === '3' && (<Td>{categoryIdToName(post.categoryId)}</Td>)}
          <Td>{post.writerName}</Td>
          <Td>{post.viewDate}</Td>
          <Td>{post.modifyDate}</Td>
          <Td>{props.boardId === '6' ? '' : post.validFileCounts}</Td>
        </Tr>
      ))}
    </tbody>
  );
}