import React, {useState, useContext, useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {AdminInfoProvider, useAdminInfoValueState, useAdminInfoActionState} from "./templates/Admin/AdminInfoStore";
import {GlobalStoreProvider, RefreshCounterProvider} from "./templates/Admin/AdminGlobalStore";
import AdminContent from "./templates/Admin/AdminContent";
import AdminSideMenu from "./templates/Admin/AdminSideMenu";
import AdminLogin from "./templates/Admin/AdminLogin";
import AuthChecker from "./templates/Admin/AuthChecker";
import './static/css/pages/Admin.css';
import styled from 'styled-components';
import './static/css/pages/Admin.css';
import axios from "axios";
import PostWrite from "./templates/Admin/PostWrite";
import ContactRead from "./templates/Admin/ContactRead";

axios.defaults.headers['Access-Control-Allow-Origin'] = 'http://localhost**';
axios.defaults.withCredentials = true;

const DivHeader = styled.div`
    display: flex;
    font-size: 24px;
    border-bottom: 1px solid #e5e5e5;
    justify-content: space-between;
`;
const H1 = styled.h1`
    margin: 5px 20px;
`;

const DivInfo = styled.div`
    display: flex;
    font-size: 20px;      
    gap: 30px;
    margin-right: 30px;
    align-items: center;
`;

const DivBtn = styled.div`
    width: 100px;
    cursor: pointer;
    &:hover {
        color: #2c2c2c;
        font-weight: 700;
    }
`;

function AdminHeader() {

    const userInfo = useAdminInfoValueState();
    const action = useAdminInfoActionState();
    const nav = useNavigate();
    let isLogin = localStorage.getItem("login");

    if (userInfo.id == '' && isLogin) {
        axios.get(process.env.REACT_APP_API_URL + '/api/admin/idCheck')
            .then((response) => {
                const status = response.data.res;
                if (status == 0) {
                    action.logIn({ id : response.data.userId });
                } else {
                    action.logOut();
                    nav("/login")
                }
            })
            .catch((Error) => {
                action.logOut();
                nav("/login")
            });

    }

    return (
        <DivHeader>
            <H1>Bigvalue Admin</H1>
            {
                isLogin ? <DivInfo><div>{userInfo.id} 님 환영합니다!</div><DivBtn onClick={() => Logout()}>로그아웃</DivBtn></DivInfo>
                    : <DivInfo><DivBtn onClick={() => goToLogin()}>로그인</DivBtn></DivInfo>
            }
            {/* <div>administrator</div> */}
            {/* <div>로그아웃</div> */}
        </DivHeader>
    )

    function Logout() {
        action.logOut();
        nav('/login')
    }

    function goToLogin() {
        nav(`/login`)
    }
}

const DivMain = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200%;
    background-color: steelblue;
    color: white;
`;
const DivMenuAndContent = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
`;
function Admin() {
    
    return (
        <GlobalStoreProvider>
            <RefreshCounterProvider>
                <AdminInfoProvider>
                <BrowserRouter basename="/admin">
                    <DivMain>
                        <AdminHeader />
                        <DivMenuAndContent>
                            <AdminSideMenu />
                            <Routes>
                                <Route element={<AuthChecker />} >
                                    <Route path="/" element={ <Navigate to="/notice" /> } />
                                    <Route path="/notice" element={<AdminContent boardId='1' categoryId='1' />} />
                                    <Route path="/notice/:postId" element={<PostWrite />} />
                                    <Route path="/recruit" element={<AdminContent boardId='2' categoryId='1' />} />
                                    <Route path="/recruit/:postId" element={<PostWrite />} />
                                    <Route path="/resources" element={<AdminContent boardId='3' categoryId='1' />} />
                                    <Route path="/resources/:postId" element={<PostWrite />} />
                                    <Route path="/resources3" element={<AdminContent boardId='3' categoryId='3' />} />
                                    <Route path="/resources3/:postId" element={<PostWrite />} />
                                    <Route path="/resources4" element={<AdminContent boardId='3' categoryId='4' />} />
                                    <Route path="/resources4/:postId" element={<PostWrite />} />
                                    <Route path="/resources6" element={<AdminContent boardId='3' categoryId='6' />} />
                                    <Route path="/resources6/:postId" element={<PostWrite />} />
                                    <Route path="/history" element={<AdminContent boardId='6' categoryId='1' />} />
                                    <Route path="/history/:postId" element={<PostWrite />} />
                                    <Route path="/contact" element={<AdminContent boardId='0' categoryId='0' />} />
                                    <Route path="/contact/:postId" element={<ContactRead />} />
                                </Route>
                                <Route path="/login" element={<AdminLogin />} />
                            </Routes>
                        </DivMenuAndContent>
                    </DivMain>
                </BrowserRouter>
                </AdminInfoProvider>
            </RefreshCounterProvider>
        </GlobalStoreProvider>
    );
}

export default Admin;