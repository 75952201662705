const loadScript = (source, callback) => {

    const script = document.createElement('script');

    script.src = source;
    script.async = true;

    script.onerror = (e) => {
    };

    script.onload = () => {
        callback && callback();
    };

    const prior = document.getElementsByTagName('script')[0];

    prior.parentNode.insertBefore(script, prior);
}

export default loadScript;