import React, {Component} from 'react';
import CompanyList from "./CompanyList";
import '../../../../static/css/components/AboutInvestment.css';
import {isMobile} from "react-device-detect";
import {deviceDetect} from "../../../../static/utils/IsMobile/isMobileStore";

class Investment extends Component {

    state = {
        isMobile : deviceDetect()
    }

    render() {
        if (this.state.isMobile) {
            return(
                <div className="investmentComponent">
                    <div className="investmentHeader_mobile">
                        <div className="prefix">국내 최고의 투자기관들이</div>
                        <div className="postfix"><div>빅밸류의 데이터</div><span className="bold">기술력에 투자</span>했습니다.</div>
                    </div>
                    <CompanyList />
                </div>
            );
        }

        return (
            <div className="investmentComponent">
                <div className="investmentHeader">
                    <div className="prefix">국내 최고의 투자기관들이</div>
                    <div className="postfix">빅밸류의 데이터 <span className="bold">기술력에 투자</span>했습니다.</div>
                </div>
                <CompanyList />
            </div>
        );
    }

    windowResize = () => {
        const res = deviceDetect();

        this.setState({
            isMobile : res
        })
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.windowResize);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.windowResize);
    }
}

export default Investment;