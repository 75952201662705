import React, {useState, useEffect, useContext} from 'react';
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';

import styled from 'styled-components';

// import "@toast-ui/editor/dist/toastui-editor.css";

// import Editor from "@toast-ui/editor";
const DivArea = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    padding: 20px;
    width: 100%;
`;

const Table = styled.table`
        border-collapse: collapse;
        margin: 0 auto;
        width: 100%;
        font-size: 16px;
`

const Tr = styled.tr`
    border-top: 1px solid #d2d2d2 !important;
    border-bottom: 1px solid #d2d2d2 !important;
`

const Td = styled.td`
    padding: 10px;
`

const TdHeader = styled.td`
    background-color: #F5F5F5 !important;
    color: #000000 !important;
    font-weight: bold !important;
    padding: 12px 20px !important;
    text-align: left !important;
`

const ButtonGroup = styled.div`
    padding: 10px 0;
    display: flex;
    gap: 3px;
`;

const Button = styled.button`
    margin-right: 10px;
    border: 1px solid #448fdd;
    color: #448fdd;
    background-color: #ffffff;
    font-size: 14px !important;
    height: 30px;
    cursor: pointer;
    padding: 10px 20px;
    display: inline-block;
    box-sizing: border-box;
    width: 180px;

    &:hover {
        text-decoration: underline;
    }
`
const InputText = styled.input`
    width: 100%;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
`


const refReplyEditor = React.createRef();
export default function ContactRead(props) {
    // console.log("ContactRead props: ", props);
    const { postId } = useParams();
    const [contactData, setContactData] = useState([]);
    const [hasReply, setHasReply] = useState(false);
    const [replyData, setReplyData] = useState([]);
    const nav = useNavigate();
    const location = useLocation();
    const prevPath = "/" + location.pathname.split("/")[1];
    const pageNum = location.state;

    useEffect(() => {
        loadDb();

        /**
         * NHN Toast UI Editor 사용함. 
         * 리액트용으로 나온 버전도 있지만, 리액트용으로 나온 버전은 프로젝트 리액트 버전과 맞지 않아서 일반 자바스크립트 버전을 사용함.
         * (리액트용을 사용하려면 프로젝트의 리액트 버전을 다운그레이드 해야 함)
         *  */ 
        // refReplyEditor.current = new Editor({
        //     el: document.querySelector("#replyEditor"),
        //     initialEditType: "wysiwyg",
        //     previewStyle: "vertical",
        //     height: "430px",
        //     initialValue: "",
        //     useCommandShortcut: true,
        // });

        /**
         * Toast UI Editor 사용 안 함: 토스트에디터는 
         * 여러 이미지 한 번에 올리기, 가운데 정렬, 새 탭에서 링크 열기 기능이 없어서 summernote 사용.
         * */
        window.$("#summernote_contact").summernote({
            height: 430,
        });

        return () => {
            // console.log("ToastUiEditor useEffect cleanup");
        };
    }, []);

    useEffect(() => {
        if (contactData.reply == null) {
            setHasReply(false);
        } else {
            setHasReply(true);
            setReplyData(contactData.reply);
            return;
        }
    }, [contactData])

    const refReplyTitle = React.createRef();
    const refReplyEmail = React.createRef();
    const refReplyReplyname = React.createRef();

    let savedHTML = "";

    function validate() {
        if(refReplyTitle.current.value === '' ||
            refReplyEmail.current.value === '' ||
            getEditorSummernoteHTML() === '<p><br></p>' // 빈 내용이면 <p><br></p>로 나옴
            ) {
            alert('항목을 입력해주세요.');
            return -1;
        }

        return 0;
    }

    async function write() {
        const resValidate = validate();
        if(resValidate !== 0)
            return;
        
        const ret = await saveDb();
        if(ret.code === 0) {
            alert('등록되었습니다.');
            nav(prevPath, {
                state : pageNum.toString()
            });
        } else {
            alert('등록에 실패했습니다.');
        }
    }

    function getEditorHTML() {
        const instance = refReplyEditor.current;
        return instance.getHTML();
    }

    function getEditorSummernoteHTML() {
        const html = window.$("#summernote_contact").summernote("code")
        return html;
    }

    async function loadDb() {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/contact/${postId}`);
        const data = response.data;
        const content = {
            idx: data.idx,
            contactName: data.contactName,
            email: data.email,
            companyName: data.companyName,
            inquiry: data.inquiry,
            phoneNumber: data.phoneNumber,
            createDate: data.createDate,
            type: data.type,
            downItems: data.downItems,
            reply: data.reply
        }

        setContactData(content);
    }

    // db에 저장
    async function saveDb() {
        
        // savedHTML = getEditorHTML();
        savedHTML = getEditorSummernoteHTML();

        /* body 처리 */
        const body = {
            title: refReplyTitle.current.value,
            email: refReplyEmail.current.value,
            replyname: refReplyReplyname.current.value,
            replyinquiry: savedHTML,
            contact_idx: contactData.idx,
        }

        /* axios call */
        const response = await axios.post(process.env.REACT_APP_API_URL + `/api/reply`, body);

        if(response.status === 200) {
            return {code: 0};
        } else {
            return {code: -1};
        }
    }

    function saveWithoutReply() {
        const body = {
            createDate: '',
            email: contactData.email,
            replyinquiry: '<p>답변 완료</p>',
            replyname: contactData.replyname,
            title : '답변 완료',
            contact_idx: contactData.idx,
        }

        axios.post(process.env.REACT_APP_API_URL + `/api/noReply`, body)
            .then((response) => {
                alert('답변 처리되었습니다.');
                let changed = contactData;
                changed.reply = response.data;
                setContactData(changed);
                setHasReply(true);
                setReplyData(response.data);
            });
    }

    async function deleteReply() {
        const response = await axios.delete(process.env.REACT_APP_API_URL + `/api/deleteReply/` + contactData.idx);

        if (response.data == 1) {
            alert('답변이 삭제되었습니다.');
            let changed = contactData;
            changed.reply = null;
            setContactData(changed);
            setHasReply(false);
            setReplyData(null);
        } else {
            alert('답변 삭제를 실패했습니다.');
        }
    }

    function goBack () {
        nav(prevPath, {
            state : pageNum.toString()
        });
    }

    function changeNTT() {
        const Name = ['', 'AI LOBIG', 'AI Developer', 'V-Advisor', '빌라시세닷컴'];
        let numberToText = '';

        if (contactData.downItems == null) {
            return "";
        } else {
            const split = contactData.downItems.split(',');
            numberToText = Name[split[0]];
            for (let i=1; i<split.length; i++) {
                numberToText += "," + Name[split[i]];
            }
        }

        return numberToText;
    }

    const items = changeNTT();
    const contactType = contactData.type == 1 ? "솔루션 소개자료 받기" : contactData.type == 2 ? "데이터 구매" : "기타문의";

    return (
        <DivArea id='ContactRead'>
            <Table>
                <colgroup>
                    <col width="200px" />
                    <col width="*" />
                </colgroup>
                <tbody>
                    <Tr>
                        <TdHeader>문의 작성자</TdHeader>
                        <Td>{contactData.contactName}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 종류</TdHeader>
                        <Td>{contactType}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 회사명</TdHeader>
                        <Td>{contactData.companyName}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 email</TdHeader>
                        <Td>{contactData.email}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 전화번호</TdHeader>
                        <Td>{contactData.phoneNumber}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 내용</TdHeader>
                        <Td style={{whiteSpace: 'pre-wrap'}}>{contactData.inquiry}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 등록일</TdHeader>
                        <Td>{contactData.createDate}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>선택한 솔루션 소개자료</TdHeader>
                        <Td>{items}</Td>
                    </Tr>
                    <Tr>
                        <TdHeader>문의 답변여부</TdHeader>
                        {hasReply ? 
                            <Td style={{fontWeight: 'bold', color: 'blue'}}>답변등록</Td> 
                        : 
                            <Td style={{fontWeight: 'bold', color: 'red'}}>답변대기</Td>
                        }
                    </Tr>
                </tbody>
            </Table>

            <hr />

            <h1>답변</h1>
            <Table>
                <colgroup>
                    <col width="200px" />
                    <col width="*" />
                </colgroup>
                <tbody>
                    {
                        hasReply ? // 답변이 있는 경우
                        <>
                            <Tr>
                                <TdHeader>답변 제목</TdHeader>
                                <Td>{replyData.title}</Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변 내용</TdHeader>
                                <Td dangerouslySetInnerHTML={{__html: replyData.replyinquiry}}></Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변 받는 email</TdHeader>
                                <Td>{replyData.email}</Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변 받는 사람</TdHeader>
                                <Td>{replyData.replyname}</Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변일</TdHeader>
                                <Td>{replyData.createDate}</Td>
                            </Tr>
                        </>
                        : // 답변이 없는 경우 (새로 등록)
                        <>
                            <Tr>
                                <TdHeader>답변 제목</TdHeader>
                                <Td><InputText type="text" name="replyTitle" ref={refReplyTitle} /></Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변 내용</TdHeader>
                                {/* <Td> <div id="replyEditor" ref={refReplyEditor}></div> </Td> */}
                                <Td> <div id="summernote_contact"></div></Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변 받는 email</TdHeader>
                                <Td><InputText type="text" name="replyEmail" ref={refReplyEmail} defaultValue={contactData.email} /></Td>
                            </Tr>
                            <Tr>
                                <TdHeader>답변 받는 사람</TdHeader>
                                <Td><InputText type="text" name="replyReplyname" ref={refReplyReplyname} defaultValue={contactData.contactName} /></Td>
                            </Tr>
                        </>
                    }
                </tbody>
            </Table>


            <hr />
            {/* 버튼들 */}
            <ButtonGroup>
                <Button onClick={() => goBack()}>목록으로</Button>
                {!hasReply && <Button onClick={() => write()}>저장</Button>}
                {!hasReply ? <Button onClick={() => saveWithoutReply()}>답변 없이 등록</Button> : <Button onClick={() => deleteReply()}>답변 제거</Button>}
            </ButtonGroup>
        </DivArea>
    )
}