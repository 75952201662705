import React, {Component} from 'react';
import ProcessCircle from "./processCircle";

class ProcessLevel extends Component {
    state = {
        level : []
    };

    render() {
        const circle = this.state.level.map((data) => <ProcessCircle key={data.id} data={data} />)

        return (
            <div className="processCircleList">
                {circle}
            </div>
        );
    }

    getLevel = () => {
        const levelData = [
            {
                id : 1,
                body : '입사지원',
                sub : ''
            },
            {
                id : 2,
                body : '서류전형',
                sub : ''
            },
            {
                id : 3,
                body : '1차',
                sub : '실무자 면접'
            },
            {
                id : 4,
                body : '2차',
                sub : '임원 면접'
            },
            {
                id : 0,
                body : '최종합격',
                sub : ''
            }
        ];

        this.setState({
            level : levelData
        });
    }

    componentDidMount() {
        this.getLevel();
    }
}

export default ProcessLevel;