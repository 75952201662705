import React from "react";
import loadScript from "../../../static/utils/loadScript";


class KakaoMap extends React.Component {

    constructor(props) {
        super(props);

        this.setMapRef = element => {

            if (!element)
            {
                return;
            }

            this.map = element;

            /* global kakao */
            if (!window.kakao || !window.kakao.maps) {

                loadScript("//dapi.kakao.com/v2/maps/sdk.js?appkey=9c31ed747f35e243796d1876d2401af2&autoload=false", () => {

                    window.kakao.maps.load(()=>{

                        props.initMap(element);

                    });
                });
            }
            else
            {
                props.initMap(element);
            }
        }
    }

    render() {
        return (<div ref={this.setMapRef} className={this.props.className}>

        </div>);
    }
}

export default KakaoMap;