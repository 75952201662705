import React, {Component} from 'react';
import {isMobile} from "react-device-detect";

class ArticlePreviewHeader extends Component {
    render() {
        const previewHeader = ['', 'Seminar·Webinar', 'Press Release', 'Media', 'Case studies', 'BV Contents']

        return (
            <div className="articleListHeader">
                { previewHeader[this.props.value-1] }
            </div>
        );
    }
}

export default ArticlePreviewHeader;